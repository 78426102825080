import React, { Component } from "react";
import { Grid,TextField } from "@material-ui/core";

class BtcSolcompra extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
            opened: false,
		};
		
	}
  
	
  
	render() {
		var { solcompra } = this.props;

		if (solcompra.procesoId>0){
			return (
                <Grid container spacing={3}>
                  <Grid item item xs={12} md={6}>
                    <TextField
                      id="cnameprov"
                      margin="normal"
                      label="Código OC"
                      value={solcompra.procesoCodigo}
                      inputProps={{ readOnly: true }}
                      fullWidth
                    />
                      <TextField
                      id="cnit"
                      margin="normal"
                      label="Proveedor"
                      value={solcompra.proveedor}
                      inputProps={{ readOnly: true }}
                      type="text"
                      fullWidth
                    />
                    <TextField
                      id="cnamecontac"
                      inputProps={{ readOnly: true }}
                      margin="normal"
                      label="Monto"
                      value={solcompra.monto}
                      type="text"
                      fullWidth
                    />
                    <TextField
                      id="ccondpag"
                      inputProps={{ readOnly: true }}
                      margin="normal"
                      label="Descripción"
                      value={solcompra.descripción}
                      type="text"
                      fullWidth
                    />
                    <TextField
                      id="ctelfofi"
                      inputProps={{ readOnly: true }}
                      margin="normal"
                      label="ce Co Descripción"
                      value={solcompra.ceCoDescripción}
                      type="text"
                      fullWidth
                    />
                    <TextField
                      id="cnroct"
                      inputProps={{ readOnly: true }}
                      margin="normal"
                      label="Personal Id Manager"
                      value={solcompra.personalIdManager}
                      type="text"
                      fullWidth
                    />
                    <TextField
                      id="cmndcta"
                      inputProps={{ readOnly: true }}
                      margin="normal"
                      label="Manager"
                      value={solcompra.manager}
                      type="text"
                      fullWidth
                    />
                    <TextField
                      id="cpscta"
                      inputProps={{ readOnly: true }}
                      margin="normal"
                      label="Nit"
                      value={solcompra.nit}
                      type="text"
                      fullWidth
                    />
                  </Grid>
                  <Grid item item xs={12} md={6}>
                    <TextField
                        id="cemail"
                        inputProps={{ readOnly: true }}
                        margin="normal"
                        label="Contacto"
                        value={solcompra.contacto}
                        type="text"
                        fullWidth
                      />
                      <TextField
                        id="ctelf"
                        inputProps={{ readOnly: true }}
                        margin="normal"
                        label="Correo"
                        value={solcompra.correo}
                        type="text"
                        fullWidth
                      />
                      <TextField
                        id="caddres"
                        inputProps={{ readOnly: true }}
                        margin="normal"
                        label="Telefono Oficina"
                        value={solcompra.telefonoOficina}
                        type="text"
                        fullWidth
                      />
                      <TextField
                        id="cbanco"
                        inputProps={{ readOnly: true }}
                        margin="normal"
                        label="Condicion Pago"
                        value={solcompra.condicionPago}
                        type="text"
                        fullWidth
                      />
                      <TextField
                        id="ctcta"
                        inputProps={{ readOnly: true }}
                        margin="normal"
                        label="Dirección"
                        value={solcompra.dirección}
                        type="text"
                        fullWidth
                      />
                      <TextField
                        id="ccat"
                        inputProps={{ readOnly: true }}
                        margin="normal"
                        label="Celular"
                        value={solcompra.celular}
                        type="text"
                        fullWidth
                      />
                      <TextField
                        id="cstd"
                        inputProps={{ readOnly: true }}
                        margin="normal"
                        label="SolicitanteId"
                        value={solcompra.solicitanteId}
                        type="text"
                        fullWidth
                      />
                      <TextField
                        id="cspr"
                        inputProps={{ readOnly: true }}
                        margin="normal"
                        label="Orden de Compra"
                        value={solcompra.procesoId}
                        type="text"
                        fullWidth
                      />
                  </Grid>
              </Grid>
            )
		}else{
			return (
                <div>
                </div>
            )
		}

	}
}

export default BtcSolcompra;