import React from "react";
import {
  Route,
  Switch,
  Redirect,
  withRouter,
} from "react-router-dom";
import classnames from "classnames";
import {Box, IconButton, Link} from '@material-ui/core'
import Icon from '@mdi/react'

//icons
import {
  mdiFacebook as FacebookIcon,
  mdiTwitter as TwitterIcon,
  mdiGithub as GithubIcon,
} from '@mdi/js'

// styles
import useStyles from "./styles";

// components
import Header from "../Header";
import Sidebar from "../Sidebar";

// pages
import Dashboard from "../../pages/dashboard";
import Materias from "../../pages/materias";
import Notifications from "../../pages/notifications";
import Maps from "../../pages/maps";
import Tables from "../../pages/tables";
import Icons from "../../pages/icons";
import Charts from "../../pages/charts";
import Solcompra from "../../pages/solcompra";
import SolPago from "../../pages/solpago";
import Abm from "../../pages/Abm";
import Abmpers from "../../pages/Abmpers";
import Logs from "../../pages/Logs";
import Logsh from "../../pages/Logsh";
import Seguimiento from "../../pages/Seguimiento";
import Seguimientouser from "../../pages/Seguimientouser";
import Perfil from "../../pages/perfil";
// context
import { useLayoutState } from "../../context/LayoutContext";
import Solpago from "../../pages/solpago";
import Gestionuser from "../../pages/Gestionuser";
import Abmuser from "../../pages/Abmuser";
import Seguimientopago from "../../pages/Seguimientopago";
import Anularproceso from "../../pages/Anularproceso";
import Gestioncambio from "../../pages/Gestioncambio";
import Logsecuser from "../../pages/Logsecuser";
import reasignacion from "../../pages/reasignacion";

function Layout(props) {
  var classes = useStyles();

  // global
  var layoutState = useLayoutState();

  return (
    <div className={classes.root}>
        <>
          <Header history={props.history} />
          <Sidebar />
          <div
            className={classnames(classes.content, {
              [classes.contentShift]: layoutState.isSidebarOpened,
            })}
          >
            <div className={classes.fakeToolbar} />
            <Switch>
              <Route path="/app/dashboard" component={Dashboard} />
              <Route path="/app/materias" component={Materias} />
              <Route path="/app/Solcompra" component={Solcompra} />
              <Route path="/app/Solcompra/:id" component={Solcompra} />
              <Route path="/app/Solpago" component={Solpago} />
              <Route path="/app/Solpago/:id" component={Solpago} />
              <Route path="/app/Abm" component={Abm} />
              <Route path="/app/Abmpers" component={Abmpers} />
              <Route path="/app/Logs" component={Logs} />
              <Route path="/app/Logsh" component={Logsh} />
              <Route path="/app/Seguimiento" component={Seguimiento} />
              <Route path="/app/Seguimientouser" component={Seguimientouser} />
              <Route path="/app/tables" component={Tables} />
              <Route path="/app/notifications" component={Notifications} />
              <Route path="/app/perfil" component={Perfil} />
              <Route path="/app/Gestionuser" component={Gestionuser} />
              <Route path="/app/Abmuser" component={Abmuser} />
              <Route path="/app/Seguimientopago" component={Seguimientopago} />
              <Route path="/app/Anularproceso" component={Anularproceso} />
              <Route path="/app/Gestioncambio" component={Gestioncambio} />
              <Route path="/app/Logsecuser" component={Logsecuser} />
              <Route path="/app/reasignacion" component={reasignacion} />
              
              <Route
                exact
                path="/app/ui"
                render={() => <Redirect to="/app/ui/icons" />}
              />
              <Route path="/app/ui/maps" component={Maps} />
              <Route path="/app/ui/icons" component={Icons} />
              <Route path="/app/ui/charts" component={Charts} />
            </Switch>
            
          </div>
        </>
    </div>
  );
}

export default withRouter(Layout);
