import React, { useState,useEffect } from "react";
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  Grid,
  LinearProgress,
  OutlinedInput,
  MenuItem,
  Button,
  TextField,
  IconButton
} from "@material-ui/core";
import { useTheme } from "@material-ui/styles";
import {
  ResponsiveContainer,
  ComposedChart,
  AreaChart,
  LineChart,
  Line,
  Area,
  PieChart,
  Pie,
  Cell,
  YAxis,
  XAxis,
} from "recharts";
import { useSnackbar } from 'notistack';
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import Icon from '@material-ui/core/Icon';
import {Edit,Delete,Add,AdbRounded} from '@material-ui/icons';


// styles
import {
  DataGrid,
  ColDef,
  ValueGetterParams,
  CellParams,
  GridApi
} from "@material-ui/data-grid";
// components
import axios from 'axios'
import mock from "./mock";
import Widget from "../../components/Widget/Widget";
import PageTitle from "../../components/PageTitle/PageTitle";
import { Typography } from "../../components/Wrappers/Wrappers";
import Dot from "../../components/Sidebar/components/Dot";
import BigStat from "./components/BigStat/BigStat";
import UserAvatar from "../../components/UserAvatar/UserAvatar";

import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Constantes from "../../context/Constates";
import useStyles from "./styles";
import {Modal,ModalBody,ModalFooter,ModalHeader} from "reactstrap"
import { useAlert } from "react-alert";

const museStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
    '& .super-app-theme--cell': {
      backgroundColor: 'rgba(224, 183, 60, 0.55)',
      color: '#1a3e72',
      fontWeight: '600',
    },
    '& .super-app.negative': {
      backgroundColor: '#d90007',
      color: '#FFFFFF',
      fontWeight: '600',
    },
    '& .super-app.positive': {
      backgroundColor: '#d47483',
      color: '#1a3e72',
      fontWeight: '600',
    },
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  large: {
    width: theme.spacing(15),
    height: theme.spacing(15),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#008000',
  },
 
}));

  /*rutas */
  var obteneruserroles=Constantes.URLSERVER+Constantes.APIGETROLESUSER
  var abmrol=Constantes.URLSERVER+Constantes.APIPOSTROL
  var getappuser=Constantes.URLSERVER+Constantes.APIGETROLAPPUSER
  var voidapprol=Constantes.URLSERVER+Constantes.APIPOSTROLAPP
  var voiduserrol=Constantes.URLSERVER+Constantes.APIPOSTROLUSER
  
  
export default function Gesionuser(props) {
  const classes = useStyles();

  let history = useHistory();
  var theme = useTheme();
  const alert = useAlert();
  var dataRecibido={};
  var configjson = {
    headers: {'Content-Type': 'application/json' , 'Authorization': 'Bearer '+localStorage.getItem('id_token')}
  };
  const mclasses = museStyles();
  const location = useLocation();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  // local
  var Usuario=JSON.parse(localStorage.getItem('user'));
  const [mRoles, setmRoles] = React.useState([]);
  const [mUsers, setmUsers] = React.useState([]);
  const [mAplicacion, setmAplicacion] = React.useState([]);
  const [mUsuario, setmUsuario] = React.useState([]);
  const [mAppSel, setmAppSel] = React.useState({});
  const [mUserSel, setmUserSel] = React.useState({});
  const [mAplicaciones, setmAplicaciones] = React.useState([]);
  
  const [mRol,setmRol]=React.useState({});
  const [modalins, setmodalins] = React.useState(false);
  const [modalupd, setmodalupd] = React.useState(false);
  const [modalapp, setmodalapp] = React.useState(false);
  const [loading,setloading]=React.useState(false);
  

  var columnsapp=[
    { field: 'aplicacionId', headerName: '#', width: 120 },
    { field: 'aplicacionNombre', headerName: 'Nombre Aplicaciones', resizable: true,width: 300  },
  ];
  var columnsuser=[
    { field: 'personalId', headerName: '#', width: 120 },
    { field: 'personalNombre', headerName: 'Nombre Personal', resizable: true,width: 300  },
  ];

  var [mainChartState, setMainChartState] = useState("monthly");

  /*Peticiones*/ 
  useEffect(()=>{
    GetRoleandUser();
  },[location])

  /*alert mensajes */

  const voidRoles=async(tipo)=>{
    try {
      var data={};
      if(tipo==1){
        mRol.GrupoPersonasId=0;
        mRol.GrupoPersonasUnipersonal=0;
        mRol.AreaId =4;
      }
      mRol.tipoEstado=tipo;
      const json = JSON.stringify(mRol);
      setloading(true);
      console.log(json);
      const resp = await axios.post(abmrol,json,configjson);
      var result = resp.data;
      if(result.codigoRespuesta==0){
        GetRoleandUser();
        if(tipo==1){
          mRol.grupoPersonasId=0;
          accionesModal();
        }else{
          accionesModalmod()
        }
      }else{
        alert.show(result.mensajeRespuesta);   
      }
      setloading(false);
        return data;
      } catch (err) {
        setloading(false);
        console.error(err);
      }
}
 
  const GetRoleandUser=async()=>{
        try {
            var data={};
            setloading(true);
            const resp = await axios.get(obteneruserroles,configjson);
            var result = resp.data;
            if(result.codigoRespuesta==0){
              var dataroles=result.valorRespuesta.listaRoles;
              var datausuarios=result.valorRespuesta.listaPersonalTodos;
              var dataaplicaciones=result.valorRespuesta.listaAplicacionTodos;
              setmRoles(dataroles);
              setmUsers(datausuarios);
              setmAplicaciones(dataaplicaciones);
            }else{
              alert.show(result.mensajeRespuesta);
            }
            setloading(false);
            return data;
          } catch (err) {
            setloading(false);
            console.error(err);
          }
    }

    const voidAppRol=async(tipo)=>{
      try {
        var data={};
        var app={};
        app.AplicacionId =mAppSel.aplicacionId;
        app.GrupoPersonasId =mRol.grupoPersonasId;
        app.tipoEstado=tipo;
        const json = JSON.stringify(app);
        setloading(true);
        const resp = await axios.post(voidapprol,json,configjson);
        var result = resp.data;
        if(result.codigoRespuesta==0){
          var misaplicaciones=result.valorRespuesta;
          misaplicaciones.map(dt=>{
            dt.id=dt.aplicacionId;
          })
          setmAplicacion(misaplicaciones);
          var newAplicaciones=[];
          mAplicaciones.map(dt=>{
            var esta=false;
            misaplicaciones.map(td=>{
              if(td.aplicacionId==dt.aplicacionId){
                esta=true;
              }
            })
            if(!esta){
              newAplicaciones.push(dt);
            }
          })
          setmAplicaciones(newAplicaciones);
          if(tipo!=3){
            accionesModalapp();
          }
        }else{
          alert.show(result.mensajeRespuesta);
        }
        setloading(false);
        return data;
      } catch (err) {
        setloading(false);
          console.error(err);
      }
    }
    const mvoidUserRol=async(tipo)=>{
      try {
        var data={};
        var app={};
        app.PersonalId  =mUserSel.personalId;
        app.GrupoPersonaId  =mRol.grupoPersonasId;
        app.PersonalIdNuevo  =mUserSel.personalId;
        
        app.tipoEstado=tipo;
        const json = JSON.stringify(app);
        setloading(true);
        const resp = await axios.post(voiduserrol,json,configjson);
        var result = resp.data;
        if(result.codigoRespuesta==0){
          var miuser=result.valorRespuesta;
          miuser.map(dt=>{
            dt.id=dt.personalId;
          })
          console.log(miuser);
          console.log(result.valorRespuesta);
          setmUsuario(miuser);
          var newUsers=[];
          mUsers.map(dt=>{
            var esta=false;
            miuser.map(td=>{
              if(td.personalId==dt.personalId){
                esta=true;
              }
            })
            if(!esta){
              newUsers.push(dt);
            }
          })
          setmUsers(newUsers);
          /*if(tipo==3){
            accionesModalapp();
          }*/
        }else{
          alert.show(result.mensajeRespuesta);            
        }
        setloading(false);
        return data;
      } catch (err) {
        setloading(false);
          console.error(err);
      }
    }
    const getAppUser=async(id)=>{
      try {
        var data={};
        var url=getappuser+"id="+id;
        setloading(true);
        const resp = await axios.get(url,configjson);
        var result = resp.data;
        if(result.codigoRespuesta==0){
          var dataApp=result.valorRespuesta.listaAplicacion;
          var dataUser=result.valorRespuesta.listaPersonal;
          var c=0;
          dataApp.map(dt=>{
            c++;
            dt.id=c;
          })
          dataUser.map(dt=>{
            dt.id=dt.personalId;
          })
          setmAplicacion(dataApp);
          setmUsuario(dataUser);
          var newAplicaciones=[];
          mAplicaciones.map(dt=>{
            var esta=false;
            dataApp.map(td=>{
              if(td.aplicacionId==dt.aplicacionId){
                esta=true;
              }
            })
            if(!esta){
              newAplicaciones.push(dt);
            }
          })
          setmAplicaciones(newAplicaciones);
          var newUsers=[];
          mUsers.map(dt=>{
            var esta=false;
            dataUser.map(td=>{
              if(td.personalId==dt.personalId){
                esta=true;
              }
            })
            if(!esta){
              newUsers.push(dt);
            }
          })
          setmUsers(newUsers);
        }else{
          alert.show(result.mensajeRespuesta);            
        }
        setloading(false);
        return data;
      } catch (err) {
        setloading(false);
          console.error(err);
      }
    }
    
    const voidapp=(tipo)=>{
      voidAppRol(tipo);
    }
    const voidUserRols=(tipo)=>{
      if(mUserSel.personalId>0){
        mvoidUserRol(tipo);
      }
    }

    const handleChangeroles = (event) => {
      console.log(event.target.value);
      if(mRoles.length>0){
        mRoles.map(rol => {
          if(rol.grupoPersonasId==event.target.value){
            setmRol(rol)
            getAppUser(rol.grupoPersonasId);
          }
        })
      }
    };
    const handleChangenamerol = (event) => {
      mRol.grupoPersonasNombre =event.target.value;
    };
    const handleChangeapp=(event)=>{
      mAplicaciones.map(dt=>{
        if(dt.aplicacionId==event.target.value){
          setmAppSel(dt)
        }
      })
    }
    const handleChangeuser=(event)=>{
      mUsers.map(dt=>{
        if(dt.personalId==event.target.value){
          setmUserSel(dt)
        }
      })
    }
    
    const accionesModal=()=>{
      setmodalins(!modalins)
    }
    const accionesModalmod=()=>{
      setmodalupd(!modalupd)
    }
    const accionesModalapp=()=>{
      setmodalapp(!modalapp)
    }

  return (
    <>
      <Grid container spacing={3}>
       <Grid item lg={12} md={12} sm={12} xs={12}>
          <Widget
            title="Gestion Roles"
            upperTitle
            bodyClass={classes.fullHeightBody}
            className={classes.card}
            disableWidgetMenu={true}
          >
             <Grid container spacing={6}>
              <Grid item xs={6} style={{paddingRight: 25}}>
                <FormControl className={classes.formControl} fullWidth>
                    <InputLabel htmlFor="age-native-simple">Seleccione Rol</InputLabel>
                    <Select
                    native
                    id="Rol"
                    name="Rol"
                    onChange={handleChangeroles}
                    >
                    <option value=""></option>
                    {mRoles.map(rol => (
                        <option
                        key={rol.grupoPersonasId}
                        value={rol.grupoPersonasId}
                        >
                        {rol.grupoPersonasNombre}
                        </option>
                    ))}
                    </Select>
                </FormControl>
              </Grid> 
              <Grid item xs={6} >
                  <IconButton color="primary" onClick={()=>accionesModal()} component="span">
                    <Add/>
                  </IconButton>
                  {mRol && mRol.grupoPersonasId>0 && 
                  <>
                  <IconButton color="primary" onClick={()=>accionesModalmod()}  component="span">
                    <Edit/>
                  </IconButton>
                  <IconButton color="primary" onClick={()=>accionesModal()} component="span">
                    <Delete/>
                  </IconButton>
                  </>
                  }
              </Grid>
            </Grid>
          </Widget>
        </Grid>

        {mRol && mRol.grupoPersonasId>0 && 
        <>
        <Grid item lg={6} md={12} sm={12} xs={6}>
          <Widget
            title="Aplicaciones que puede Acceder"
            upperTitle
            bodyClass={classes.fullHeightBody}
            className={classes.card}
            disableWidgetMenu={true}
          >
            <Grid container spacing={6}>
              <Grid item xs={12} style={{paddingRight: 25}}>
              <Button
                variant="contained"
                size="small"
                color="primary"
                className={classes.button}
                style={{ marginLeft: 16 }}
                onClick={()=>accionesModalapp()}
              >
                Adicionar Aplicacion
              </Button>
              <br/>
              <br/>
            <div style={{ height: 750 , padding:0}}>
              <DataGrid 
                rows={mAplicacion} 
                columns={columnsapp}  
                pageSize={20}
                onRowSelected={(newSelection) => {
                  var sol=newSelection.data;
                  setmAppSel(sol);
                  voidapp(3);
                  }}
              />
            </div> 
              </Grid> 
              
            </Grid>
          </Widget>
        </Grid>
       <Grid item lg={6} md={12} sm={12} xs={6}>
          <Widget
            title="Usuario Asignado"
            upperTitle
            bodyClass={classes.fullHeightBody}
            className={classes.card}
            disableWidgetMenu={true}
          >
             <Grid container spacing={6}>
             <Grid item xs={6} style={{paddingRight: 25}}>
              <FormControl className={classes.formControl} fullWidth>
                    <InputLabel htmlFor="age-native-simple">Seleccione Usuario</InputLabel>
                    <Select
                    native
                    id="Rol"
                    name="Rol"
                    onChange={handleChangeuser}
                    >
                    <option value=""></option>
                    {mUsers.map(rol => (
                        <option
                        key={rol.personalId}
                        value={rol.personalId}
                        >
                        {rol.personalNombre}
                        </option>
                    ))}
                    </Select>
                </FormControl>
            </Grid> 
              <Grid item xs={6} >
                  <IconButton color="primary" onClick={()=>voidUserRols(1)} component="span">
                    <AdbRounded/>
                  </IconButton>
                  {mUserSel && mUserSel.personalId>0 && 
                  <>
                  <IconButton color="primary" onClick={()=>voidUserRols(3)} component="span">
                    <Delete/>
                  </IconButton>
                  </>
                  }
              </Grid>
              <br/>
              <br/>
              <Grid item xs={12}>
              <div style={{ height: 750 , padding:0}}>
                <DataGrid 
                  rows={mUsuario} 
                  columns={columnsuser}  
                  pageSize={20}
                  onRowSelected={(newSelection) => {
                    var sol=newSelection.data;
                    setmUserSel(sol);
                    }}
                />
              </div>
              </Grid>
             
            </Grid>
          </Widget>
        </Grid> </>}
            
      </Grid>



      <Modal
        isOpen={modalins}
        paddingTop={30}
        centered
        zIndex={9000}
        size={'lg'}
      >
        <ModalHeader>
            Nuevo Rol
        </ModalHeader>
        <ModalBody>
        <Grid container spacing={6}>
            <Grid item xs={12} md={12}>
              <TextField
                  multiline
                  InputProps={{
                    classes: {
                      underline: classes.textFieldUnderline,
                      input: classes.textField,
                    },
                  }}
                  onChange={handleChangenamerol}
                  label="Nombre Rol"
                  margin="normal"
                  required
                  type="text"
                  fullWidth
                />
                
            </Grid>
        </Grid>
        </ModalBody>
        <ModalFooter>
        <Grid >
        
            <Button
              variant="contained"
              color="success"
              size="small"
              onClick={()=>accionesModal()}
              style={{ marginLeft: 16 }}
            >
              Cerrar
            </Button>
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={()=>voidRoles(1)}
              style={{ marginLeft: 16 }}
            >
              Agregar
            </Button>
        </Grid>
        </ModalFooter>
      </Modal>

      <Modal
        isOpen={modalupd}
        paddingTop={30}
        centered
        zIndex={9000}
        size={'lg'}
      >
        <ModalHeader>
            Modificar Rol
        </ModalHeader>
        <ModalBody>
        <Grid container spacing={6}>
            <Grid item xs={12} md={12}>
              <TextField
                  InputProps={{
                    classes: {
                      input: classes.textField,
                    },
                  }}
                  defaultValue={mRol.grupoPersonasNombre}
                  onChange={handleChangenamerol}
                  label="Nombre Rol"
                  margin="normal"
                  required
                  type="text"
                  fullWidth
                />
                
            </Grid>
        </Grid>
        </ModalBody>
        <ModalFooter>
        <Grid >
        <Button
              variant="contained"
              color="success"
              size="small"
              onClick={()=>voidRoles(2)}
              style={{ marginLeft: 16 }}
            >
              Modificar
            </Button>
            <Button
              variant="contained"
              color="success"
              size="small"
              onClick={()=>accionesModalmod()}
              style={{ marginLeft: 16 }}
            >
              Cerrar
            </Button>
        </Grid>
        </ModalFooter>
      </Modal>



      <Modal
        isOpen={modalapp}
        paddingTop={30}
        centered
        zIndex={9000}
        size={'lg'}
      >
        <ModalHeader>
            Seleccione Aplicación
        </ModalHeader>
        <ModalBody>
        <Grid container spacing={6}>
            <Grid item xs={12} md={12}>
            <FormControl className={classes.formControl} fullWidth>
                    <InputLabel htmlFor="age-native-simple">Aplicacion</InputLabel>
                    <Select
                    native
                    onChange={handleChangeapp}
                    >
                      <option value="">Seleccione Aplicacion</option>
                    {mAplicaciones.map(app => (
                        <option
                        key={app.aplicacionId}
                        value={app.aplicacionId}
                        >
                        {app.aplicacionNombre}
                        </option>
                    ))}
                    </Select>
                </FormControl>
                
            </Grid>
        </Grid>
        </ModalBody>
        <ModalFooter>
        <Grid >
        
            <Button
              variant="contained"
              color="success"
              size="small"
              onClick={()=>accionesModalapp()}
              style={{ marginLeft: 16 }}
            >
              Cerrar
            </Button>
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={()=>voidapp(1)}
              style={{ marginLeft: 16 }}
            >
              Adicionar
            </Button>
        </Grid>
        </ModalFooter>
      </Modal>
      <Backdrop className={mclasses.backdrop} open={loading} >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}

// #######################################################################
