import React from "react";
import { SnackbarProvider } from 'notistack';
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";
import { positions, Provider } from "react-alert";
import AlertTemplate from "react-alert-template-basic";

// components
import Layout from "./Layout";

// pages
import Error from "../pages/error";
import Login from "../pages/login";
import Validar from "../pages/validar";

// context
import { useUserState } from "../context/UserContext";

export default function App() {
  // global
  var { isAuthenticated } = useUserState();
  const options = {
    timeout: 5000,
    position: positions.BOTTOM_RIGHT
  };
  return (
    <Provider template={AlertTemplate} {...options}>  
      <SnackbarProvider>
        <HashRouter>
          <Switch>
            <Route exact path="/" render={() => <Redirect to="/app/dashboard" />} />
            <Route
              exact
              path="/app"
              render={() => <Redirect to="/app/dashboard" />}
            />
            <PrivateRoute path="/app" component={Layout} />
            <PublicRoute path="/login" component={Login} />
            <PublicRoute path="/Validar" component={Validar} />
            <Route component={Error} />
          </Switch>
        </HashRouter>
      </SnackbarProvider>
    </Provider>  
    
  );

  // #######################################################################

  function PrivateRoute({ component, ...rest }) {
    return (
      <Route
        {...rest}
        render={props =>
          isAuthenticated ? (
            React.createElement(component, props)
          ) : (
            <Redirect
              to={{
                pathname: "/login",
                state: {
                  from: props.location,
                },
              }}
            />
          )
        }
      />
    );
  }

  function PublicRoute({ component, ...rest }) {
    return (
      <Route
        {...rest}
        render={props =>
          isAuthenticated ? (
            <Redirect
              to={{
                pathname: "/",
              }}
            />
          ) : (
            React.createElement(component, props)
          )
        }
      />
    );
  }
}
