import React ,{useState, useEffect}from "react";
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { Grid,TextField,Paper,Button,IconButton } from "@material-ui/core";
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Icon from '@material-ui/core/Icon';
import {Modal,ModalBody,ModalFooter,ModalHeader} from "reactstrap"
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import PrintIcon from '@material-ui/icons/Print';
import PrintDisabledIcon from '@material-ui/icons/PrintDisabled';
import VisibilityIcon from '@material-ui/icons/Visibility';
// styles
import useStyles from "./styles";
import "bootstrap/dist/css/bootstrap.min.css";
// components
import PageTitle from "../../components/PageTitle/PageTitle";
import Widget from "../../components/Widget/Widget";
import { Typography } from "../../components/Wrappers/Wrappers";
import mock from "../dashboard/mock";
import classnames from "classnames";
import {
  DataGrid,
  ColDef,
  ValueGetterParams,
  CellParams,
  GridApi
} from "@material-ui/data-grid";
import Constantes from "../../context/Constates";
import axios from 'axios'
import {Edit,Delete,PanoramaFishEyeRounded} from '@material-ui/icons';
import ReactExport from "react-export-excel";
import { SettingsSystemDaydreamTwoTone } from "@material-ui/icons";


export default function SeguimientoPage() {
  const museStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      '& > *': {
        margin: theme.spacing(1),
      },
      '& .super-app-theme--cell': {
        backgroundColor: 'rgba(224, 183, 60, 0.55)',
        color: '#1a3e72',
        fontWeight: '600',
      },
      '& .super-app.negative': {
        backgroundColor: '#d90007',
        color: '#FFFFFF',
        fontWeight: '600',
      },
      '& .super-app.positive': {
        backgroundColor: '#d47483',
        color: '#1a3e72',
        fontWeight: '600',
      },
    },
    small: {
      width: theme.spacing(3),
      height: theme.spacing(3),
    },
    large: {
      width: theme.spacing(15),
      height: theme.spacing(15),
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#008000',
    },
   
  }));
    let history = useHistory();
    const location = useLocation();
    var classes = useStyles();
  const mclasses = museStyles();
    const [mdata, setmdata] = React.useState([]);
    const [mcolumns, setmcolumns] = React.useState([]);
    const [muser, setuser] = React.useState([]);
    const [loading,setloading]=React.useState(false);
    var dataRecibido={};
    var Usuario=JSON.parse(localStorage.getItem('user'));

    var lSeguimiento=Constantes.URLSERVER+Constantes.APIGETSEGUIMIENTO
    var lSeguimientouser=Constantes.URLSERVER+Constantes.APIGETSEGUIMIENTOUSER
    var lreporte=Constantes.URLSERVER+Constantes.APIGETREPORTE
    var lstSeguimiento=[];
    var config = {
        headers: {'Access-Control-Allow-Origin': '*' , 'Authorization': 'Bearer '+localStorage.getItem('id_token')}
    };

    useEffect(()=>{
      if (location.state!=null) {
        dataRecibido=location.state.user;
        var iduser=Usuario.personalId;
        if (dataRecibido.personalId>0) {
          iduser=dataRecibido.personalId;
        }
        setuser(iduser);
        lSeguimiento=lSeguimiento+"?PersonalId="+iduser
      }
      //GetPersonaAreas();
    },[location])
    
    const ExcelFile = ReactExport.ExcelFile;
    const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
    const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

    /*datagrid */
    
    /*Solicitud */
    const[solselc,setsolselc]=useState({
        id: 0,
        procesoCodigo:'',
        solicitante:'',
        fechaSolicitud:'',
        estado:0,
        personalAsignado:'',
        personalIdGM:0,
        generalManager:'',
        personalIDRegionalCFO:0,
        regionalCFO:'',
        personalIDRegionalPA:0,
        regionalPresidentAmericas:'',
        position:'',
        tipoEstado:0
    })
    /*funciones extras modal */
    
    /*handles*/
    const handleChange=e=>{
        const { name, value}=e.target;
       /* setAreaselect({
            ...areaselc,
            [name]:value
        })*/
        
    }

    function loadData(data){
      
    }
    /*Peticiones*/ 
    
    const GetSolicitudes=async()=>{
        try {
            var data={};
            setloading(true);
            const resp = await axios.get(lSeguimiento,config);
            var result = resp.data;
            if(result.codigoRespuesta==0){

              var rows=result.valorRespuesta;
              rows.map(dt => {
                dt.id=dt.procesoId;
                dt.viewprint=false;
                if (dt.estado=="Fin"){
                  dt.viewprint=true;
                }
              });
              lstSeguimiento=rows;
              setmdata(rows);

                var columns=[
                       { field: 'procesoCodigo', headerName: 'Proceso Codigo', width: 180 },
                       { field: 'procesoId', headerName: 'Orden Compra',width: 180,renderCell: (params: CellParams) => {
                        const onClickedit = () => {
                          const api: GridApi = params.api;
                          const fields = api
                            .getAllColumns()
                            .map((c) => c.field)
                            .filter((c) => c !== "__check__" && !!c);
                          const thisRow = {};
                  
                          fields.forEach((f) => {
                            thisRow[f] = params.getValue(f);
                          });
                  
                          return  viewRepor(thisRow);
                        };
                        const onClickview = () => {
                          const api: GridApi = params.api;
                          const fields = api
                            .getAllColumns()
                            .map((c) => c.field)
                            .filter((c) => c !== "__check__" && !!c);
                          const thisRow = {};
                  
                          fields.forEach((f) => {
                            thisRow[f] = params.getValue(f);
                          });
                  
                          return  viewSolicitud(thisRow);
                        };
                        const defprint=()=>{
                          /*const api: GridApi = params.api;
                          const fields = api
                             .getAllColumns()
                             .map((c) => c.field)
                             .filter((c) => c !== "__check__" && !!c);
                          const thisRow = {};
                  
                          fields.forEach((f) => {
                             thisRow[f] = params.getValue(f);
                          });*/
                          if(params.row.viewprint){
                            return <div >
                                <label htmlFor="icon-button-file">
                                <IconButton  color="primary" onClick={onClickedit}  component="span">
                                <PrintIcon/>
                                </IconButton>
                                <IconButton  color="primary" onClick={onClickview}  component="span">
                                <VisibilityIcon/>
                                </IconButton>
                              </label>
                            </div>;
                          }else{
                            return <div >
                                <label htmlFor="icon-button-file">
                                <IconButton  color="primary" onClick={onClickview}  component="span">
                                <VisibilityIcon/>
                                </IconButton>
                              </label>
                            </div>;
                          }
                        }
                      // defprint()
                        if(params.row.viewprint){
                          return <div >
                              <label htmlFor="icon-button-file">
                              <IconButton  color="primary" onClick={onClickedit}  component="span">
                              <PrintIcon/>
                              </IconButton>
                              <IconButton  color="primary" onClick={onClickview}  component="span">
                              <VisibilityIcon/>
                              </IconButton>
                            </label>
                          </div>;
                        }else{
                          return <div >
                              <label htmlFor="icon-button-file">
                              <IconButton  color="primary"  component="span">
                              <PrintDisabledIcon/>
                              </IconButton>
                              <IconButton  color="primary" onClick={onClickview}  component="span">
                              <VisibilityIcon/>
                              </IconButton>
                            </label>
                          </div>;
                        }
                        
                        
                      }  },
                       { field: 'solicitante', headerName: 'Solicitante', width: 180 },
                       { field: 'fechaSolicitudStr', headerName: 'Fecha Solicitud', width: 180 },
                       { field: 'estado', headerName: 'Estado', width: 180 },
                       { field: 'personalAsignado', headerName: 'Personal Asignado', width: 170 },
                       { field: 'tareaFechaAsignacionStr', headerName: 'Fecha deAsignacion', width: 180 },
                       { field: 'description', headerName: 'Description', width: 180 },
                       { field: 'area', headerName: 'Area', width: 180 },
                       { field: 'centroCosto', headerName: 'Centro de Costo', width: 180 },
                       { field: 'nombre', headerName: 'Nombre', width: 180 },
                       { field: 'cargo', headerName: 'Cargo', width: 180 },
                       { field: 'proveedor', headerName: 'Proveedor', width: 180 },
                       { field: 'nit', headerName: 'Nit', width: 180 },
                       { field: 'nombreContacto', headerName: 'Nombre de Contacto', width: 180 },
                       { field: 'emailContacto', headerName: 'Email de Contacto', width: 180 },
                       { field: 'celular', headerName: 'Celular', width: 180 },
                       { field: 'telefonoOficina', headerName: 'Telefono Oficina', width: 180 },
                       { field: 'monto', headerName: 'Monto', width: 180 },
                       { field: 'saldo', headerName: 'Saldo', width: 180 },
                       { field: '', headerName: 'Pagado?', width: 180 },
                       
                     ];
                
                setmcolumns(columns);
            }else{
                
            }
            setloading(false);
            return data;
          } catch (err) {
              console.error(err);
          }
    }
    
    /*en peticiones */
    
  function viewRepor(data){
    var urlfile =lreporte+"ProcesoId="+data.procesoId;
    var win = window.open(urlfile, '_blank');
    win.focus();
    axios({
      url: urlfile,
      method: 'GET',
      headers: {
        'Content-Type': 'application/pdf',
      },
      responseType: 'blob', // important
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
      const link = document.createElement('a');
      link.href = url;
      //link.setAttribute('download', 'file.pdf');
      link.setAttribute('target', '_blank');
      //link.target="target";
      document.body.appendChild(link);
      link.click();
    });
  }
    function viewSolicitud(sol){
      var url =window.location.origin+"/#/app/Solcompra/"+sol.procesoId
      window.open(url, "_blank")
      // history.push({
      //   pathname: '../../app/Solcompra',
      //   state: { dtsolicitud: sol }
      // });
    }

    useEffect(()=>{
      GetSolicitudes();
    },[])

    
 
    
   

 
 
  return (
    <>
      <Grid container spacing={6}>
      <Grid item xs={12} md={16} style={{paddingBottom: 1}}>
        <Widget title="Seguimiento al Proceso de Solicitud de Compra y Emisión de Ordenes de Compra"  disableWidgetMenu   style={{paddingRight: 15}} headerClass={classes.widgetHeader}>
        
        <Grid item xs={6} md={6}>
            <ExcelFile filename={"Report Seguimiento"} element={ 
              <Button
              variant="contained"
              color="primary"
              className={classes.button}
              endIcon={<Icon>cloud_download</Icon>}>
              Exportar Excel
            </Button>}>
              <ExcelSheet data={mdata} name="Proveedores">
                <ExcelColumn label="Proceso" value="procesoId"/>
                <ExcelColumn label="Codigo de Proceso" value="procesoCodigo"/>
                <ExcelColumn label="Solicitante" value="solicitante"/>
                <ExcelColumn label="Fecha de Solicitud" value="fechaSolicitudStr"/>
                <ExcelColumn label="Estado" value="estado"/>
                <ExcelColumn label="Personal Asignado" value="personalAsignado"/>
                <ExcelColumn label="Fecha Asignacion" value="tareaFechaAsignacionStr"/>
                <ExcelColumn label="Description" value="description"/>
                <ExcelColumn label="Area" value="area"/>
                <ExcelColumn label="Centro de Costo" value="centroCosto"/>
                <ExcelColumn label="Nombre" value="nombre"/>
                <ExcelColumn label="Cargo" value="cargo"/>
                <ExcelColumn label="Proveedor" value="proveedor"/>
                <ExcelColumn label="Nit" value="nit"/>
                <ExcelColumn label="Nombre Contacto" value="nombreContacto"/>
                <ExcelColumn label="Email Contacto" value="emailContacto"/>
                <ExcelColumn label="Celular" value="celular"/>
                <ExcelColumn label="Telefono Oficina" value="telefonoOficina"/>
                <ExcelColumn label="Monto" value="monto"/>
                <ExcelColumn label="Saldo" value="saldo"/>
              </ExcelSheet>
                
            </ExcelFile> 
            </Grid>
        </Widget>
        </Grid>
        <Grid item xs={12} md={16} style={{paddingBottom: 1}}>
          <Widget  disableWidgetMenu>
          <div style={{ height: 700 , padding:0}}>
            <DataGrid 
            rows={mdata} 
            columns={mcolumns}  
            pageSize={15}
            columnBuffer={2}
            showToolbar 
            density="compact"
            /*onRowSelected={(newSelection) => {
              var sol=newSelection.data;
                      history.push({
                        pathname: '../../app/Solcompra',
                        state: { dtsolicitud: sol }
                      });
                      console.log(newSelection.data);
              }}*/
            filterModel={{
              items: [
                { columnField: 'procesoCodigo', operatorValue: 'contains', value: '' },
              ],
            }}
             />
          </div>
              
          </Widget>
        </Grid>
       
     
        <Grid item xs={12} md={16} style={{paddingBottom: 1}}>
        <Widget noWidgetShadow disableWidgetMenu noBodyPadding noHeaderPadding style={{paddingRight: 15}} headerClass={classes.widgetHeader}>
        
        
        </Widget>
        </Grid>
      </Grid>

      <Backdrop className={mclasses.backdrop} open={loading} >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}
