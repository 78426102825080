import React, { useState } from "react";
import {
  Grid,
  CircularProgress,
  Typography,
  Button,
  Tabs,
  Tab,
  TextField,
  Fade,
} from "@material-ui/core";
import { withRouter } from "react-router-dom";
import classnames from "classnames";

// styles
import useStyles from "./styles";

// logo
import logo from "./logo.svg";
import logobtc from "./logo_btc_medio.png";
import google from "../../images/google.svg";
import {Modal,ModalBody,ModalFooter,ModalHeader} from "reactstrap"

// context
import { useUserDispatch, loginUser,passwordrec,cambiarcontra } from "../../context/UserContext";



function Login(props) {
  var classes = useStyles();
 
  // global
  var userDispatch = useUserDispatch();

  // local
  var [isLoading, setIsLoading] = useState(false);
  var [error, setError] = useState(false);
  var [mensaje, setMensaje] = useState("");
  var [activeTabId, setActiveTabId] = useState(0);
  var [nameValue, setNameValue] = useState("");
  var [loginValue, setLoginValue] = useState("");
  var [passwordValue, setPasswordValue] = useState("");
  var [passwordACT, setPasswordACT] = useState("");
  var [passwordNEW, setPasswordNEW] = useState("");
  var [passwordRNEW, setPasswordRNEW] = useState("");
  const [modal, setmodal] = React.useState(false);
  const [ModalCE, setModalCE] = React.useState(false);

  const accionesModalapp=(datauser)=>{
    setmodal(!modal)
    setMensaje("")
    setNameValue(datauser)
  }
  return (

    <>
    <Grid container className={classes.container}>
      <div className={classes.logotypeContainer}>
        <img src={logo} alt="logo" className={classes.logotypeImage} />
      </div>
      <div className={classes.formContainer}>
      <img src={logobtc} alt="logo" className={classes.logotypeImage} />
        <div className={classes.form}>
          <Tabs
            value={activeTabId}
            onChange={(e, id) => setActiveTabId(id)}
            indicatorColor="primary"
            textColor="primary"
            centered
          >
            <Tab label="P2P" classes={{ root: classes.tab }} />
          </Tabs>
          {activeTabId === 0 && (
            <React.Fragment>
              <Typography variant="h1" className={classes.greeting}>
                Bienvenido
              </Typography>
              <Fade in={error}>
                <Typography color="secondary" className={classes.errorMessage}>
                  {mensaje}
                </Typography>
              </Fade>
              <TextField
                id="email"
                InputProps={{
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField,
                  },
                }}
                value={loginValue}
                onChange={e => setLoginValue(e.target.value)}
                margin="normal"
                placeholder="Cuenta "
                type="text"
                fullWidth
              />
              <TextField
                id="password"
                InputProps={{
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField,
                  },
                }}
                value={passwordValue}
                onChange={e => setPasswordValue(e.target.value)}
                margin="normal"
                placeholder="Clave"
                type="password"
                fullWidth
              />
              <div className={classes.formButtons}>
                {isLoading ? (
                  <CircularProgress size={26} className={classes.loginLoader} />
                ) : (
                  <Button
                    disabled={
                      loginValue.length === 0 || passwordValue.length === 0 
                    }
                    
                    onClick={() =>
                      loginUser(
                        userDispatch,
                        loginValue,
                        passwordValue,
                        props.history,
                        setIsLoading,
                        setError,
                        setMensaje,
                        setModalCE
                      )
                    }
                    variant="contained"
                    color="primary"
                    size="large"
                    style={{color:"#FFFFFF" }}
                  >
                    Ingresar
                  </Button>
                )}
                <Button
                  color="primary"
                  size="large"
                  className={classes.forgetButton}
                  onClick={()=>accionesModalapp(loginValue)}
                >
                  Recuperar Contraseña
                </Button>
              </div>
            </React.Fragment>
          )}
        </div>
        <Typography color="primary" className={classes.copyright}>
          © 2021 BANTIC Software S.R.L., Todos los derechos reservados.
        </Typography>
      </div>
    </Grid>
    

    <Modal
        isOpen={modal}
        paddingTop={30}
        centered
        zIndex={9000}
        size={'lg'}
      >
        <ModalHeader>
        Enviar correo de recuperación de clave
        </ModalHeader>
        <ModalBody>
            <Grid container spacing={6}  xs={12} md={12}>
              <Grid item xs={12} md={12}>
              <TextField
                  InputProps={{
                    classes: {
                      underline: classes.textFieldUnderline,
                      input: classes.textField,
                    },
                  }}
                  label="Nombre Usuario"
                  margin="normal"
                  required
                  value={nameValue}
                  onChange={e => setNameValue(e.target.value)}
                  type="text"
                  fullWidth
                />
                <Typography color="secondary" className={classes.errorMessage}>
                  {mensaje}
                </Typography>
              </Grid>
              
            </Grid>
        </ModalBody>
        <ModalFooter>
        <Grid >
        
            <Button
              variant="contained"
              color="warning"
              size="small"
              onClick={()=>accionesModalapp("")}
              style={{ marginLeft: 16 ,color:"#FFFFFF"}}
            >
              Cancelar
            </Button>
            {isLoading ? (
                  <CircularProgress size={26} className={classes.loginLoader} />
                ) : (
                  
            <Button
              variant="contained"
              color="primary"
              size="small"
              style={{ marginLeft: 16,color:"#FFFFFF" }}
              onClick={
                ()=>passwordrec(
                        nameValue,
                        setmodal,
                        setMensaje,
                        setIsLoading
                      )
              }
            >
              Enviar
            </Button>
             )}
        </Grid>
        </ModalFooter>
      </Modal>

      <Modal
              isOpen={ModalCE}
              paddingTop={30}
              centered
              zIndex={9000}
              size={'xl'}
            >
                <ModalHeader>
                    Cambiar Contraseña
                </ModalHeader>
                <ModalBody>
                    <Grid container spacing={6}  xs={12} md={12}>
                      <Grid item xs={6} md={6}>
                      <TextField
                          InputProps={{
                            classes: {
                              underline: classes.textFieldUnderline,
                              input: classes.textField,
                            },
                          }}
                          value={passwordACT}
                          onChange={e => setPasswordACT(e.target.value)}
                          label="Clave Actual"
                          margin="normal"
                          required
                          type="password"
                          fullWidth
                        />
                      <TextField
                          InputProps={{
                            classes: {
                              underline: classes.textFieldUnderline,
                              input: classes.textField,
                            },
                          }}
                          value={passwordNEW}
                          onChange={e => setPasswordNEW(e.target.value)}
                          label="Clave Nueva"
                          margin="normal"
                          required
                          type="password"
                          fullWidth
                        />
                        <TextField
                          InputProps={{
                            classes: {
                              underline: classes.textFieldUnderline,
                              input: classes.textField,
                            },
                          }}
                          value={passwordRNEW}
                          onChange={e => setPasswordRNEW(e.target.value)}
                          label="Repetir Clave"
                          margin="normal"
                          required
                          type="password"
                          fullWidth
                        />
                         <Typography color="secondary" className={classes.errorMessage}>
                            {mensaje}
                          </Typography>
                      </Grid>
                      <Grid item xs={6} md={6}>
                        <br/>
                        <label>1. La clave no debe ser igual a la clave de reseteo</label>
                        <br/>
                        <label>2. La clave no debe contener espacios vacíos</label>
                        <br/>
                        <label>3. La clave debe ser de 8 o más dígitos</label>
                        <br/>
                        <label>4. La clave debe contener al menos 3 letras mayúsculas</label>
                        <br/>
                        <label>5. La clave debe contener al menos 3 letras minúsculas</label>
                        <br/>
                        <label>6. La clave debe contener al menos 1 número</label>
                        <br/>
                        <label>7. La clave debe contener al menos 1 caracter especial ! # $ & ( ) * + - , . /</label>
                        <br/>
                        </Grid>
                    </Grid>
                </ModalBody>
                <ModalFooter>
                <Grid >
                    
                    <Button
                      variant="contained"
                      color="warning"
                      size="small"
                      onClick={()=>setModalCE(false)}
                      style={{ marginLeft: 16 ,color:"#FFFFFF"}}
                    >
                      Cancelar
                    </Button>

                    {isLoading ? (
                      <CircularProgress size={26} className={classes.loginLoader} />
                    ) : (
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      disabled={
                        passwordACT.length === 0 || passwordNEW.length === 0 || passwordRNEW.length === 0 
                      }
                      onClick={()=>cambiarcontra(passwordACT,passwordNEW,passwordRNEW,setModalCE,setMensaje,setIsLoading)}
                      style={{ marginLeft: 16,color:"#FFFFFF" }}
                    >
                      Cambiar
                    </Button>
                    )}
                </Grid>
                </ModalFooter>
              </Modal>
    </>


  );
}

export default withRouter(Login);
