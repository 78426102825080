import React ,{useState, useEffect}from "react";
import { Grid,TextField,Paper,Button,IconButton } from "@material-ui/core";
import {Modal,ModalBody,ModalFooter,ModalHeader} from "reactstrap"
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import ReactExport from "react-export-excel";
import Icon from '@material-ui/core/Icon';

// styles
import useStyles from "./styles";
import "bootstrap/dist/css/bootstrap.min.css";
// components
import PageTitle from "../../components/PageTitle/PageTitle";
import Widget from "../../components/Widget/Widget";
import { Typography } from "../../components/Wrappers/Wrappers";
import mock from "../dashboard/mock";
import classnames from "classnames";
import {
  DataGrid,
  ColDef,
  ValueGetterParams,
  CellParams,
  GridApi
} from "@material-ui/data-grid";
import Constantes from "../../context/Constates";
import axios from 'axios'
import {Edit,Delete,PanoramaFishEyeRounded} from '@material-ui/icons';
import { useAlert } from "react-alert";
import { SettingsSystemDaydreamTwoTone } from "@material-ui/icons";


export default function AbmPage() {
    var classes = useStyles();
    const alert = useAlert();
    const [mdata, setmdata] = React.useState([]);
    const [mcolumns, setmcolumns] = React.useState([]);
    const [mPersonas, setmPersonas] = React.useState([]);
    const [mPersona, setmPersona] = React.useState({});
    const [modalins, setmodalins] = React.useState(false);
    const [modaleditar, setmodaleditar] = React.useState(false);
    const [modalver, setmodalver] = React.useState(false);
    const [mareasel, setmareasel] = React.useState({});

    const ExcelFile = ReactExport.ExcelFile;
    const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
    const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

    var lareas=Constantes.URLSERVER+Constantes.APIGETLISTAAREA
    var lPersareas=Constantes.URLSERVER+Constantes.APIGETPERSAREA
    var lVoidareas=Constantes.URLSERVER+Constantes.APIPOSTAREA
    var lstArea=[];
    var config = {
        headers: {'Access-Control-Allow-Origin': '*' , 'Authorization': 'Bearer '+localStorage.getItem('id_token')}
    };
    var configjson = {
        headers: {'Content-Type': 'application/json' , 'Authorization': 'Bearer '+localStorage.getItem('id_token')}
    };
    /*datagrid */
    
    /*Area */
    const[areaselc,setAreaselect]=useState({
        id: 0,
        area:'',
        costCenter:'',
        description:'',
        personalIdManager:0,
        manager:'',
        personalIdGM:0,
        generalManager:'',
        personalIDRegionalCFO:0,
        regionalCFO:'',
        personalIDRegionalPA:0,
        regionalPresidentAmericas:'',
        position:'',
        tipoEstado:0
    })
    /*funciones extras modal */
    const accionesModal=()=>{
        setmodalins(!modalins)
    }
    const accionesModalVer=()=>{
      setmodalver(!modalver)
    }
    const accionesModalEdit=()=>{
      setmodaleditar(!modaleditar)
  }
    /*handles*/
    const handleChange=e=>{
        const { name, value}=e.target;
        setAreaselect({
            ...areaselc,
            [name]:value
        })
        
    }
    /*Peticiones*/ 
    const GetPersonaAreas=async()=>{
        try {
            var data={};
            const resp = await axios.get(lPersareas,config);
            var result = resp.data;
            if(result.codigoRespuesta==0){
                var pers=result.valorRespuesta;
                setmPersonas(pers);
            }else{
              alert.show(result.mensajeRespuesta);
            }
          } catch (err) {
              console.error(err);
          }
    }
    const GetAreas=async()=>{
        try {
            var data={};
            const resp = await axios.get(lareas,config);
            var result = resp.data;
            if(result.codigoRespuesta==0){
       
                var columns=[
                  { field: '', headerName: 'Acciones', width: 200 ,
                       renderCell: (params: CellParams) => {
                        const onClickedit = () => {
                          const api: GridApi = params.api;
                          const fields = api
                            .getAllColumns()
                            .map((c) => c.field)
                            .filter((c) => c !== "__check__" && !!c);
                          const thisRow = {};
                  
                          fields.forEach((f) => {
                            thisRow[f] = params.getValue(f);
                          });
                  
                          return  editArea(thisRow);
                        };
                        const onClickdel = () => {
                          const api: GridApi = params.api;
                          const fields = api
                            .getAllColumns()
                            .map((c) => c.field)
                            .filter((c) => c !== "__check__" && !!c);
                          const thisRow = {};
                  
                          fields.forEach((f) => {
                            thisRow[f] = params.getValue(f);
                          });
                  
                          return  deletArea(thisRow);
                        };
                        const onClickver = () => {
                          const api: GridApi = params.api;
                          const fields = api
                            .getAllColumns()
                            .map((c) => c.field)
                            .filter((c) => c !== "__check__" && !!c);
                          const thisRow = {};
                  
                          fields.forEach((f) => {
                            thisRow[f] = params.getValue(f);
                          });
                  
                          return  verArea(thisRow);
                        };
                        return <div>
                                <label htmlFor="icon-button-file">
                                <IconButton color="primary" onClick={onClickver} component="span">
                                <PanoramaFishEyeRounded/>
                                </IconButton>
                                <IconButton color="primary" onClick={onClickedit}  component="span">
                                <Edit/>
                                </IconButton>
                                <IconButton color="primary" onClick={onClickdel} component="span">
                                <Delete/>
                                </IconButton>
                              </label>
                            </div>;
                      }},
                       { field: 'id', headerName: '#', width: 80 },
                       { field: 'area', headerName: 'Area', resizable: true,width: 180  },
                       { field: 'costCenter', headerName: 'Centro de Costo', width: 180 },
                       { field: 'description', headerName: 'Description', width: 150 },
                       { field: 'manager', headerName: 'Manager', width: 180 },
                       { field: 'generalManager', headerName: 'Gral. Manager', width: 180 },
                       { field: 'regionalCFO', headerName: 'Regional CFO', width: 180 },
                       { field: 'regionalPresidentAmericas', headerName: 'Regional Americas', width: 180 },
                       { field: 'position', headerName: 'Cargo', width: 190 }
                       
                     ];
                var rows=result.valorRespuesta;
                lstArea=rows;
                setmcolumns(columns);
                setmdata(rows);
            }else{
              alert.show(result.mensajeRespuesta);  
            }
            return data;
          } catch (err) {
              console.error(err);
          }
    }
    const voidAreas=async(tipo)=>{
        try {
          var data={};

          if(tipo==1){
            areaselc.id=parseInt(areaselc.id);
            areaselc.personalIdManager=parseInt(areaselc.personalIdManager);
            areaselc.personalIdGM=parseInt(areaselc.personalIdGM);
            areaselc.personalIDRegionalCFO=parseInt(areaselc.personalIDRegionalCFO);
            areaselc.personalIDRegionalPA=parseInt(areaselc.personalIDRegionalPA);
            mPersonas.map(pers => {
              if(areaselc.personalIdManager==pers.personalId){
                  areaselc.manager=pers.personalNombre;
              }
              if(areaselc.personalIdGM==pers.personalId){
                areaselc.generalManager=pers.personalNombre;
              }
              if(areaselc.personalIDRegionalCFO==pers.personalId){
                areaselc.regionalCFO=pers.personalNombre;
              }
              if(areaselc.personalIDRegionalPA==pers.personalId){
                areaselc.regionalPresidentAmericas=pers.personalNombre;
              }
            });
          }
            areaselc.tipoEstado=tipo;
            const json = JSON.stringify(areaselc);
            console.log(configjson);
            const resp = await axios.post(lVoidareas,json,configjson);
            var result = resp.data;
            if(result.codigoRespuesta==0){
              if(tipo==1){
                accionesModal();
              }else{  
                accionesModalEdit();
              }
              setmdata(mdata.concat(result.valorRespuesta));
              lstArea=mdata;
              alert.success(result.mensajeRespuesta);
            }else{
              alert.show(result.mensajeRespuesta);  
            }
            return data;
          } catch (err) {
              console.error(err);
          }
    }
    /*en peticiones */
    
    useEffect(()=>{
        GetAreas();
        GetPersonaAreas();
    },[])
    function verArea(vardata){
      if (vardata && vardata.id>0) {
        lstArea.map(dt => {
          if(vardata.id==dt.id){
              setmareasel(dt);
              return;
          }
        });
        accionesModalVer();
      }
   }
   function editArea(vardata){
      if (vardata && vardata.id>0) {
        lstArea.map(dt => {
          if(vardata.id==dt.id){
              setmareasel(dt);
              setAreaselect(dt);
              return;
          }
        });
        accionesModalEdit();
      }
   }
   function deletArea(vardata){
      setmareasel(vardata);
      setAreaselect(vardata);
      voidAreas(3);
   }
 
    
   

 
 
  return (
    <>
      <Grid container spacing={6}>
      <Grid item xs={12} md={16} style={{paddingBottom: 1}}>
        <Widget title="Administracion de Areas"  disableWidgetMenu   style={{paddingRight: 15}} headerClass={classes.widgetHeader}>
        <Button
          variant="contained"
          size="small"
          color="primary"
          className={classes.button}
          style={{ marginLeft: 16 }}
          onClick={()=>accionesModal()}
        >
          Agregar
        </Button>
     
        
        </Widget>
        </Grid>
      <Grid item xs={12} md={16} style={{paddingBottom: 1}}>
          <Widget  disableWidgetMenu>
          <ExcelFile element={<Button
              variant="contained"
              color="primary"
              className={classes.button}
              endIcon={<Icon>cloud_download</Icon>}>
              Exportar Excel
            </Button>}>
              <ExcelSheet data={mdata} name="Areas">
                <ExcelColumn label="Area" value="area"/>
                <ExcelColumn label="Cost Center" value="costCenter"/>
                <ExcelColumn label="Description" value="description"/>
                <ExcelColumn label="General Manager" value="generalManager"/>
                <ExcelColumn label="Manager" value="manager"/>
                <ExcelColumn label="Personal ID Regional CFO" value="personalIDRegionalCFO"/>
                <ExcelColumn label="Personal ID Regional PA" value="personalIDRegionalPA"/>
                <ExcelColumn label="Personal IdGM" value="personalIdGM"/>
                <ExcelColumn label="Personal IdManager" value="personalIdManager"/>
                <ExcelColumn label="Position" value="position"/>
                <ExcelColumn label="Regional CFO" value="regionalCFO"/>
                <ExcelColumn label="Regional Americas" value="regionalPresidentAmericas"/>
              </ExcelSheet>
                
            </ExcelFile>  
            <br/>
          <div style={{ height: 700 , padding:0}}>
            <DataGrid 
            rows={mdata} 
            columns={mcolumns}  
            pageSize={10}
            showToolbar 
            filterModel={{
              items: [
                { columnField: 'area', operatorValue: 'contains', value: '' },
              ],
            }}
             />
          </div>
              
          </Widget>
        </Grid>
       
     
        <Grid item xs={12} md={16} style={{paddingBottom: 1}}>
        <Widget noWidgetShadow disableWidgetMenu noBodyPadding noHeaderPadding style={{paddingRight: 15}} headerClass={classes.widgetHeader}>
        
        
        </Widget>
        </Grid>
      </Grid>

      <Modal
        isOpen={modalins}
        paddingTop={20}
        zIndex={5000}
        centered
      >
        <ModalHeader>
            Registrar Area
        </ModalHeader>
        <ModalBody>
        <Grid container spacing={6}>
            <Grid item xs={12} md={12}>
            <TextField
                  multiline
                  id="id"
                  name="id"
                  InputProps={{
                    classes: {
                      underline: classes.textFieldUnderline,
                      input: classes.textField,
                    },
                  }}
                  label="Id"
                  hidden
                  margin="normal"
                  placeholder="Id"
                  type="text"
                  fullWidth
                />
              <TextField
                  multiline
                  id="area"
                  name="area"
                  InputProps={{
                    classes: {
                      underline: classes.textFieldUnderline,
                      input: classes.textField,
                    },
                  }}
                  label="Area"
                  margin="normal"
                  required
                  placeholder="Area"
                  onChange={handleChange}
                  type="text"
                  fullWidth
                />
                <TextField
                  multiline
                  id="costCenter"
                  name="costCenter"
                  InputProps={{
                    classes: {
                      underline: classes.textFieldUnderline,
                      input: classes.textField,
                    },
                  }}
                  label="Cost Center"
                  margin="normal"
                  required
                  placeholder="Cost Center"
                  onChange={handleChange}
                  type="text"
                  fullWidth
                />
                <TextField
                  multiline
                  id="description"
                  name="description"
                  InputProps={{
                    classes: {
                      underline: classes.textFieldUnderline,
                      input: classes.textField,
                    },
                  }}
                  label="Descripcion"
                  margin="normal"
                  required
                  placeholder="Descripcion"
                  onChange={handleChange}
                  type="text"
                  fullWidth
                />
                <FormControl className={classes.formControl} fullWidth>
                    <InputLabel htmlFor="age-native-simple">Manager</InputLabel>
                    <Select
                    native
                    id="personalIdManager"
                    name="personalIdManager"
                    onChange={handleChange}
                    >
                        <option value="">Seleccione Personal</option>
                    {mPersonas.map(pers => (
                        <option
                        key={pers.personalId}
                        value={pers.personalId}
                        >
                        {pers.personalNombre}
                        </option>
                    ))}
                    </Select>
                </FormControl>
                <FormControl className={classes.formControl} fullWidth>
                    <InputLabel htmlFor="age-native-simple">General Manager</InputLabel>
                    <Select
                    native
                    id="personalIdGM"
                    name="personalIdGM"
                    onChange={handleChange}
                    >
                        <option value="">Seleccione Personal</option>
                    {mPersonas.map(pers => (
                        <option
                        key={pers.personalId}
                        value={pers.personalId}
                        >
                        {pers.personalNombre}
                        </option>
                    ))}
                    </Select>
                </FormControl>
                <FormControl className={classes.formControl} fullWidth>
                    <InputLabel htmlFor="age-native-simple">Regional CFO</InputLabel>
                    <Select
                    native
                    id="personalIDRegionalCFO"
                    name="personalIDRegionalCFO"
                    onChange={handleChange}
                    >
                        <option value="">Seleccione Personal</option>
                    {mPersonas.map(pers => (
                        <option
                        key={pers.personalId}
                        value={pers.personalId}
                        >
                        {pers.personalNombre}
                        </option>
                    ))}
                    </Select>
                </FormControl>
                <FormControl className={classes.formControl} fullWidth>
                    <InputLabel htmlFor="age-native-simple">Regional America</InputLabel>
                    <Select
                    native
                    id="personalIDRegionalPA"
                    name="personalIDRegionalPA"
                    onChange={handleChange}
                    >
                        <option value="">Seleccione Personal</option>
                    {mPersonas.map(pers => (
                        <option
                        key={pers.personalId}
                        value={pers.personalId}
                        >
                        {pers.personalNombre}
                        </option>
                    ))}
                    </Select>
                </FormControl>
            </Grid>
        </Grid>
        </ModalBody>
        <ModalFooter>
            <Button className="btn btn-danger" onClick={()=>accionesModal()} >Cancelar</Button>
            <Button className="btn btn-primary" onClick={()=>voidAreas(1)} > Guardar</Button>
        </ModalFooter>
      </Modal>
      

      <Modal
        isOpen={modaleditar}
        paddingTop={20}
        zIndex={5000}
        size={'lg'}
        centered
      >
        <ModalHeader>
            Editar Area
        </ModalHeader>
        <ModalBody>
        <Grid container spacing={6}>
            <Grid item xs={12} md={12}>
            
                <div className="form-group">
                  <label>Id Area:</label>
                  <br/>
                  <input id="id" name="id" type="text" onChange={handleChange} readOnly className="form-control" defaultValue={mareasel && mareasel.id}></input>
                  <br/>
                  <label>Area:</label>
                  <br/>
                  <input id="area" name="area" type="text" onChange={handleChange} className="form-control" defaultValue={mareasel && mareasel.area}></input>
                  <br/>
                  <label>Cost Center:</label>
                  <br/>
                  <input id="costCenter" name="costCenter" onChange={handleChange} type="text" className="form-control" defaultValue={mareasel && mareasel.costCenter}></input>
                  <br/>
                  <label>Descripcion:</label>
                  <br/>
                  <input id="description" name="description" onChange={handleChange} type="text" className="form-control" defaultValue={mareasel && mareasel.description}></input>
                  <br/>
                  <label>Manager:</label>
                  <br/>
                  <select id="personalIdManager" name="personalIdManager" onChange={handleChange}  className="form-control" value={mareasel && mareasel.personalIdManager}>
                    <option value="" >Seleccione Personal</option>
                    {mPersonas.map(pers => (
                        <option
                        key={pers.personalId}
                        value={pers.personalId}
                        >
                        {pers.personalNombre}
                        </option>
                    ))}
                    </select>
                    <br/>
                    <label>General Manager:</label>
                    <br/>
                    <select id="personalIdGM" name="personalIdGM" onChange={handleChange} className="form-control" value={mareasel && mareasel.personalIdGM}>
                    <option value="">Seleccione Personal</option>
                    {mPersonas.map(pers => (
                        <option
                        key={pers.personalId}
                        value={pers.personalId}
                        >
                        {pers.personalNombre}
                        </option>
                    ))}
                    </select>
                    <br/>
                    <label>Regional CFO:</label>
                    <br/>
                    <select id="personalIDRegionalCFO" name="personalIDRegionalCFO" onChange={handleChange} className="form-control" value={mareasel && mareasel.personalIDRegionalCFO}>
                    <option value="">Seleccione Personal</option>
                    {mPersonas.map(pers => (
                        <option
                        key={pers.personalId}
                        value={pers.personalId}
                        >
                        {pers.personalNombre}
                        </option>
                    ))}
                    </select>
                    <br/>
                    <label>Regional America:</label>
                    <br/>
                    <select id="personalIDRegionalPA" name="personalIDRegionalPA" onChange={handleChange} className="form-control" value={mareasel && mareasel.personalIDRegionalPA}>
                    <option value="">Seleccione Personal</option>
                    {mPersonas.map(pers => (
                        <option
                        key={pers.personalId}
                        value={pers.personalId}
                        >
                        {pers.personalNombre}
                        </option>
                    ))}
                    </select>
                </div>
                
            </Grid>
        </Grid>
        </ModalBody>
        <ModalFooter>
            <Button className="btn btn-danger" onClick={()=>accionesModalEdit()} >Cancelar</Button>
            <Button className="btn btn-primary" onClick={()=>voidAreas(2)} > Guardar</Button>
        </ModalFooter>
      </Modal>
      
        <Modal
        isOpen={modalver}
        paddingTop={20}
        zIndex={5000}
        centered
        >
        <ModalHeader>
            Ver Area
        </ModalHeader>
        <ModalBody>
        <Grid container spacing={6}>
            <Grid item xs={12} md={12}>
            <TextField
                  multiline
                  id="id"
                  name="id"
                  InputProps={{
                    classes: {
                      underline: classes.textFieldUnderline,
                      input: classes.textField,
                    },
                  }}
                  label="Id"
                  value={mareasel && mareasel.id}
                  margin="normal"
                  type="text"
                  fullWidth
                />
              <TextField
                  multiline
                  id="area"
                  name="area"
                  InputProps={{
                    classes: {
                      underline: classes.textFieldUnderline,
                      input: classes.textField,
                    },
                  }}
                  label="Area"
                  margin="normal"
                  value={mareasel && mareasel.area}
                  type="text"
                  fullWidth
                />
                <TextField
                  multiline
                  id="costCenter"
                  name="costCenter"
                  InputProps={{
                    classes: {
                      underline: classes.textFieldUnderline,
                      input: classes.textField,
                    },
                  }}
                  label="Cost Center"
                  margin="normal"
                  value={mareasel && mareasel.costCenter}
                  type="text"
                  fullWidth
                />
                <TextField
                  multiline
                  id="description"
                  name="description"
                  InputProps={{
                    classes: {
                      underline: classes.textFieldUnderline,
                      input: classes.textField,
                    },
                  }}
                  label="Descripcion"
                  margin="normal"
                  value={mareasel && mareasel.description}
                  type="text"
                  fullWidth
                />
               <TextField
                  multiline
                  id="manager"
                  name="manager"
                  InputProps={{
                    classes: {
                      underline: classes.textFieldUnderline,
                      input: classes.textField,
                    },
                  }}
                  label="Manager"
                  margin="normal"
                  value={mareasel && mareasel.manager}
                  type="text"
                  fullWidth
                />
                <TextField
                  multiline
                  id="generalManager"
                  name="generalManager"
                  InputProps={{
                    classes: {
                      underline: classes.textFieldUnderline,
                      input: classes.textField,
                    },
                  }}
                  label="General Manager"
                  margin="normal"
                  value={mareasel && mareasel.generalManager}
                  type="text"
                  fullWidth
                />
               <TextField
                  multiline
                  id="regionalCFO"
                  name="regionalCFO"
                  InputProps={{
                    classes: {
                      underline: classes.textFieldUnderline,
                      input: classes.textField,
                    },
                  }}
                  label="Regional CFO"
                  margin="normal"
                  value={mareasel && mareasel.regionalCFO}
                  type="text"
                  fullWidth
                />
                <TextField
                  multiline
                  id="regionalPresidentAmericas"
                  name="regionalPresidentAmericas"
                  InputProps={{
                    classes: {
                      underline: classes.textFieldUnderline,
                      input: classes.textField,
                    },
                  }}
                  label="Regional America"
                  margin="normal"
                  value={mareasel && mareasel.regionalPresidentAmericas}
                  type="text"
                  fullWidth
                />
            </Grid>
        </Grid>
        </ModalBody>
        <ModalFooter>
            <Button className="btn btn-danger" onClick={()=>accionesModalVer()} >Cerrar</Button>
        </ModalFooter>
      </Modal>
      
    </>
  );
}
