import React, { useState,useEffect } from "react";
import {
  Grid,
  LinearProgress,
  Select,
  OutlinedInput,
  MenuItem,
  TextField,
  Button
} from "@material-ui/core";
import { useTheme } from "@material-ui/styles";
import {
  ResponsiveContainer,
  ComposedChart,
  AreaChart,
  LineChart,
  Line,
  Area,
  PieChart,
  Pie,
  Cell,
  YAxis,
  XAxis,
} from "recharts";
import { useSnackbar } from 'notistack';
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import {Modal,ModalBody,ModalFooter,ModalHeader} from "reactstrap"
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
// styles
import {
  DataGrid,
  ColDef,
  ValueGetterParams,
  CellParams,
  GridApi
} from "@material-ui/data-grid";
// components
import axios from 'axios'
import Widget from "../../components/Widget/Widget";

import { withStyles, makeStyles } from '@material-ui/core/styles';

import Constantes from "../../context/Constates";

import { useUserDispatch, signOut } from "../../context/UserContext";
import List from '@material-ui/core/List';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Checkbox from '@material-ui/core/Checkbox';
import Divider from '@material-ui/core/Divider';

  /*rutas */
  var obtenerTareas=Constantes.URLSERVER+Constantes.APIGETTAREAS
  var voidcontra=Constantes.URLSERVER+Constantes.APIPOSTPASSWORD
  var obteneruserroles=Constantes.URLSERVER+Constantes.APIGETROLESUSER

const useStyles = makeStyles((theme) =>({
  table: {
    minWidth: 700,
  },
  root: {
    margin: 'auto',
  },
  cardHeader: {
    padding: theme.spacing(1, 2),
  },
  list: {
    width: 500,
    height: 230,
    backgroundColor: theme.palette.background.paper,
    overflow: 'auto',
  },
  button: {
    margin: theme.spacing(0.5, 0),
  },
}));

function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
  var inter=[];
  a.map(dt=>{
    b.map(st=>{
      if(a.tareaId==b.tareaId){
        inter.push(dt);
      }
    })
  });
  console.log("a "+a);
  console.log("b "+b);
  console.log(inter);
  return inter;
}

function union(a, b) {
  return [...a, ...not(b, a)];
}

export default function Dashboard(props) {
  const classes = useStyles();
  let history = useHistory();
  var theme = useTheme();
  var userDispatch = useUserDispatch();

  var dataRecibido={};
  var configjson = {
    headers: {'Content-Type': 'application/json'},
    headers: {'Authorization': 'Bearer '+localStorage.getItem('id_token')}
  };
  const location = useLocation();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  // local
  var Usuario=0;
  var cambiarcontrauser=JSON.parse(localStorage.getItem('userpass'));
  var mensajes=JSON.parse(localStorage.getItem('mensajes'));
  const [mtareas, setmtareas] = React.useState([]);
  const [mUsuarios, setmUsuarios] = React.useState([]);
  const [mUsuariosdes, setmUsuariosdes] = React.useState([]);
  const [mtareaspagos, setmtareaspagos] = React.useState([]);
  const [UserOrigen, setUserOrigen] = React.useState();
  
  

  /*Peticiones*/ 
  useEffect(()=>{
      GetUser()    
    //GetPersonaAreas();
  },[location])


  const [checked, setChecked] = React.useState([]);
  const [left, setLeft] = React.useState([]);
  const [right, setRight] = React.useState([]);
  const [leftpago, setLeftpago] = React.useState([]);
  const [rightpago, setRightpago] = React.useState([]);

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const handleToggle = (value) => () => {
    console.log(value);
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    console.log("newche "+leftChecked.length)
    setChecked(newChecked);
  };

  const numberOfChecked = (items) => intersection(checked, items).length;

  const handleToggleAll = (items) => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items));
    } else {
      setChecked(union(checked, items));
    }
  };

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    console.log("hcl "+leftChecked);
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  const handleChangeuser=(event) => {
    Usuario=parseInt(event.target.value);
    var destUser=[];
    mUsuarios.map(dt=>{
      if(dt.personalId==Usuario){
        setUserOrigen(dt);
      }else{
        destUser.push(dt);
      }
    })
    setmUsuariosdes(destUser);
    GetTareas();
  }
  const handleChangeuserdestino=(event) => {
    //Usuario=parseInt(event.target.value);
    
  }
  

  
  const GetUser=async()=>{
    try {
        var data={};
        const resp = await axios.get(obteneruserroles,configjson);
        var result = resp.data;
        if(result.codigoRespuesta==0){
          var usuarios=result.valorRespuesta.listaPersonalTodos;
          setmUsuarios(usuarios);
        }else{
            
        }
        return data;
      } catch (err) {
          console.error(err);
      }
}
  const GetTareas=async()=>{
        try {
            var data={};
            var urldm=obtenerTareas+'PersonalId='+Usuario;
            const resp = await axios.get(urldm,configjson);
            var result = resp.data;
            if(result.codigoRespuesta==0){
              var rows=result.valorRespuesta.listaTareasPendientesSolCompra;
              var rowspago=result.valorRespuesta.listaTareasPendientesSolPago;
              
              setLeft(rows)
              setLeftpago(rowspago)
            }else{
                
            }
            return data;
          } catch (err) {
              console.error(err);
          }
    }

    const customList = (title, items) => (
      <Card>
        <CardHeader
          className={classes.cardHeader}
          avatar={
            <Checkbox
              onClick={handleToggleAll(items)}
              checked={numberOfChecked(items) === items.length && items.length !== 0}
              indeterminate={numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0}
              disabled={items.length === 0}
              inputProps={{ 'aria-label': 'all items selected' }}
            />
          }
          title={title}
          subheader={`${numberOfChecked(items)}/${items.length} selected`}
        />
        <Divider />
        <List className={classes.list} dense component="div" role="list">
          {items.map( dt=> {
            const labelId = `transfer-list-all-item-${dt.procesoCodigo}-label`;
  
            return (
              <ListItem key={dt.tareaId} role="listitem" button onClick={handleToggle(dt.tareaId)}>
                <ListItemIcon>
                  <Checkbox
                    checked={checked.indexOf(dt.tareaId) !== -1}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ 'aria-labelledby': labelId }}
                  />
                </ListItemIcon>
                <ListItemText id={dt.tareaId} primary={dt.procesoCodigo} />
              </ListItem>
            );
          })}
          <ListItem />
        </List>
      </Card>
    );
    const customListPago = (title, items) => (
      <Card>
        <CardHeader
          className={classes.cardHeader}
          avatar={
            <Checkbox
              onClick={handleToggleAll(items)}
              checked={numberOfChecked(items) === items.length && items.length !== 0}
              indeterminate={numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0}
              disabled={items.length === 0}
              inputProps={{ 'aria-label': 'all items selected' }}
            />
          }
          title={title}
          subheader={`${numberOfChecked(items)}/${items.length} selected`}
        />
        <Divider />
        <List className={classes.list} dense component="div" role="list">
          {items.map( dt=> {
            const labelId = `transfer-list-all-item-${dt.procesoCodigo}-label`;
  
            return (
              <ListItem key={dt.tareaId} role="listitem" button onClick={handleToggle(dt.tareaId)}>
                <ListItemIcon>
                  <Checkbox
                    checked={checked.indexOf(dt.tareaId) !== -1}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ 'aria-labelledby': labelId }}
                  />
                </ListItemIcon>
                <ListItemText id={dt.tareaId} primary={dt.procesoCodigo} />
              </ListItem>
            );
          })}
          <ListItem />
        </List>
      </Card>
    );
  return (
    <>
      
      <Grid container spacing={3}>
       
      <Grid item lg={12} md={12} sm={12} xs={12}>
          <Widget
            title="Reasignacion de procesos"
            upperTitle
            bodyClass={classes.fullHeightBody}
            className={classes.card}
            disableWidgetMenu={true}
          >
             <Grid container spacing={6}>
              <Grid item xs={6} style={{paddingRight: 25}}>
                <FormControl className={classes.formControl} fullWidth>
                    <InputLabel htmlFor="age-native-simple">Seleccione Usuario</InputLabel>
                    <Select
                    native
                    onChange={handleChangeuser}
                    >
                    <option value=""></option>
                    {mUsuarios.map(rol => (
                        <option
                        key={rol.personalId}
                        value={rol.personalId}
                        >
                        {rol.personalNombre}
                        </option>
                    ))}
                    </Select>
                </FormControl>
              </Grid> 
              <Grid item xs={6} style={{paddingRight: 25}}>
                <FormControl className={classes.formControl} fullWidth>
                    <InputLabel htmlFor="age-native-simple">Seleccione Usuario Reasignado</InputLabel>
                    <Select
                    native
                    onChange={handleChangeuserdestino}
                    >
                    <option value=""></option>
                    {mUsuariosdes.map(rol => (
                        <option
                        key={rol.personalId}
                        value={rol.personalId}
                        >
                        {rol.personalNombre}
                        </option>
                    ))}
                    </Select>
                </FormControl>
              </Grid> 
            </Grid>
          </Widget>
        </Grid>
       
        
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Widget
            title="Tareas Pendientes Compras"
            upperTitle
            bodyClass={classes.fullHeightBody}
            className={classes.card}
            disableWidgetMenu={true}
          >
           {UserOrigen && <Grid container spacing={2} justify="center" alignItems="center" className={classes.root}>
              <Grid item>{customList('Tareas de '+UserOrigen.personalNombre, left)}</Grid>
                <Grid item>
                  <Grid container direction="column" alignItems="center">
                    <Button
                      variant="outlined"
                      size="small"
                      className={classes.button}
                      onClick={handleCheckedRight}
                      disabled={leftChecked.length === 0}
                      aria-label="move selected right"
                    >
                      &gt;
                    </Button>
                    <Button
                      variant="outlined"
                      size="small"
                      className={classes.button}
                      onClick={handleCheckedLeft}
                      disabled={rightChecked.length === 0}
                      aria-label="move selected left"
                    >
                      &lt;
                    </Button>
                  </Grid>
                </Grid>
                <Grid item>{customList('Tareas', right)}</Grid>
              </Grid>}
                
          </Widget>
        </Grid>

        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Widget
            title="Tareas Pendientes Pagos"
            upperTitle
            bodyClass={classes.fullHeightBody}
            className={classes.card}
            disableWidgetMenu={true}
          >
            {UserOrigen && <Grid container spacing={2} justify="center" alignItems="center" className={classes.root}>
              <Grid item>{customListPago('Tareas de '+UserOrigen.personalNombre, leftpago)}</Grid>
                <Grid item>
                  <Grid container direction="column" alignItems="center">
                    <Button
                      variant="outlined"
                      size="small"
                      className={classes.button}
                      onClick={handleCheckedRight}
                      disabled={leftChecked.length === 0}
                      aria-label="move selected right"
                    >
                      &gt;
                    </Button>
                    <Button
                      variant="outlined"
                      size="small"
                      className={classes.button}
                      onClick={handleCheckedLeft}
                      disabled={rightChecked.length === 0}
                      aria-label="move selected left"
                    >
                      &lt;
                    </Button>
                  </Grid>
                </Grid>
                <Grid item>{customList('Tareas', right)}</Grid>
              </Grid>}
                
          </Widget>
                
        </Grid>
        
      </Grid>




    </>
  );
}

// #######################################################################
