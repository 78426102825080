import React, { useMemo } from "react";

import DataTable from "react-data-table-component";
import FilterComponent from "./FilterComponent";

const MiTableArea = props => {
  const columns = [
    {
      name: "#",
      selector: "id",
      width:"90px",
    },
    {
      name: "Area",
      selector: "area",
      sortable: true,
      hide: "sm"
    },
    {
      name: "Manager",
      selector: "manager",
      sortable: true,
      hide: "md"
    },
    {
      name: "Description",
      selector: "description",
      sortable: true,
      hide: "md"
    },
    {
      name: "Centro de Costo",
      selector: "costCenter",
      sortable: true
    },
    
    {
        name: "Cargo",
        selector: "position",
        sortable: true,
        hide: "md"
    }
    
  ];

  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = React.useState(
    false
  );
  // const filteredItems = data.filter(
  //   item => item.name && item.name.includes(filterText)
  // );
  const filteredItems = props.data.filter(
    item =>
      JSON.stringify(item)
        .toLowerCase()
        .indexOf(filterText.toLowerCase()) !== -1
  );
  const onMeetingTitleClick = (event,data) => {
    props.onRowClicked(data);
  }
  const onRowClicked = (row, event) => { onMeetingTitleClick(event, row.id); };

  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent
        onFilter={e => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  return (
    <DataTable
      title="Seleccione Area"
      columns={columns}
      data={filteredItems}
      striped
      pagination
      subHeader
      subHeaderComponent={subHeaderComponent}
      onRowClicked={onRowClicked}
    />
  );
};

export default MiTableArea;
