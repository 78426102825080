import React, { Component } from "react";
import { Grid,TextField } from "@material-ui/core";

class BtcProveedor extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
            opened: false,
		};
		
	}
  
	
  
	render() {
		var { proveedor } = this.props;

		if (proveedor.id>0){
			return (
                <Grid container spacing={3}>
                  <Grid item item xs={12} md={6}>
                    <TextField
                      id="cnameprov"
                      margin="normal"
                      label="Nombre Proveedor"
                      value={proveedor.supplierName}
                      inputProps={{ readOnly: true }}
                      fullWidth
                    />
                      <TextField
                      id="cnit"
                      margin="normal"
                      label="NIT"
                      placeholder="NIt"
                      value={proveedor.taxIdentificationNumber}
                      inputProps={{ readOnly: true }}
                      type="text"
                      fullWidth
                    />
                    <TextField
                      id="cnamecontac"
                      inputProps={{ readOnly: true }}
                      margin="normal"
                      label="Nombre Contacto"
                      placeholder="Nombre Contacto"
                      value={proveedor.contactName}
                      type="text"
                      fullWidth
                    />
                    <TextField
                      id="ccondpag"
                      inputProps={{ readOnly: true }}
                      margin="normal"
                      label="Condición de Pago"
                      placeholder="Condición de Pago"
                      value={proveedor.paymentConditionInDays}
                      type="text"
                      fullWidth
                    />
                    <TextField
                      id="ctelfofi"
                      inputProps={{ readOnly: true }}
                      margin="normal"
                      label="Telf. Oficina"
                      placeholder="Telf. Oficina"
                      value={proveedor.officePhone}
                      type="text"
                      fullWidth
                    />
                    <TextField
                      id="cnroct"
                      inputProps={{ readOnly: true }}
                      margin="normal"
                      label="Nro de Cuenta"
                      placeholder="Nro Cuenta"
                      value={proveedor.nrodeCuenta}
                      type="text"
                      fullWidth
                    />
                    <TextField
                      id="cmndcta"
                      inputProps={{ readOnly: true }}
                      margin="normal"
                      label="Moneda de Cuenta"
                      placeholder="Moneda de Cuenta"
                      value={proveedor.monedaCuenta}
                      type="text"
                      fullWidth
                    />
                    <TextField
                      id="cpscta"
                      inputProps={{ readOnly: true }}
                      margin="normal"
                      label="Pais de Cuenta"
                      placeholder="Pais de Cuenta"
                      value={proveedor.paisProcedencia}
                      type="text"
                      fullWidth
                    />
                  </Grid>
                  <Grid item item xs={12} md={6}>
                    <TextField
                        id="cemail"
                        inputProps={{ readOnly: true }}
                        margin="normal"
                        label="Correo"
                        placeholder="Correo"
                        value={proveedor.contactMail}
                        type="text"
                        fullWidth
                      />
                      <TextField
                        id="ctelf"
                        inputProps={{ readOnly: true }}
                        margin="normal"
                        label="Telf. Celular"
                        placeholder="Telf. Celular"
                        value={proveedor.cellandWhasAppNumber}
                        type="text"
                        fullWidth
                      />
                      <TextField
                        id="caddres"
                        inputProps={{ readOnly: true }}
                        margin="normal"
                        label="Direccion"
                        placeholder="Direccion"
                        value={proveedor.address}
                        type="text"
                        fullWidth
                      />
                      <TextField
                        id="cbanco"
                        inputProps={{ readOnly: true }}
                        margin="normal"
                        label="Banco"
                        placeholder="Banco"
                        value={proveedor.banco}
                        type="text"
                        fullWidth
                      />
                      <TextField
                        id="ctcta"
                        inputProps={{ readOnly: true }}
                        margin="normal"
                        label="Tipo de Cuenta"
                        placeholder="Tipo de Cuenta"
                        value={proveedor.tipoCuenta}
                        type="text"
                        fullWidth
                      />
                      <TextField
                        id="ccat"
                        inputProps={{ readOnly: true }}
                        margin="normal"
                        label="Categoria"
                        placeholder="Categoria"
                        value={proveedor.categoría}
                        type="text"
                        fullWidth
                      />
                      <TextField
                        id="cstd"
                        inputProps={{ readOnly: true }}
                        margin="normal"
                        label="Estado"
                        placeholder="Estado"
                        value={proveedor.estado}
                        type="text"
                        fullWidth
                      />
                  </Grid>
              </Grid>
            )
		}else{
			return (
                <div>
                </div>
            )
		}

	}
}

export default BtcProveedor;