import React from "react";
import {
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  Chip
} from "@material-ui/core";
import useStyles from "../../styles";

const states = {
  inscrita: "inscrita",
  resagada: "resagada",
  rechazada: "rechazada",
};

export default function TableComponent({ data }) {
  const classes = useStyles();
  var keys = Object.keys(data[0]).map(i => i.toUpperCase());
  keys.shift(); // delete "id" key

  return (
    <Table className="mb-0">
      <TableHead>
        <TableRow>
          {keys.map(key => (
            <TableCell key={key}>{key}</TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {data.map(({ id, sigla, nivel, materia, horario, aula, doncente, estado }) => (
          <TableRow key={id}>
            <TableCell className="pl-3 fw-normal">{sigla}</TableCell>
            <TableCell>{nivel}</TableCell>
            <TableCell>{materia}</TableCell>
            <TableCell>{horario}</TableCell>
            <TableCell>{aula}</TableCell>
            <TableCell>{doncente}</TableCell>
            <TableCell>
              <Chip label={estado} classes={{root: classes[states[estado.toLowerCase()]]}}/>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}
