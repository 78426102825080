import React, { useState,useEffect } from "react";
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  Grid,
  LinearProgress,
  OutlinedInput,
  MenuItem,
  Button,
  TextField,
  IconButton
} from "@material-ui/core";
import { useTheme } from "@material-ui/styles";
import {
  ResponsiveContainer,
  ComposedChart,
  AreaChart,
  LineChart,
  Line,
  Area,
  PieChart,
  Pie,
  Cell,
  YAxis,
  XAxis,
} from "recharts";
import { useSnackbar } from 'notistack';
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import Icon from '@material-ui/core/Icon';
import {Edit,Delete,Add,AdbRounded} from '@material-ui/icons';


// styles
import {
  DataGrid,
  ColDef,
  ValueGetterParams,
  CellParams,
  GridApi
} from "@material-ui/data-grid";
// components
import axios from 'axios'
import mock from "./mock";
import Widget from "../../components/Widget/Widget";
import PageTitle from "../../components/PageTitle/PageTitle";
import { Typography } from "../../components/Wrappers/Wrappers";
import Dot from "../../components/Sidebar/components/Dot";
import BigStat from "./components/BigStat/BigStat";
import UserAvatar from "../../components/UserAvatar/UserAvatar";

import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Constantes from "../../context/Constates";
import useStyles from "./styles";
import {Modal,ModalBody,ModalFooter,ModalHeader} from "reactstrap"
import { useAlert } from "react-alert";



  /*rutas */
  var obteneruserroles=Constantes.URLSERVER+Constantes.APIGETROLESUSER
  var abmrol=Constantes.URLSERVER+Constantes.APIPOSTROL
  var getappuser=Constantes.URLSERVER+Constantes.APIGETROLAPPUSER
  var voidapprol=Constantes.URLSERVER+Constantes.APIPOSTROLAPP
  var getUserlog=Constantes.URLSERVER+Constantes.APIGETLOSECUSER
  const museStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      '& > *': {
        margin: theme.spacing(1),
      },
      '& .super-app-theme--cell': {
        backgroundColor: 'rgba(224, 183, 60, 0.55)',
        color: '#1a3e72',
        fontWeight: '600',
      },
      '& .super-app.negative': {
        backgroundColor: '#d90007',
        color: '#FFFFFF',
        fontWeight: '600',
      },
      '& .super-app.positive': {
        backgroundColor: '#d47483',
        color: '#1a3e72',
        fontWeight: '600',
      },
    },
    small: {
      width: theme.spacing(3),
      height: theme.spacing(3),
    },
    large: {
      width: theme.spacing(15),
      height: theme.spacing(15),
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#008000',
    },
   
  }));
  
export default function LogsecuserPage(props) {
  const classes = useStyles();

  let history = useHistory();
  var theme = useTheme();
  const mclasses = museStyles();
  const alert = useAlert();
  var dataRecibido={};
  var configjson = {
    headers: {'Content-Type': 'application/json' , 'Authorization': 'Bearer '+localStorage.getItem('id_token')}
  };
  const location = useLocation();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  // local
  var Usuario=JSON.parse(localStorage.getItem('user'));
  const [mRoles, setmRoles] = React.useState([]);
  const [mUsers, setmUsers] = React.useState([]);
  const [mAplicacion, setmAplicacion] = React.useState([]);
  const [mMensajes, setmMensajes] = React.useState([]);
  const [mtipologct, setmtipologct] = React.useState([]);
  const [mtipolog, setmtipolog] = React.useState([]);
  const [loading,setloading]=React.useState(false);

  const [mUsuario, setmUsuario] = React.useState([]);
  const [mAppSel, setmAppSel] = React.useState({});
  const [mUserSel, setmUserSel] = React.useState({});
  const [mAplicaciones, setmAplicaciones] = React.useState([]);
  
  const [Userl,setUserl]=React.useState({});
  const [modalins, setmodalins] = React.useState(false);
  const [modalupd, setmodalupd] = React.useState(false);
  const [modalapp, setmodalapp] = React.useState(false);
  const [fechaini, setfechaini] = React.useState("01/01/1999");
  const [fechafin, setfechafin] = React.useState("01/01/1999");
  const [tipo, settipo] = React.useState("Todos");
  

  var columnsapp=[
    { field: 'aplicacionId', headerName: '#', width: 59 },
    { field: 'aplicacionNombre', headerName: 'Nombre Aplicaciones', resizable: true,width: 180  },
  ];
  var columnsmensaje=[
    { field: 'mensaje', headerName: 'Mensaje', width: 300 },
    { field: 'fechaHoraAsignadoStr', headerName: 'Fecha Asignado', resizable: true,width: 150  },
    { field: 'fechaHoraVistoStr', headerName: 'Fecha Visto', resizable: true,width: 150  },
  ];
  var columnstipologl=[
    { field: 'tipoLog', headerName: 'Tipo Log', width: 180 },
    { field: 'cantidad', headerName: 'Cantidad', resizable: true,width: 150  },
  ];
  var columnstipologr=[
    { field: 'tipoLog', headerName: 'Tipo Log', width: 180 },
    { field: 'fechaLogStr', headerName: 'Fecha', resizable: true,width: 180  },
  ];
  var columnsuser=[
    { field: 'personalId', headerName: '#', width: 120 },
    { field: 'personalNombre', headerName: 'Nombre Personal', resizable: true,width: 200  },
  ];

  var [mainChartState, setMainChartState] = useState("monthly");

  /*Peticiones*/ 
  useEffect(()=>{
    GetRoleandUser();
  },[location])

  /*alert mensajes */

 
 
  const GetRoleandUser=async()=>{
        try {
            var data={};
            setloading(true);
            const resp = await axios.get(obteneruserroles,configjson);
            var result = resp.data;
            if(result.codigoRespuesta==0){
              var dataroles=result.valorRespuesta.listaRoles;
              var datausuarios=result.valorRespuesta.listaPersonalTodos;
              var dataaplicaciones=result.valorRespuesta.listaAplicacionTodos;
              setmRoles(dataroles);
              setmUsers(datausuarios);
              setmAplicaciones(dataaplicaciones);
            }else{
              alert.show(result.mensajeRespuesta);
            }
            setloading(false);
            return data;
          } catch (err) {
            setloading(false);
              console.error(err);
          }
    }

    
    
    const getAppUser=async(id)=>{
      try {
        var data={};
        console.log(Userl.personalId);
        var dataparameter={};
        if(id>0){
          dataparameter.PersonalId = parseInt(id);
        }else if(Userl && Userl.personalId>0){
          dataparameter.PersonalId = parseInt(Userl.personalId);
        }else{
          dataparameter.PersonalId =0;
        }
        dataparameter.FechaIni  = fechaini;
        dataparameter.FechaFin  = fechafin;
        dataparameter.Tipo   = tipo;
        const json = JSON.stringify(dataparameter);
        const resp = await axios.post(getUserlog,json,configjson);
        var result = resp.data;
        if(result.codigoRespuesta==0){
          var dataApp=result.valorRespuesta.listaAplicacion;
          var datamensajes=result.valorRespuesta.listaPersonalMensajeApps;
          var datatipologct=result.valorRespuesta.listaLogCantidad;
          var datatipolog=result.valorRespuesta.listaLog;
          var ct=0;
          dataApp.map(dt=>{
            ct++;
            dt.id=ct;
          })
          ct=0;
          datamensajes.map(dt=>{
            ct++;
            dt.id=ct;
          })
          ct=0;
          datatipologct.map(dt=>{
            ct++;
            dt.id=ct;
          })
          ct=0;
          datatipolog.map(dt=>{
            ct++;
            dt.id=ct;
          })
          setmAplicacion(dataApp);
          setmMensajes(datamensajes);
          setmtipologct(datatipologct);
          setmtipolog(datatipolog);
        }else{
          alert.show(result.mensajeRespuesta);
        }
        setloading(false);
        return data;
      } catch (err) {
        setloading(false);
          console.error(err);
      }
    }
    

    const handleChangeusers = (event) => {
      console.log(event.target.value);
      if(mUsers.length>0){
        mUsers.map(usr => {
          if(usr.personalId==event.target.value){
            setloading(true);
            setUserl(usr)
            getAppUser(usr.personalId);
          }
        })
      }
    };
    
    const handleChangeapp=(event)=>{
      mAplicaciones.map(dt=>{
        if(dt.aplicacionId==event.target.value){
          setmAppSel(dt)
        }
      })
    }
    const handleChangeuser=(event)=>{
      mUsers.map(dt=>{
        if(dt.personalId==event.target.value){
          setmUserSel(dt)
        }
      })
    }
    
    const accionesModal=()=>{
      setmodalins(!modalins)
    }
    const accionesModalmod=()=>{
      setmodalupd(!modalupd)
    }
    const accionesModalapp=()=>{
      setmodalapp(!modalapp)
    }
    function format(inputDate) {

      var year = inputDate.substring(0,4);
      var month = inputDate.substring(5,7);
      var day = inputDate.substring(8,10);
      var someFormattedDate = day + '/' + month + '/' + year;
      return someFormattedDate;
     
  }
  const handleChangefi = (event) => {
    setfechaini(format(event.target.value));
   };

   const handleChangeff = (event) => {
    setfechafin(format(event.target.value));
   };
   const handleChangetipo = (event) => {
    settipo(event.target.value);
   };


  return (
    <>
      <Grid container spacing={3}>
       <Grid item lg={12} md={12} sm={12} xs={12}>
          <Widget
            title="Logs Seguridad de usuarios"
            upperTitle
            bodyClass={classes.fullHeightBody}
            className={classes.card}
            disableWidgetMenu={true}
          >
             <Grid container spacing={6}>
              <Grid item xs={12} lg={6} md={6} style={{paddingRight: 25}}>
                <FormControl className={classes.formControl} fullWidth>
                    <InputLabel htmlFor="age-native-simple">Seleccione Usuario</InputLabel>
                    <Select
                    native
                    id="Rol"
                    name="Rol"
                    onChange={handleChangeusers}
                    >
                    <option value=""></option>
                    {mUsers.map(usr => (
                        <option
                        key={usr.personalId}
                        value={usr.personalId}
                        >
                        {usr.personalNombre}
                        </option>
                    ))}
                    </Select>
                </FormControl>
              </Grid> 
              
            </Grid>
          </Widget>
        </Grid>

        
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Widget
            title="Aplicaciones que puede Acceder"
            upperTitle
            bodyClass={classes.fullHeightBody}
            className={classes.card}
            disableWidgetMenu={true}
          >
            <Grid container spacing={6}>
              <Grid item xs={12} style={{paddingRight: 25}}>
              <Button
                variant="contained"
                size="small"
                color="primary"
                className={classes.button}
                style={{ marginLeft: 16 }}
                onClick={()=>accionesModalapp()}
              >
                Adicionar Aplicacion
              </Button>
              
              </Grid> 
              <Grid  item lg={4} md={4} xs={12} style={{paddingRight: 0}}>
                <div style={{ height: 750 , padding:0}}>
                  <DataGrid 
                    rows={mAplicacion} 
                    columns={columnsapp}  
                    pageSize={20}
                    onRowSelected={(newSelection) => {
                      var sol=newSelection.data;
                      setmAppSel(sol);
                      }}
                  />
                </div> 
              </Grid>
              <Grid  item lg={8} md={8} xs={12}  style={{paddingRight: 25}}>
                <div style={{ height: 750 , padding:0}}>
                  <DataGrid 
                    rows={mMensajes} 
                    columns={columnsmensaje}  
                    pageSize={20}
                  />
                </div> 
              </Grid>
            </Grid>
          </Widget>
        </Grid>
       <Grid item lg={12} md={12} sm={12} xs={12}>
          <Widget
            title="Registro de Seguridad"
            upperTitle
            bodyClass={classes.fullHeightBody}
            className={classes.card}
            disableWidgetMenu={true}
          >
             <Grid container spacing={6}>
             <Grid item xs={3} style={{paddingRight: 25}}>
             <TextField
                  onChange={handleChangefi}
                  label="Fecha Inicio"
                  margin="normal"
                  required
                  type="date"
                  format={'dd/mm/YYYY'}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  fullWidth
                />
            </Grid> 
              <Grid item xs={3} >
              <TextField
                  onChange={handleChangeff}
                  label="Fecha Fin"
                  margin="normal"
                  required
                  type="date"
                  format={'dd/mm/YYYY'}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={3} >
              <FormControl className={classes.formControl} fullWidth>
                    <InputLabel htmlFor="age-native-simple">Seleccione Tipo</InputLabel>
                    <Select
                    native
                    onChange={handleChangetipo}
                    >
                    <option value=""></option>
                    <option value="Usuario Modificado">Usuario Modificado</option>
                    <option value="Usuario Inactivado">Usuario Inactivado</option>
                    <option value="Usuario Creado">Usuario Creado</option>
                    <option value="Cambio de Clave">Cambio de Clave</option>
                    <option value="Usuario ReActivado">Usuario ReActivado</option>
                    <option value="Reseteo de Clave">Reseteo de Clave</option>
                    <option value="Ingreso Satisfactorio">Ingreso Satisfactorio</option>
                    
                    </Select>
                </FormControl>
              </Grid>
              <Grid item xs={3} >
                  <Button
                  variant="contained"
                  color="success"
                  size="small"
                  onClick={()=>getAppUser(0)}
                  style={{ marginLeft: 16 }}
                >
                  Buscar
                </Button>
              </Grid>
              <Grid item xs={6}>
              <div style={{ height: 750 , padding:0}}>
                <DataGrid 
                  rows={mtipologct} 
                  columns={columnstipologl}  
                  pageSize={20}
                 
                />
              </div>
              </Grid>
              <Grid item xs={6}>
              <div style={{ height: 750 , padding:0}}>
                <DataGrid 
                  rows={mtipolog} 
                  columns={columnstipologr}  
                  pageSize={20}
                  
                />
              </div>
              </Grid>
            </Grid>
          </Widget>
        </Grid> 
            
      </Grid>



      

      <Modal
        isOpen={modalapp}
        paddingTop={30}
        centered
        zIndex={9000}
        size={'lg'}
      >
        <ModalHeader>
            Seleccione Aplicación
        </ModalHeader>
        <ModalBody>
        <Grid container spacing={6}>
            <Grid item xs={12} md={12}>
            <FormControl className={classes.formControl} fullWidth>
                    <InputLabel htmlFor="age-native-simple">Aplicacion</InputLabel>
                    <Select
                    native
                    onChange={handleChangeapp}
                    >
                      <option value="">Seleccione Aplicacion</option>
                    {mAplicaciones.map(app => (
                        <option
                        key={app.aplicacionId}
                        value={app.aplicacionId}
                        >
                        {app.aplicacionNombre}
                        </option>
                    ))}
                    </Select>
                </FormControl>
                
            </Grid>
        </Grid>
        </ModalBody>
        <ModalFooter>
        <Grid >
        
            <Button
              variant="contained"
              color="success"
              size="small"
              onClick={()=>accionesModalapp()}
              style={{ marginLeft: 16 }}
            >
              Cerrar
            </Button>
            <Button
              variant="contained"
              color="primary"
              size="small"
              style={{ marginLeft: 16 }}
            >
              Adicionar
            </Button>
        </Grid>
        </ModalFooter>
      </Modal>


      <Backdrop className={mclasses.backdrop} open={loading} >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}

// #######################################################################
