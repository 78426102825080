import React, { useState, useEffect } from "react";
import { Drawer, IconButton, List } from "@material-ui/core";
import {
  Home as HomeIcon,
  NotificationsNone as NotificationsIcon,
  ViewStream as ViewStreamIcon,
  FilterNone as UIElementsIcon,
  BorderAll as TableIcon,
  QuestionAnswer as SupportIcon,
  LibraryBooks as LibraryIcon,
  HelpOutline as FAQIcon,
  ArrowBack as ArrowBackIcon,
} from "@material-ui/icons";
import { useTheme } from "@material-ui/styles";
import { withRouter } from "react-router-dom";
import classNames from "classnames";
import axios from 'axios';
import Constantes from "../../context/Constates";

// styles
import useStyles from "./styles";

// components
import SidebarLink from "./components/SidebarLink/SidebarLink";
import Dot from "./components/Dot";
import Icon from '@material-ui/core/Icon'
// context
import {
  useLayoutState,
  useLayoutDispatch,
  toggleSidebar,
} from "../../context/LayoutContext";


const Menu={
  
};
const structure = [
  { id: 0, label: "Inicio", link: "/app/dashboard", icon: <HomeIcon /> },
  {
    id: 1,
    label: "Materias Inscritas",
    link: "/app/materias",
    icon: <ViewStreamIcon/>,
  },
  { id: 2, label: "Malla Curricular", link: "/app/tables", icon: <TableIcon /> },
  {
    id: 3,
    label: "Inscribir Materias",
    link: "/app/notifications",
    icon: <NotificationsIcon />,
  },
  {
    id: 4,
    label: "Pagos",
    link: "/app/ui",
    icon: <UIElementsIcon />,
    children: [
      { label: "Icons", link: "/app/ui/icons" },
      { label: "Charts", link: "/app/ui/charts" },
      { label: "Maps", link: "/app/ui/maps" },
    ],
  },
 
];

var UserMenu=[];



function Sidebar({ location }) {
  if(localStorage.getItem('menu')!=null){
    UserMenu=JSON.parse(localStorage.getItem('menu'));
  }else{
    UserMenu =[];
    var token=localStorage.getItem('id_token');
    //getMenuUser(token);
  }
 // console.log("usermenu "+UserMenu)
  var classes = useStyles();
  var theme = useTheme();

  // global
  var { isSidebarOpened } = useLayoutState();
  var layoutDispatch = useLayoutDispatch();

  // local
  var [isPermanent, setPermanent] = useState(true);

  useEffect(function() {
    window.addEventListener("resize", handleWindowWidthChange);
    handleWindowWidthChange();
    return function cleanup() {
      window.removeEventListener("resize", handleWindowWidthChange);
    };
  });

  return (
    <Drawer
      variant={isPermanent ? "permanent" : "temporary"}
      className={classNames(classes.drawer, {
        [classes.drawerOpen]: isSidebarOpened,
        [classes.drawerClose]: !isSidebarOpened,
      })}
      classes={{
        paper: classNames({
          [classes.drawerOpen]: isSidebarOpened,
          [classes.drawerClose]: !isSidebarOpened,
        }),
      }}
      open={isSidebarOpened}
    >
      <div className={classes.toolbar} />
      <div className={classes.mobileBackButton}>
        <IconButton onClick={() => toggleSidebar(layoutDispatch)}>
          <ArrowBackIcon
            classes={{
              root: classNames(classes.headerIcon, classes.headerIconCollapse),
            }}
          />
        </IconButton>
      </div>
      <List className={classes.sidebarList}>
        {UserMenu.map(link => (
          <SidebarLink
            key={link.grupoAplicacionId}
            location={location}
            link={link.aplicacionEnlaceJS}
            label={link.grupoAplicacionNombre}
            icon={link.grupoAplicacionIcono}   
            isSidebarOpened={isSidebarOpened}
            children={link.listaAplicaciones}
            {...link}
          />
        ))}
      </List>
    </Drawer>
  );

  // ##################################################################
  function handleWindowWidthChange() {
    var windowWidth = window.innerWidth;
    var breakpointWidth = theme.breakpoints.values.md;
    var isSmallScreen = windowWidth < breakpointWidth;

    if (isSmallScreen && isPermanent) {
      setPermanent(false);
    } else if (!isSmallScreen && !isPermanent) {
      setPermanent(true);
    }
  }

  
}
function getMenuUser (token)  {
  try {
    var Persona=JSON.parse(localStorage.getItem('user'));
    var fmenu=Constantes.URLSERVER+Constantes.APIGETMENU+"PersonalId="+Persona.personalId
    var config = {
      headers: {'Access-Control-Allow-Origin': '*' ,'Authorization': 'Bearer '+token}
    };
      axios.get(fmenu,config).then((repos) => {
        var result = repos.data;
        if(result.codigoRespuesta==0){
          var mn=result.valorRespuesta[0]
          var grupo=mn.listaGrupoAplicacion
          UserMenu=grupo;
          var mgrupo={};
          mgrupo.grupoPersonasId=mn.grupoPersonasId;
          mgrupo.grupoPersonasNombre=mn.grupoPersonasNombre;
          mgrupo.grupoPersonasUnipersonal=mn.grupoPersonasUnipersonal;
          mgrupo.areaId=mn.areaId;
          localStorage.setItem('menu', JSON.stringify(grupo));
          localStorage.setItem('grupo', JSON.stringify(mgrupo));
          //getDatalist(token);

        }else{
          
        }
      });
      
  } catch (err) {
      console.error(err);
  }
};
function getDatalist(token) {
  var menu=Constantes.URLSERVER+'api/servicio/getListaFormSolicitudCompra'
  var config = {
    headers: {'Access-Control-Allow-Origin': '*','Content-Type': 'application/json', 'Authorization': 'Bearer '+token}

};

  axios.get(menu,config)
  .then((repos) => {
    var result = repos.data;
    if(result.codigoRespuesta==0){
      var mn=result.valorRespuesta
      var listarea=mn.listaAreas
      var listctmark=mn.listaCuentasMarketing
      var listmarcas=mn.listaMarcas
      var listprov=mn.listaProveedores
      localStorage.setItem('ltareas', JSON.stringify(listarea));
      localStorage.setItem('ltctmarks', JSON.stringify(listctmark));
      localStorage.setItem('ltmarcas', JSON.stringify(listmarcas));
      localStorage.setItem('ltprov', JSON.stringify(listprov));
    }else{
    console.error('sesion expirada!');
      
    }
  })
  .catch(error => {
    console.error('There was an error!', error);
  });

  


}
export default withRouter(Sidebar);
