import React ,{useState, useEffect}from "react";
import { Grid,TextField,Paper,Button,IconButton } from "@material-ui/core";
import {Modal,ModalBody,ModalFooter,ModalHeader} from "reactstrap"
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import ReactExport from "react-export-excel";
// styles
import useStyles from "./styles";
import "bootstrap/dist/css/bootstrap.min.css";
// components
import PageTitle from "../../components/PageTitle/PageTitle";
import Widget from "../../components/Widget/Widget";
import { Typography } from "../../components/Wrappers/Wrappers";
import mock from "../dashboard/mock";
import classnames from "classnames";
import {
  DataGrid,
  ColDef,
  ValueGetterParams,
  CellParams,
  GridApi
} from "@material-ui/data-grid";
import Constantes from "../../context/Constates";
import axios from 'axios'
import {Edit,Delete,PanoramaFishEyeRounded} from '@material-ui/icons';

import { SettingsSystemDaydreamTwoTone } from "@material-ui/icons";


export default function LogsPage() {
    var classes = useStyles();
    const [mdata, setmdata] = React.useState([]);
    const [mcolumns, setmcolumns] = React.useState([]);
    const [mPersonas, setmPersonas] = React.useState([]);
    const [mPersona, setmPersona] = React.useState({});
    const [modalins, setmodalins] = React.useState(false);
    const [modaleditar, setmodaleditar] = React.useState(false);
    const [modalver, setmodalver] = React.useState(false);
    const [mareasel, setmareasel] = React.useState({});

    const ExcelFile = ReactExport.ExcelFile;
    const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
    const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
    
    var lstprovee=Constantes.URLSERVER+Constantes.APIGETLOGSLISTPROVELOG
    var lPersareas=Constantes.URLSERVER+Constantes.APIGETPERSAREA
    var lVoidareas=Constantes.URLSERVER+Constantes.APIPOSTAREA
    var lstArea=[];
    var config = {
        headers: {'Access-Control-Allow-Origin': '*' , 'Content-Type': 'application/json' , 'Authorization': 'Bearer '+localStorage.getItem('id_token')}
    };
    var configjson = {
        headers: {'Content-Type': 'application/json' , 'Authorization': 'Bearer '+localStorage.getItem('id_token')}
    };
    /*datagrid */
    
    /*Area */
    const[areaselc,setAreaselect]=useState({
        id: 0,
        area:'',
        costCenter:'',
        description:'',
        personalIdManager:0,
        manager:'',
        personalIdGM:0,
        generalManager:'',
        personalIDRegionalCFO:0,
        regionalCFO:'',
        personalIDRegionalPA:0,
        regionalPresidentAmericas:'',
        position:'',
        tipoEstado:0
    })
    /*funciones extras modal */
    const accionesModal=()=>{
        setmodalins(!modalins)
    }
    const accionesModalVer=()=>{
      setmodalver(!modalver)
    }
    const accionesModalEdit=()=>{
      setmodaleditar(!modaleditar)
  }
    /*handles*/
    const handleChange=e=>{
        const { name, value}=e.target;
        setAreaselect({
            ...areaselc,
            [name]:value
        })
        
    }
    /*Peticiones*/ 
    const GetPersonaAreas=async()=>{
        try {
            var data={};
            const resp = await axios.get(lPersareas,config);
            var result = resp.data;
            if(result.codigoRespuesta==0){
                var pers=result.valorRespuesta;
                setmPersonas(pers);
            }else{
            }
          } catch (err) {
              console.error(err);
          }
    }
    const GetAreas=async()=>{
        try {
            var data={};
            const resp = await axios.get(lstprovee,config);
            var result = resp.data;
            if(result.codigoRespuesta==0){
                var columns=[
                       { field: 'id', headerName: '#', width: 120 },
                       { field: 'proveedorNombre', headerName: 'Nombre Proveedor', resizable: true,width: 250  },
                       { field: 'actividad', headerName: 'Actividad', width: 180 },
                       { field: 'fechaRegistro', headerName: 'Fecha', width: 150 },
                       { field: 'cambios', headerName: 'Cambios', width: 230},
                       { field: 'personalNombre', headerName: 'Nombre Persona', width: 230},
                       
                     ];
                var rows=result.valorRespuesta;
                lstArea=rows;
                setmcolumns(columns);
                setmdata(rows);
                console.log(rows);
            }else{
                
            }
            return data;
          } catch (err) {
              console.error(err);
          }
    }
    const voidAreas=async()=>{
        try {
            var data={};
            areaselc.id=parseInt(areaselc.id);
            areaselc.personalIdManager=parseInt(areaselc.personalIdManager);
            areaselc.personalIdGM=parseInt(areaselc.personalIdGM);
            areaselc.personalIDRegionalCFO=parseInt(areaselc.personalIDRegionalCFO);
            areaselc.personalIDRegionalPA=parseInt(areaselc.personalIDRegionalPA);
            mPersonas.map(pers => {
              if(areaselc.personalIdManager==pers.personalId){
                  areaselc.manager=pers.personalNombre;
              }
              if(areaselc.personalIdGM==pers.personalId){
                areaselc.generalManager=pers.personalNombre;
              }
              if(areaselc.personalIDRegionalCFO==pers.personalId){
                areaselc.regionalCFO=pers.personalNombre;
              }
              if(areaselc.personalIDRegionalPA==pers.personalId){
                areaselc.regionalPresidentAmericas=pers.personalNombre;
              }
            });
            areaselc.tipoEstado=1;
            const json = JSON.stringify(areaselc);
            const resp = await axios.post(lVoidareas,json,configjson);
            var result = resp.data;
            if(result.codigoRespuesta==0){
               accionesModal();
               setmdata(mdata.concat(result.valorRespuesta));
               lstArea=mdata;
            }else{
                
            }
            return data;
          } catch (err) {
              console.error(err);
          }
    }
    /*en peticiones */
    
    useEffect(()=>{
        GetAreas();
        GetPersonaAreas();
    },[])
    function verArea(vardata){
      if (vardata && vardata.id>0) {
        lstArea.map(dt => {
          if(vardata.id==dt.id){
              setmareasel(dt);
              return;
          }
        });
        accionesModalVer();
      }
   }
   function editArea(vardata){
      if (vardata && vardata.id>0) {
        lstArea.map(dt => {
          if(vardata.id==dt.id){
              setmareasel(dt);
              return;
          }
        });
        accionesModalEdit();
      }
   }
   function deletArea(vardata){
      setmareasel(vardata);
   }
 
    
   

 
 
  return (
    <>
      <Grid container spacing={6}>
      <Grid item xs={12} md={16} style={{paddingBottom: 1}}>
        <Widget title="Registro de Cambios de Proveedores"  disableWidgetMenu   style={{paddingRight: 15}} headerClass={classes.widgetHeader}>
        
        
        </Widget>
        </Grid>
      <Grid item xs={12} md={16} style={{paddingBottom: 1}}>
          <Widget  disableWidgetMenu>
          <div style={{ height: 700 , padding:0}}>
            <DataGrid 
            rows={mdata} 
            columns={mcolumns}  
            pageSize={10}
            showToolbar 
            autoHeight
            density="compact"
            filterModel={{
              items: [
                { columnField: 'area', operatorValue: 'contains', value: '' },
              ],
            }}
             />
          </div>
              
          </Widget>
        </Grid>
       
     
        <Grid item xs={12} md={16} style={{paddingBottom: 1}}>
        <Widget noWidgetShadow disableWidgetMenu noBodyPadding noHeaderPadding style={{paddingRight: 15}} headerClass={classes.widgetHeader}>
        
        
        </Widget>
        </Grid>
      </Grid>

      <Modal
        isOpen={modalins}
        paddingTop={20}
        centered
      >
        <ModalHeader>
            Registrar Area
        </ModalHeader>
        <ModalBody>
        <Grid container spacing={6}>
            <Grid item xs={12} md={12}>
            <TextField
                  multiline
                  id="id"
                  name="id"
                  InputProps={{
                    classes: {
                      underline: classes.textFieldUnderline,
                      input: classes.textField,
                    },
                  }}
                  label="Id"
                  hidden
                  margin="normal"
                  placeholder="Id"
                  type="text"
                  fullWidth
                />
              <TextField
                  multiline
                  id="area"
                  name="area"
                  InputProps={{
                    classes: {
                      underline: classes.textFieldUnderline,
                      input: classes.textField,
                    },
                  }}
                  label="Area"
                  margin="normal"
                  required
                  placeholder="Area"
                  onChange={handleChange}
                  type="text"
                  fullWidth
                />
                <TextField
                  multiline
                  id="costCenter"
                  name="costCenter"
                  InputProps={{
                    classes: {
                      underline: classes.textFieldUnderline,
                      input: classes.textField,
                    },
                  }}
                  label="Cost Center"
                  margin="normal"
                  required
                  placeholder="Cost Center"
                  onChange={handleChange}
                  type="text"
                  fullWidth
                />
                <TextField
                  multiline
                  id="description"
                  name="description"
                  InputProps={{
                    classes: {
                      underline: classes.textFieldUnderline,
                      input: classes.textField,
                    },
                  }}
                  label="Descripcion"
                  margin="normal"
                  required
                  placeholder="Descripcion"
                  onChange={handleChange}
                  type="text"
                  fullWidth
                />
                <FormControl className={classes.formControl} fullWidth>
                    <InputLabel htmlFor="age-native-simple">Manager</InputLabel>
                    <Select
                    native
                    id="personalIdManager"
                    name="personalIdManager"
                    onChange={handleChange}
                    >
                        <option value="">Seleccione Personal</option>
                    {mPersonas.map(pers => (
                        <option
                        key={pers.personalId}
                        value={pers.personalId}
                        >
                        {pers.personalNombre}
                        </option>
                    ))}
                    </Select>
                </FormControl>
                <FormControl className={classes.formControl} fullWidth>
                    <InputLabel htmlFor="age-native-simple">General Manager</InputLabel>
                    <Select
                    native
                    id="personalIdGM"
                    name="personalIdGM"
                    onChange={handleChange}
                    >
                        <option value="">Seleccione Personal</option>
                    {mPersonas.map(pers => (
                        <option
                        key={pers.personalId}
                        value={pers.personalId}
                        >
                        {pers.personalNombre}
                        </option>
                    ))}
                    </Select>
                </FormControl>
                <FormControl className={classes.formControl} fullWidth>
                    <InputLabel htmlFor="age-native-simple">Regional CFO</InputLabel>
                    <Select
                    native
                    id="personalIDRegionalCFO"
                    name="personalIDRegionalCFO"
                    onChange={handleChange}
                    >
                        <option value="">Seleccione Personal</option>
                    {mPersonas.map(pers => (
                        <option
                        key={pers.personalId}
                        value={pers.personalId}
                        >
                        {pers.personalNombre}
                        </option>
                    ))}
                    </Select>
                </FormControl>
                <FormControl className={classes.formControl} fullWidth>
                    <InputLabel htmlFor="age-native-simple">Regional America</InputLabel>
                    <Select
                    native
                    id="personalIDRegionalPA"
                    name="personalIDRegionalPA"
                    onChange={handleChange}
                    >
                        <option value="">Seleccione Personal</option>
                    {mPersonas.map(pers => (
                        <option
                        key={pers.personalId}
                        value={pers.personalId}
                        >
                        {pers.personalNombre}
                        </option>
                    ))}
                    </Select>
                </FormControl>
            </Grid>
        </Grid>
        </ModalBody>
        <ModalFooter>
            <Button className="btn btn-danger" onClick={()=>accionesModal()} >Cancelar</Button>
            <Button className="btn btn-primary" onClick={()=>voidAreas()} > Guardar</Button>
        </ModalFooter>
      </Modal>
      

      <Modal
        isOpen={modaleditar}
        paddingTop={20}
        centered
      >
        <ModalHeader>
            Editar Area
        </ModalHeader>
        <ModalBody>
        <Grid container spacing={6}>
            <Grid item xs={12} md={12}>
            
                <div className="form-group">
                  <label>Id Area:</label>
                  <br/>
                  <input id="id" name="id" type="text" readOnly className="form-control" defaultValue={mareasel && mareasel.id}></input>
                  <br/>
                  <label>Area:</label>
                  <br/>
                  <input id="area" name="area" type="text" className="form-control" defaultValue={mareasel && mareasel.area}></input>
                  <br/>
                  <label>Cost Center:</label>
                  <br/>
                  <input id="costCenter" name="costCenter" type="text" className="form-control" defaultValue={mareasel && mareasel.costCenter}></input>
                  <br/>
                  <label>Descripcion:</label>
                  <br/>
                  <input id="description" name="description" type="text" className="form-control" defaultValue={mareasel && mareasel.description}></input>
                  <br/>
                  <label>Manager:</label>
                  <br/>
                  <select id="personalIdManager" name="personalIdManager"  className="form-control" value={mareasel && mareasel.personalIdManager}>
                    <option value="">Seleccione Personal</option>
                    {mPersonas.map(pers => (
                        <option
                        key={pers.personalId}
                        value={pers.personalId}
                        >
                        {pers.personalNombre}
                        </option>
                    ))}
                    </select>
                    <br/>
                    <label>General Manager:</label>
                    <br/>
                    <select id="personalIdGM" name="personalIdGM"  className="form-control" value={mareasel && mareasel.personalIdGM}>
                    <option value="">Seleccione Personal</option>
                    {mPersonas.map(pers => (
                        <option
                        key={pers.personalId}
                        value={pers.personalId}
                        >
                        {pers.personalNombre}
                        </option>
                    ))}
                    </select>
                    <br/>
                    <label>Regional CFO:</label>
                    <br/>
                    <select id="personalIDRegionalCFO" name="personalIDRegionalCFO"  className="form-control" value={mareasel && mareasel.personalIDRegionalCFO}>
                    <option value="">Seleccione Personal</option>
                    {mPersonas.map(pers => (
                        <option
                        key={pers.personalId}
                        value={pers.personalId}
                        >
                        {pers.personalNombre}
                        </option>
                    ))}
                    </select>
                    <br/>
                    <label>Regional America:</label>
                    <br/>
                    <select id="personalIDRegionalPA" name="personalIDRegionalPA"  className="form-control" value={mareasel && mareasel.personalIDRegionalPA}>
                    <option value="">Seleccione Personal</option>
                    {mPersonas.map(pers => (
                        <option
                        key={pers.personalId}
                        value={pers.personalId}
                        >
                        {pers.personalNombre}
                        </option>
                    ))}
                    </select>
                </div>
                
            </Grid>
        </Grid>
        </ModalBody>
        <ModalFooter>
            <Button className="btn btn-danger" onClick={()=>accionesModalEdit()} >Cancelar</Button>
            <Button className="btn btn-primary" onClick={()=>voidAreas()} > Guardar</Button>
        </ModalFooter>
      </Modal>
      
        <Modal
        isOpen={modalver}
        paddingTop={20}
        centered
        >
        <ModalHeader>
            Ver Area
        </ModalHeader>
        <ModalBody>
        <Grid container spacing={6}>
            <Grid item xs={12} md={12}>
            <TextField
                  multiline
                  id="id"
                  name="id"
                  InputProps={{
                    classes: {
                      underline: classes.textFieldUnderline,
                      input: classes.textField,
                    },
                  }}
                  label="Id"
                  value={mareasel && mareasel.id}
                  margin="normal"
                  type="text"
                  fullWidth
                />
              <TextField
                  multiline
                  id="area"
                  name="area"
                  InputProps={{
                    classes: {
                      underline: classes.textFieldUnderline,
                      input: classes.textField,
                    },
                  }}
                  label="Area"
                  margin="normal"
                  value={mareasel && mareasel.area}
                  type="text"
                  fullWidth
                />
                <TextField
                  multiline
                  id="costCenter"
                  name="costCenter"
                  InputProps={{
                    classes: {
                      underline: classes.textFieldUnderline,
                      input: classes.textField,
                    },
                  }}
                  label="Cost Center"
                  margin="normal"
                  value={mareasel && mareasel.costCenter}
                  type="text"
                  fullWidth
                />
                <TextField
                  multiline
                  id="description"
                  name="description"
                  InputProps={{
                    classes: {
                      underline: classes.textFieldUnderline,
                      input: classes.textField,
                    },
                  }}
                  label="Descripcion"
                  margin="normal"
                  value={mareasel && mareasel.description}
                  type="text"
                  fullWidth
                />
               <TextField
                  multiline
                  id="manager"
                  name="manager"
                  InputProps={{
                    classes: {
                      underline: classes.textFieldUnderline,
                      input: classes.textField,
                    },
                  }}
                  label="Manager"
                  margin="normal"
                  value={mareasel && mareasel.manager}
                  type="text"
                  fullWidth
                />
                <TextField
                  multiline
                  id="generalManager"
                  name="generalManager"
                  InputProps={{
                    classes: {
                      underline: classes.textFieldUnderline,
                      input: classes.textField,
                    },
                  }}
                  label="General Manager"
                  margin="normal"
                  value={mareasel && mareasel.generalManager}
                  type="text"
                  fullWidth
                />
               <TextField
                  multiline
                  id="regionalCFO"
                  name="regionalCFO"
                  InputProps={{
                    classes: {
                      underline: classes.textFieldUnderline,
                      input: classes.textField,
                    },
                  }}
                  label="Regional CFO"
                  margin="normal"
                  value={mareasel && mareasel.regionalCFO}
                  type="text"
                  fullWidth
                />
                <TextField
                  multiline
                  id="regionalPresidentAmericas"
                  name="regionalPresidentAmericas"
                  InputProps={{
                    classes: {
                      underline: classes.textFieldUnderline,
                      input: classes.textField,
                    },
                  }}
                  label="Regional America"
                  margin="normal"
                  value={mareasel && mareasel.regionalPresidentAmericas}
                  type="text"
                  fullWidth
                />
            </Grid>
        </Grid>
        </ModalBody>
        <ModalFooter>
            <Button className="btn btn-danger" onClick={()=>accionesModalVer()} >Cerrar</Button>
        </ModalFooter>
      </Modal>
      
    </>
  );
}
