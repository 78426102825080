import React ,{useState, useEffect}from "react";
import { Grid,TextField,Paper,Button,IconButton } from "@material-ui/core";
import {Modal,ModalBody,ModalFooter,ModalHeader} from "reactstrap"
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import ReactExport from "react-export-excel";
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import AppBar from '@material-ui/core/AppBar';

import { TabContent, TabPane, Nav, NavItem, NavLink, Card, CardTitle, CardText, Row, Col } from 'reactstrap';
// styles
import useStyles from "./styles";
import "bootstrap/dist/css/bootstrap.min.css";
// components
import PageTitle from "../../components/PageTitle/PageTitle";
import Widget from "../../components/Widget/Widget";
import mock from "../dashboard/mock";
import classnames from "classnames";
import {
  DataGrid,
  ColDef,
  ValueGetterParams,
  CellParams,
  GridApi
} from "@material-ui/data-grid";
import Constantes from "../../context/Constates";
import axios from 'axios'
import {Edit,Delete,PanoramaFishEyeRounded} from '@material-ui/icons';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Avatar from '@material-ui/core/Avatar';
import { SettingsSystemDaydreamTwoTone } from "@material-ui/icons";
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { useAlert } from "react-alert";

const StyledTableCell = withStyles((theme) => ({
  head: {
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
    },
  },
}))(TableRow);
const useStylestable = makeStyles({
  table: {
    minWidth: 700,
  },
});
const museStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
    '& .super-app-theme--cell': {
      backgroundColor: 'rgba(224, 183, 60, 0.55)',
      color: '#1a3e72',
      fontWeight: '600',
    },
    '& .super-app.negative': {
      backgroundColor: '#c70000',
      color: '#1a3e72',
      fontWeight: '600',
    },
    '& .super-app.positive': {
      backgroundColor: '#d47483',
      color: '#1a3e72',
      fontWeight: '600',
    },
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  large: {
    width: theme.spacing(15),
    height: theme.spacing(15),
  },
 
}));
export default function AbmPage() {
    var classes = useStyles();
    
    const mclasses = museStyles();
    const alert = useAlert();

    const [mdata, setmdata] = React.useState([]);
    const [mcolumns, setmcolumns] = React.useState([]);
    const [mPersonas, setmPersonas] = React.useState([]);
    const [mLogProv, setmLogProv] = React.useState([]);
    const [modalins, setmodalins] = React.useState(false);
    const [modaleditar, setmodaleditar] = React.useState(false);
    const [modalver, setmodalver] = React.useState(false);
    const [mprvsel, setmprvsel] = React.useState({});
    const [mImagenes, setmImagenes] = React.useState([]);

    const [age, setAge] = React.useState('');
    const [valueEstado, setvalueEstado] = React.useState('Activo');

    const ExcelFile = ReactExport.ExcelFile;
    const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
    const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

    const [pestaña, setpestaña] = React.useState(0);

    const handleChange = (event, newValue) => {
      setpestaña(newValue);
    };
    const [activeTab, setActiveTab] = useState('1');

    const toggle = tab => {
      if(activeTab !== tab) setActiveTab(tab);
    }
    const[image,setimage]=React.useState({
      id: 0,
      file: null,
      nombre:'',
      size:""
    })
    var lproveedores=Constantes.URLSERVER+Constantes.APIGETPROVEEDORES
    var lproveedoresFilter=Constantes.URLSERVER+Constantes.APIGETPROVEEDORESANIO
    var lVoidproveedore=Constantes.URLSERVER+Constantes.APIPOSTPROVEEDOR
    var logproveedor=Constantes.URLSERVER+Constantes.APIGETLOGSLISTPROVELOG

    var lstProveedor=[];
    var selProveedor={};
    var config = {
        headers: {'Access-Control-Allow-Origin': '*' , 'Authorization': 'Bearer '+localStorage.getItem('id_token')}
    };
    var configjson = {
        headers: {'Content-Type': 'application/json' , 'Authorization': 'Bearer '+localStorage.getItem('id_token')}
    };

    var year = (new Date()).getFullYear();
    var years = Array.from(new Array(20),(val, index) => index + year);
    /*datagrid */
    
    /*proveedor */
    const[prvselc,setprvselect]=useState({
      address: '',
      banco: '',
      categoría: '',
      cellandWhasAppNumber: '',
      contactMail: '',
      contactName: '',
      estado: '',
      id: 0,
      monedaCuenta: '',
      nrodeCuenta: '',
      officePhone: '',
      paisProcedencia: '',
      paymentConditionInDays: 0,
      supplierName: '',
      taxIdentificationNumber: '',
      tipoCuenta: '',
      tipoPago:1,
      codigoSap: '',
      tipoEstado: 0
    })
    /*funciones extras modal */
    const accionesModal=()=>{
        setvalueEstado("Activo");
        setmodalins(!modalins)
    }
    const accionesModalVer=()=>{
      setmodalver(!modalver)
    }
    const accionesModalEdit=()=>{
      setmodaleditar(!modaleditar)
    }
    const handleChangeEstado = (event) => {
      setvalueEstado(event.target.value);
      console.log(event.target.value)
    };
    
    /*handles*/
    const handleChangepestaña=e=>{
        const { name, value}=e.target;
        setprvselect({
            ...prvselc,
            [name]:value
        })
        
    }
    /*Peticiones*/ 
    
    const GetProveedores=async()=>{
    year = (new Date()).getFullYear();
    years = Array.from(new Array(20),(val, index) => year - index);
    console.log(years);
        try {
            var data={};
            const resp = await axios.get(lproveedores,config);
            var result = resp.data;
            if(result.codigoRespuesta==0){
                var columns=[

                       { field: '', headerName: 'Acciones', width: 200 ,
                       renderCell: (params: CellParams) => {
                        const onClickedit = () => {
                          const api: GridApi = params.api;
                          const fields = api
                            .getAllColumns()
                            .map((c) => c.field)
                            .filter((c) => c !== "__check__" && !!c);
                          const thisRow = {};
                  
                          fields.forEach((f) => {
                            thisRow[f] = params.getValue(f);
                          });
                  
                          return  editPrv(thisRow);
                        };
                        const onClickdel = () => {
                          const api: GridApi = params.api;
                          const fields = api
                            .getAllColumns()
                            .map((c) => c.field)
                            .filter((c) => c !== "__check__" && !!c);
                          const thisRow = {};
                  
                          fields.forEach((f) => {
                            thisRow[f] = params.getValue(f);
                          });
                  
                          return  deletPrv(thisRow);
                        };
                        const onClickver = () => {
                          const api: GridApi = params.api;
                          const fields = api
                            .getAllColumns()
                            .map((c) => c.field)
                            .filter((c) => c !== "__check__" && !!c);
                          const thisRow = {};
                  
                          fields.forEach((f) => {
                            thisRow[f] = params.getValue(f);
                          });
                  
                          return  verPrv(thisRow);
                        };
                        return <div>
                                <label htmlFor="icon-button-file">
                                <IconButton color="primary" onClick={onClickver} component="span">
                                <VisibilityIcon/>
                                </IconButton>
                                <IconButton color="primary" onClick={onClickedit}  component="span">
                                <Edit/>
                                </IconButton>
                                <IconButton color="primary" onClick={onClickdel} component="span">
                                <Delete/>
                                </IconButton>
                              </label>
                            </div>;
                      }},
                       { field: 'id', headerName: '#', width: 80 },
                       { field: 'supplierName', headerName: 'Nombre Proveedores', resizable: true,width: 180  },
                       { field: 'contactMail', headerName: 'Email', width: 180 },
                       { field: 'contactName', headerName: 'Nombre Contacto', width: 150 },
                       { field: 'address', headerName: 'Direccion', width: 180 },
                       { field: 'cellandWhasAppNumber', headerName: '# Celular', width: 180 },
                       { field: 'paisProcedencia', headerName: 'Pais', width: 190 },
                       { field: 'categoría', headerName: 'Categoría', width: 190 },
                       { field: 'estado', headerName: 'Estado', width: 190 },
                       { field: 'officePhone', headerName: 'Telefono', width: 190 },
                       { field: 'taxIdentificationNumber', headerName: 'NIT', width: 190 },
                       { field: 'paymentConditionInDays', headerName: 'Condicion de Pago', width: 190 },
                       { field: 'banco', headerName: 'Banco', width: 190 },
                       { field: 'monedaCuenta', headerName: 'Moneda', width: 190 },
                       { field: 'nrodeCuenta', headerName: '# Cuenta', width: 190 }
                       
                     ];
                var rows=result.valorRespuesta;
                lstProveedor=rows;
                setmcolumns(columns);
                setmdata(rows);
               console.log(rows);
            }else{
              alert.show(result.mensajeRespuesta);                
            }
            return data;
          } catch (err) {
              console.error(err);
          }
    }

    const GetLogProveedores=async()=>{
          try {
              var data={};
              var urlservice=logproveedor+'id='+selProveedor.id
              console.log('user '+selProveedor)
              const resp = await axios.get(urlservice,config);
              var result = resp.data;
              if(result.codigoRespuesta==0){
                  var rows=result.valorRespuesta;
                  setmLogProv(rows);
                  console.log('log '+rows);
              }else{
                alert.show(result.mensajeRespuesta);                  
              }
              return data;
            } catch (err) {
                console.error(err);
            }
      }
    const GetProveedoresfilter=async()=>{

          try {
              var data={};
              var filt=lproveedoresFilter+'?year='+age
              const resp = await axios.get(filt,config);
              var result = resp.data;
              if(result.codigoRespuesta==0){
                  var columns=[
  
                         { field: 'id', headerName: '#', width: 80 },
                         { field: 'supplierName', headerName: 'Nombre Proveedores', resizable: true,width: 180  },
                         { field: 'contactMail', headerName: 'Email', width: 180 },
                         { field: 'contactName', headerName: 'Nombre Contacto', width: 150 },
                         { field: 'address', headerName: 'Direccion', width: 180 },
                         { field: 'cellandWhasAppNumber', headerName: '# Celular', width: 180 },
                         { field: 'paisProcedencia', headerName: 'Pais', width: 190 },
                         { field: 'categoría', headerName: 'Categoría', width: 190 },
                         { field: 'estado', headerName: 'Estado', width: 190 },
                         { field: 'officePhone', headerName: 'Telefono', width: 190 },
                         { field: 'taxIdentificationNumber', headerName: 'NIT', width: 190 },
                         { field: 'paymentConditionInDays', headerName: 'Condicion de Pago', width: 190 },
                         { field: 'banco', headerName: 'Banco', width: 190 },
                         { field: 'monedaCuenta', headerName: 'Moneda', width: 190 },
                         { field: 'nrodeCuenta', headerName: '# Cuenta', width: 190 },
                         { field: '', headerName: 'Acciones', width: 200 ,
                         renderCell: (params: CellParams) => {
                          const onClickedit = () => {
                            const api: GridApi = params.api;
                            const fields = api
                              .getAllColumns()
                              .map((c) => c.field)
                              .filter((c) => c !== "__check__" && !!c);
                            const thisRow = {};
                    
                            fields.forEach((f) => {
                              thisRow[f] = params.getValue(f);
                            });
                    
                            return  editPrv(thisRow);
                          };
                          const onClickdel = () => {
                            const api: GridApi = params.api;
                            const fields = api
                              .getAllColumns()
                              .map((c) => c.field)
                              .filter((c) => c !== "__check__" && !!c);
                            const thisRow = {};
                    
                            fields.forEach((f) => {
                              thisRow[f] = params.getValue(f);
                            });
                    
                            return  deletPrv(thisRow);
                          };
                          const onClickver = () => {
                            const api: GridApi = params.api;
                            const fields = api
                              .getAllColumns()
                              .map((c) => c.field)
                              .filter((c) => c !== "__check__" && !!c);
                            const thisRow = {};
                    
                            fields.forEach((f) => {
                              thisRow[f] = params.getValue(f);
                            });
                    
                            return  verPrv(thisRow);
                          };
                          return <div>
                                  <label htmlFor="icon-button-file">
                                  <IconButton color="primary" onClick={onClickver} component="span">
                                  <PanoramaFishEyeRounded/>
                                  </IconButton>
                                  <IconButton color="primary" onClick={onClickedit}  component="span">
                                  <Edit/>
                                  </IconButton>
                                  <IconButton color="primary" onClick={onClickdel} component="span">
                                  <Delete/>
                                  </IconButton>
                                </label>
                              </div>;
                        }}
                       ];
                  var rows=result.valorRespuesta;
                  lstProveedor=rows;
                  setmcolumns(columns);
                  setmdata(rows);
                 console.log(rows);
              }else{
                alert.show(result.mensajeRespuesta);                  
              }
              return data;
            } catch (err) {
                console.error(err);
            }
      }

    const voidProveedor=async()=>{
        try {
            var data={};
            prvselc.id=parseInt(prvselc.id);
            prvselc.tipoPago=parseInt(prvselc.tipoPago);
           // prvselc.nrodeCuenta=prvselc.nrodeCuenta;
            prvselc.paymentConditionInDays=parseInt(prvselc.paymentConditionInDays);
            prvselc.estado=valueEstado;
            prvselc.tipoEstado=1;
            const json = JSON.stringify(prvselc);
            const resp = await axios.post(lVoidproveedore,json,configjson);
            var result = resp.data;
            if(result.codigoRespuesta==0){
               accionesModal();
               setmdata(mdata.concat(result.valorRespuesta));
               lstProveedor=mdata;
            }else{
              alert.show(result.mensajeRespuesta);                
            }
            return data;
          } catch (err) {
              console.error(err);
          }
    }
    
    const voideditProveedor=async()=>{
      try {
          var data={};
          prvselc.id=parseInt(prvselc.id);
          prvselc.tipoPago=parseInt(prvselc.tipoPago);
          prvselc.nrodeCuenta=prvselc.nroCuentaBancaria;
          prvselc.paymentConditionInDays=parseInt(prvselc.paymentConditionInDays);
          prvselc.tipoEstado=2;
          const json = JSON.stringify(prvselc);
          const resp = await axios.post(lVoidproveedore,json,configjson);
          var result = resp.data;
          if(result.codigoRespuesta==0){
            GetProveedores();
            accionesModalEdit();

          }else{
            alert.show(result.mensajeRespuesta);              
          }
          return data;
        } catch (err) {
            console.error(err);
        }
  }
    /*en peticiones */
    
    useEffect(()=>{
        GetProveedores();
        //GetPersonaAreas();
    },[])
    function verPrv(vardata){
      if (vardata && vardata.id>0) {
        lstProveedor.map(dt => {
          if(vardata.id==dt.id){
              selProveedor=dt;
              setmprvsel(dt);
              GetLogProveedores();
              return;
          }
        });
        accionesModalVer();
      }
   }
   function editPrv(vardata){
      if (vardata && vardata.id>0) {
        lstProveedor.map(dt => {
          if(vardata.id==dt.id){
              setmprvsel(dt);
              setprvselect(dt);
              
              return;
          }
        });
        accionesModalEdit();
      }
   }
   function deletPrv(vardata){
      setmprvsel(vardata);
   }
 
   const handleChanges = (event) => {
    console.log(`Option selected:`, event.target.value);
    setAge(event.target.value);
    GetProveedoresfilter();
   };
   const delimage= (data) =>{
    var index = mImagenes.indexOf(data);
    if (index > -1) { 
      mImagenes.splice(index, 1);
      setmImagenes(mImagenes);
    }
    console.log(mImagenes);
   }

   const handleChangeImage=e=>{
    //setmfile(URL.createObjectURL(event.target.files[0]))
    var cfile={};

    cfile.id=mImagenes.length+1;
   // cfile.files=e.dataTransfer.files[0]
   var mfile=e.target.files[0];
    cfile.files=mfile;
    cfile.file=URL.createObjectURL(e.target.files[0]);
    cfile.nombre=e.target.files[0].name;
    cfile.size=formatBytes(e.target.files[0].size);
    console.log(cfile);
    setimage(e.target)
    setmImagenes(mImagenes.concat(cfile));
  }

 /*funciones extras */
 function formatBytes(a,b=2){if(0===a)return"0 Bytes";const c=0>b?0:b,d=Math.floor(Math.log(a)/Math.log(1024));return parseFloat((a/Math.pow(1024,d)).toFixed(c))+" "+["Bytes","KB","MB","GB","TB","PB","EB","ZB","YB"][d]}
    
  return (
    <>
      <Grid container spacing={6}>
      <Grid item xs={12} md={16} style={{paddingBottom: 1}}>
        <Widget title="Administracion Proveedores"  disableWidgetMenu   style={{paddingRight: 15}} headerClass={classes.widgetHeader}>
        <Button
          variant="contained"
          size="small"
          color="primary"
          className={classes.button}
          style={{ marginLeft: 16 }}
          onClick={()=>accionesModal()}
        >
          Agregar
        </Button>
        
        </Widget>
        </Grid>
      <Grid item xs={12} md={16} style={{paddingBottom: 1}}>
          <Widget  disableWidgetMenu>
            <Grid container>
            <Grid item xs={6} md={6}>
            <ExcelFile element={<button >Exportar Excel</button>}>
              <ExcelSheet data={mdata} name="Proveedores">
                <ExcelColumn label="#" value="id"/>
                <ExcelColumn label="Nombre Proveedor" value="supplierName"/>
                <ExcelColumn label="Nombre Contacto" value="contactName"/>
                <ExcelColumn label="Email" value="contactMail"/>
                <ExcelColumn label="Direccion" value="address"/>
                <ExcelColumn label="Categoría" value="categoría"/>
                <ExcelColumn label="Celular" value="cellandWhasAppNumber"/>
                <ExcelColumn label="Telefono" value="officePhone"/>
                <ExcelColumn label="Pais" value="paisProcedencia"/>
                <ExcelColumn label="NIT" value="taxIdentificationNumber"/>
                <ExcelColumn label="Banco" value="banco"/>
                <ExcelColumn label="Moneda" value="monedaCuenta"/>
                <ExcelColumn label="Tipo Cuenta" value="tipoCuenta"/>
                <ExcelColumn label="# Cuenta" value="nrodeCuenta"/>
                <ExcelColumn label="Condicion de Pago" value="paymentConditionInDays"/>
                <ExcelColumn label="Estado" value="estado"/>
              </ExcelSheet>
                
            </ExcelFile> 
            </Grid>
            <Grid item xs={6} md={6}>
            <FormControl className={classes.formControl} fullWidth>
                    <InputLabel htmlFor="age-native-simple">Seleccione Año</InputLabel>
                    <Select
                    native
                    id="anio"
                    name="anio"
                    value={age}
                    onChange={handleChanges}
                    //onChange={()=>selectanio(this)}
                    >
                        <option value=""></option>
                        <option value="2021">2021</option>
                        <option value="2020">2020</option>
                        <option value="2019">2019</option>
                        <option value="2018">2018</option>
                        <option value="2017">2017</option>
                        <option value="2016">2016</option>
                    {years.map((year, index) => {
                      <option key={`year${index}`} value={year}>{year}</option>
                    })}
                    </Select>
                </FormControl>
            </Grid>
            </Grid>
            
           
            <br/>
          <div style={{ height: 700 , padding:0}}>
            <DataGrid 
            rows={mdata} 
            columns={mcolumns}  
            pageSize={10}
            showToolbar 
             />
          </div>
              
          </Widget>
        </Grid>
       
     
        <Grid item xs={12} md={16} style={{paddingBottom: 1}}>
        <Widget noWidgetShadow disableWidgetMenu noBodyPadding noHeaderPadding style={{paddingRight: 15}} headerClass={classes.widgetHeader}>
        
        
        </Widget>
        </Grid>
      </Grid>

      <Modal
        isOpen={modalins}
        paddingTop={20}
        centered
        zIndex={9000}
      >
        <ModalHeader>
            Registrar Proveedor
        </ModalHeader>
        <ModalBody>
        <Grid container spacing={6}>
            <Grid item xs={12} md={12}>
            <TextField
                  multiline
                  id="id"
                  name="id"
                  InputProps={{
                    classes: {
                      underline: classes.textFieldUnderline,
                      input: classes.textField,
                    },
                  }}
                  label="Id"
                  hidden
                  margin="normal"
                  placeholder="Id"
                  type="text"
                  fullWidth
                />
              <TextField
                  multiline
                  id="supplierName"
                  name="supplierName"
                  InputProps={{
                    classes: {
                      underline: classes.textFieldUnderline,
                      input: classes.textField,
                    },
                  }}
                  label="Nombre Proveedor"
                  margin="normal"
                  required
                  onChange={handleChangepestaña}
                  type="text"
                  fullWidth
                />
                <TextField
                  multiline
                  id="contactName"
                  name="contactName"
                  InputProps={{
                    classes: {
                      underline: classes.textFieldUnderline,
                      input: classes.textField,
                    },
                  }}
                  label="Nombre Contacto"
                  margin="normal"
                  required
                  onChange={handleChangepestaña}
                  type="text"
                  fullWidth
                />
                <TextField
                  multiline
                  id="contactMail"
                  name="contactMail"
                  InputProps={{
                    classes: {
                      underline: classes.textFieldUnderline,
                      input: classes.textField,
                    },
                  }}
                  label="Email"
                  margin="normal"
                  required
                  onChange={handleChangepestaña}
                  type="text"
                  fullWidth
                />
                <TextField
                  multiline
                  id="cellandWhasAppNumber"
                  name="cellandWhasAppNumber"
                  InputProps={{
                    classes: {
                      underline: classes.textFieldUnderline,
                      input: classes.textField,
                    },
                  }}
                  label="Telf. Celular"
                  margin="normal"
                  required
                  onChange={handleChangepestaña}
                  type="text"
                  fullWidth
                />
                <TextField
                  multiline
                  id="categoría"
                  name="categoría"
                  InputProps={{
                    classes: {
                      underline: classes.textFieldUnderline,
                      input: classes.textField,
                    },
                  }}
                  label="Categoría"
                  margin="normal"
                  required
                  onChange={handleChangepestaña}
                  type="text"
                  fullWidth
                />
                <TextField
                  multiline
                  id="banco"
                  name="banco"
                  InputProps={{
                    classes: {
                      underline: classes.textFieldUnderline,
                      input: classes.textField,
                    },
                  }}
                  label="Banco"
                  margin="normal"
                  required
                  onChange={handleChangepestaña}
                  type="text"
                  fullWidth
                />
                <TextField
                  multiline
                  id="address"
                  name="address"
                  InputProps={{
                    classes: {
                      underline: classes.textFieldUnderline,
                      input: classes.textField,
                    },
                  }}
                  label="Direccion Proveedor"
                  margin="normal"
                  required
                  onChange={handleChangepestaña}
                  type="text"
                  fullWidth
                />
                <TextField
                  multiline
                  id="monedaCuenta"
                  name="monedaCuenta"
                  InputProps={{
                    classes: {
                      underline: classes.textFieldUnderline,
                      input: classes.textField,
                    },
                  }}
                  label="Moneda"
                  margin="normal"
                  required
                  onChange={handleChangepestaña}
                  type="text"
                  fullWidth
                />
                <TextField
                  id="nrodeCuenta"
                  name="nrodeCuenta"
                  InputProps={{
                    classes: {
                      input: classes.textField,
                    },
                  }}
                  label="# Cuenta"
                  margin="normal"
                  required
                  onChange={handleChangepestaña}
                  type="number"
                  fullWidth
                />
                <TextField
                  id="officePhone"
                  name="officePhone"
                  InputProps={{
                    classes: {
                      input: classes.textField,
                    },
                  }}
                  label="Telf. Oficina"
                  margin="number"
                  required
                  onChange={handleChangepestaña}
                  type="text"
                  fullWidth
                />
                <TextField
                  multiline
                  id="paisProcedencia"
                  name="paisProcedencia"
                  InputProps={{
                    classes: {
                      underline: classes.textFieldUnderline,
                      input: classes.textField,
                    },
                  }}
                  label="Pais Procedencia"
                  margin="normal"
                  required
                  onChange={handleChangepestaña}
                  type="text"
                  fullWidth
                />
                <TextField
                  id="paymentConditionInDays"
                  name="paymentConditionInDays"
                  InputProps={{
                    classes: {
                      input: classes.textField,
                    },
                  }}
                  label="Condicion Pago"
                  margin="normal"
                  required
                  onChange={handleChangepestaña}
                  type="number"
                  fullWidth
                />
                <TextField
                  id="taxIdentificationNumber"
                  name="taxIdentificationNumber"
                  InputProps={{
                    classes: {
                      input: classes.textField,
                    },
                  }}
                  label="Nit"
                  margin="normal"
                  required
                  onChange={handleChangepestaña}
                  type="number"
                  fullWidth
                />
                <TextField
                  id="tipoCuenta"
                  name="tipoCuenta"
                  InputProps={{
                    classes: {
                      underline: classes.textFieldUnderline,
                      input: classes.textField,
                    },
                  }}
                  label="Tipo Cuenta"
                  margin="normal"
                  required
                  onChange={handleChangepestaña}
                  type="text"
                  fullWidth
                />
                <br/><br/>
                <FormControl className={classes.formControl} fullWidth>
                    <InputLabel htmlFor="age-native-simple">Tipo Pago</InputLabel>
                    <Select
                    native
                    id="tipoPago"
                    name="tipoPago"
                    onChange={handleChangepestaña}
                    >
                      <option value="0">Seleccione Tipo de Pago</option>
                      <option value="1">Transferencia</option>
                      <option value="2">Cheque</option>
                    
                    </Select>
                </FormControl>
                <TextField
                  multiline
                  id="codigoSap"
                  name="codigoSap"
                  InputProps={{
                    classes: {
                      underline: classes.textFieldUnderline,
                      input: classes.textField,
                    },
                  }}
                  label="Codigo SAP"
                  margin="normal"
                  required
                  onChange={handleChangepestaña}
                  type="text"
                  fullWidth
                />
                <br/><br/>
                <FormControl component="fieldset">
                  <FormLabel component="legend">Estado</FormLabel>
                  <RadioGroup aria-label="estado" name="estado" value={valueEstado} onChange={handleChangeEstado}>
                    <FormControlLabel value="Activo" control={<Radio />} label="Activo" />
                    <FormControlLabel value="Inactivo" control={<Radio />} label="Inactivo" />
                  </RadioGroup>
                </FormControl>

            </Grid>
        </Grid>
        </ModalBody>
        <ModalFooter>
            <Button className="btn btn-danger" onClick={()=>accionesModal()} >Cancelar</Button>
            <Button className="btn btn-primary" onClick={()=>voidProveedor()} > Guardar</Button>
        </ModalFooter>
      </Modal>
      

      <Modal
        isOpen={modaleditar}
        paddingTop={20}
        centered
        zIndex={9000}
      >
        <ModalHeader>
            Editar Proveedor
        </ModalHeader>
        <ModalBody>
        <Grid container spacing={6}>
            <Grid item xs={12} md={12}>
            
                <div className="form-group">
                  <label>Id Proveedor:</label>
                  <br/>
                  <input id="id" name="id" onChange={handleChangepestaña} type="text" readOnly className="form-control" defaultValue={mprvsel && mprvsel.id}></input>
                  <br/>
                  <label>Nombre Proveedor:</label>
                  <br/>
                  <input id="supplierName" name="supplierName" onChange={handleChangepestaña} type="text" className="form-control" defaultValue={mprvsel && mprvsel.supplierName}></input>
                  <br/>
                  <label>Nombre Contacto:</label>
                  <br/>
                  <input id="contactName" name="contactName" onChange={handleChangepestaña} type="text" className="form-control" defaultValue={mprvsel && mprvsel.contactName}></input>
                  <br/>
                  <label>Email:</label>
                  <br/>
                  <input id="contactMail" name="contactMail" onChange={handleChangepestaña} type="text" className="form-control" defaultValue={mprvsel && mprvsel.contactMail}></input>
                  <br/>
                  <label>telf. Celular:</label>
                  <br/>
                  <input id="cellandWhasAppNumber" name="cellandWhasAppNumber" onChange={handleChangepestaña} type="text" className="form-control" defaultValue={mprvsel && mprvsel.cellandWhasAppNumber}></input>
                  <br/>
                  <label>NIT:</label>
                  <br/>
                  <input id="taxIdentificationNumber" name="taxIdentificationNumber" onChange={handleChangepestaña} type="text" className="form-control" defaultValue={mprvsel && mprvsel.taxIdentificationNumber}></input>
                  <br/>
                  <label>Tipo Cuenta:</label>
                  <br/>
                  <input id="tipoCuenta" name="tipoCuenta" onChange={handleChangepestaña} type="text" className="form-control" defaultValue={mprvsel && mprvsel.tipoCuenta}></input>
                  <br/>
                  <label>Telf. Oficina:</label>
                  <br/>
                  <input id="officePhone" name="officePhone" onChange={handleChangepestaña} type="text" className="form-control" defaultValue={mprvsel && mprvsel.officePhone}></input>
                  <br/>
                  <label>Direccion:</label>
                  <br/>
                  <input id="address" name="address" onChange={handleChangepestaña} type="text" className="form-control" defaultValue={mprvsel && mprvsel.address}></input>
                  <br/>
                  <label>Categoría:</label>
                  <br/>
                  <input id="categoría" name="categoría" onChange={handleChangepestaña} type="text" className="form-control" defaultValue={mprvsel && mprvsel.categoría}></input>
                  <br/>
                  <label>Banco:</label>
                  <br/>
                  <input id="banco" name="banco" type="text" onChange={handleChangepestaña} className="form-control" defaultValue={mprvsel && mprvsel.banco}></input>
                  <br/>
                  <label>Pais Procedencia:</label>
                  <br/>
                  <input id="paisProcedencia" name="paisProcedencia" onChange={handleChangepestaña} type="text" className="form-control" defaultValue={mprvsel && mprvsel.paisProcedencia}></input>
                  <br/>
                  <label>Condicion de Pagos:</label>
                  <br/>
                  <input id="paymentConditionInDays" name="paymentConditionInDays" onChange={handleChangepestaña} type="number" className="form-control" defaultValue={mprvsel && mprvsel.paymentConditionInDays}></input>
                  <br/>
                  <label>Tipo de pago:</label>
                  <br/>
                  <select id="tipoPago" name="tipoPago" onChange={handleChangepestaña}  className="form-control" defaultValue={mprvsel && mprvsel.tipoPago}>
                    <option value="0" >Seleccione Personal</option>
                    <option value="1" >Transferencia</option>
                    <option value="2" >Cheque</option>
                    
                    </select>
                  <br/>
                  <label>Nro de Cuenta:</label>
                  <br/>
                  <input id="nroCuentaBancaria" name="nroCuentaBancaria" onChange={handleChangepestaña} type="number" className="form-control" defaultValue={mprvsel && mprvsel.nroCuentaBancaria}></input>
                  <br/>
                  <label>Moneda Cuenta:</label>
                  <br/>
                  <input id="monedaCuenta" name="monedaCuenta" onChange={handleChangepestaña} type="text" className="form-control" defaultValue={mprvsel && mprvsel.nroCuentaBancaria}></input>
                  <br/>
                  <label>Codigo Sap:</label>
                  <br/>
                  <input id="codigoSap" name="codigoSap" onChange={handleChangepestaña} type="text" className="form-control" defaultValue={mprvsel && mprvsel.codigoSap}></input>
                  <br/>
                  <br/>
                    <FormControl component="fieldset">
                    <FormLabel component="legend">Estado</FormLabel>
                    <RadioGroup aria-label="estado" name="estado" defaultValue={mprvsel && mprvsel.estado} onChange={handleChangeEstado}>
                      <FormControlLabel value="Activo" control={<Radio />} label="Activo" />
                      <FormControlLabel value="Inactivo" control={<Radio />} label="Inactivo" />
                    </RadioGroup>
                    </FormControl>
                </div>
                
            </Grid>
        </Grid>
        </ModalBody>
        <ModalFooter>
            <Button className="btn btn-danger" onClick={()=>accionesModalEdit()} >Cancelar</Button>
            <Button className="btn btn-primary" onClick={()=>voideditProveedor()} > Guardar</Button>
        </ModalFooter>
      </Modal>
      
      
      <Modal
        isOpen={modalver}
        paddingTop={30}
        centered
        zIndex={9000}
        size={'lg'}
      >
        <ModalHeader>
            Ver Proveedor
        </ModalHeader>
        <ModalBody>
        <div>
          <Nav tabs>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === '1' })}
                onClick={() => { toggle('1'); }}>
                Información
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === '2' })}
                onClick={() => { toggle('2'); }}>
                Actividades
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === '3' })}
                onClick={() => { toggle('3'); }}>
                Documentos
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={activeTab}>
            <TabPane tabId="1">
            <Card body>
              <Grid container spacing={6}>
            <Grid item xs={12} md={12}>
              <Grid container spacing={2}>
              <Grid item xs={6} md={6}>
                <TextField
                  multiline
                  id="id"
                  name="id"
                  InputProps={{
                    classes: {
                      underline: classes.textFieldUnderline,
                      input: classes.textField,
                    },
                  }}
                  label="Id"
                  value={mprvsel && mprvsel.id}
                  margin="normal"
                  type="text"
                  fullWidth
                />
                </Grid>
                <Grid item xs={6} md={6}>
                <TextField
                  multiline
                  id="estado"
                  name="estado"
                  InputProps={{
                    classes: {
                      underline: classes.textFieldUnderline,
                      input: classes.textField,
                    },
                  }}
                  label="Estado"
                  margin="normal"
                  value={mprvsel && mprvsel.estado}
                  type="text"
                  fullWidth
                />
                </Grid>
                
              </Grid>
            
              <TextField
                  multiline
                  id="supplierName"
                  name="supplierName"
                  InputProps={{
                    classes: {
                      underline: classes.textFieldUnderline,
                      input: classes.textField,
                    },
                  }}
                  label="Nombre Proveedor"
                  margin="normal"
                  value={mprvsel && mprvsel.supplierName}
                  type="text"
                  fullWidth
                />
                <TextField
                  multiline
                  id="contactName"
                  name="contactName"
                  InputProps={{
                    classes: {
                      underline: classes.textFieldUnderline,
                      input: classes.textField,
                    },
                  }}
                  label="Nombre Contacto"
                  margin="normal"
                  value={mprvsel && mprvsel.contactName}
                  type="text"
                  fullWidth
                />
                <TextField
                  multiline
                  id="contactMail"
                  name="contactMail"
                  InputProps={{
                    classes: {
                      underline: classes.textFieldUnderline,
                      input: classes.textField,
                    },
                  }}
                  label="Email"
                  margin="normal"
                  value={mprvsel && mprvsel.contactMail}
                  type="text"
                  fullWidth
                />
                <Grid container spacing={2}>
                  <Grid item xs={6} md={6} >
                  <TextField
                  multiline
                  id="cellandWhasAppNumber"
                  name="cellandWhasAppNumber"
                  InputProps={{
                    classes: {
                      underline: classes.textFieldUnderline,
                      input: classes.textField,
                    },
                  }}
                  label="Tefl. Celular"
                  margin="normal"
                  value={mprvsel && mprvsel.cellandWhasAppNumber}
                  type="text"
                  fullWidth
                />
                <TextField
                  multiline
                  id="officePhone"
                  name="officePhone"
                  InputProps={{
                    classes: {
                      underline: classes.textFieldUnderline,
                      input: classes.textField,
                    },
                  }}
                  label="Telf. Oficina"
                  margin="normal"
                  value={mprvsel && mprvsel.officePhone}
                  type="text"
                  fullWidth
                />
                  </Grid>
                  <Grid item  xs={6} md={6}>
                  <TextField
                  multiline
                  id="categoría"
                  name="categoría"
                  InputProps={{
                    classes: {
                      underline: classes.textFieldUnderline,
                      input: classes.textField,
                    },
                  }}
                  label="Categoría"
                  margin="normal"
                  value={mprvsel && mprvsel.categoría}
                  type="text"
                  fullWidth
                />
                 <TextField
                  multiline
                  id="address"
                  name="address"
                  InputProps={{
                    classes: {
                      underline: classes.textFieldUnderline,
                      input: classes.textField,
                    },
                  }}
                  label="Direccion"
                  margin="normal"
                  value={mprvsel && mprvsel.address}
                  type="text"
                  fullWidth
                />
                  </Grid>
                </Grid>
               
                <TextField
                  multiline
                  id="taxIdentificationNumber"
                  name="taxIdentificationNumber"
                  InputProps={{
                    classes: {
                      underline: classes.textFieldUnderline,
                      input: classes.textField,
                    },
                  }}
                  label="NIT"
                  margin="normal"
                  value={mprvsel && mprvsel.taxIdentificationNumber}
                  type="text"
                  fullWidth
                />
               
                
                <Grid container spacing={2} >
                  <Grid item xs={6} md={6} >
                  <TextField
                  id="paisProcedencia"
                  name="paisProcedencia"
                  fullWidth
                  InputProps={{
                    classes: {
                      underline: classes.textFieldUnderline,
                      input: classes.textField,
                    },
                  }}
                  label="Pais Procedencia"
                  margin="normal"
                  value={mprvsel && mprvsel.paisProcedencia}
                  type="text"
                />
                <TextField
                  multiline
                  id="banco"
                  name="banco"
                  InputProps={{
                    classes: {
                      underline: classes.textFieldUnderline,
                      input: classes.textField,
                    },
                  }}
                  label="Banco"
                  margin="normal"
                  value={mprvsel && mprvsel.banco}
                  type="text"
                  fullWidth
                />
                <TextField
                  multiline
                  id="paymentConditionInDays"
                  name="paymentConditionInDays"
                  InputProps={{
                    classes: {
                      underline: classes.textFieldUnderline,
                      input: classes.textField,
                    },
                  }}
                  label="Condicion de Pago"
                  margin="normal"
                  value={mprvsel && mprvsel.paymentConditionInDays}
                  type="text"
                  fullWidth
                />
                 <TextField
                  id="nrodeCuenta"
                  name="nrodeCuenta"
                  InputProps={{
                    classes: {
                      underline: classes.textFieldUnderline,
                      input: classes.textField,
                    },
                  }}
                  label="Nro de Cuenta"
                  margin="normal"
                  value={mprvsel && mprvsel.nrodeCuenta}
                  type="text"
                  fullWidth
                />
                  </Grid>
                  <Grid item xs={6} md={6} >
                  <TextField
                  id="monedaCuenta"
                  name="monedaCuenta"
                  InputProps={{
                    classes: {
                      underline: classes.textFieldUnderline,
                      input: classes.textField,
                    },
                  }}
                  fullWidth
                  label="Modena"
                  margin="normal"
                  value={mprvsel && mprvsel.monedaCuenta}
                  type="text"
                />
                <TextField
                  multiline
                  id="tipoCuenta"
                  name="tipoCuenta"
                  InputProps={{
                    classes: {
                      underline: classes.textFieldUnderline,
                      input: classes.textField,
                    },
                  }}
                  label="Tipo Cuenta"
                  margin="normal"
                  value={mprvsel && mprvsel.tipoCuenta}
                  type="text"
                  fullWidth
                />
                  <TextField
                  multiline
                  id="codigoSap"
                  name="codigoSap"
                  InputProps={{
                    classes: {
                      underline: classes.textFieldUnderline,
                      input: classes.textField,
                    },
                  }}
                  readOnly
                  label="Codigo Sap"
                  margin="normal"
                  value={mprvsel && mprvsel.codigoSap}
                  type="text"
                  fullWidth
                />
                <TextField
                  multiline
                  id="tipoPago"
                  name="tipoPago"
                  InputProps={{
                    classes: {
                      underline: classes.textFieldUnderline,
                      input: classes.textField,
                    },
                  }}
                  label="Tipo de Pago"
                  margin="normal"
                  value={mprvsel && mprvsel.tipoPago}
                  type="text"
                  fullWidth
                />
                  </Grid>

                </Grid>
                
                
                
            </Grid>
        </Grid>
            </Card>
            </TabPane>
            <TabPane tabId="2">
              
                  <Card body>
                  <CardTitle>{mprvsel && mprvsel.supplierName}</CardTitle>
                      <TableContainer component={Paper}>
                          <Table className={classes.table} aria-label="customized table">
                            <TableHead>
                              <TableRow>
                                <StyledTableCell>#</StyledTableCell>
                                <StyledTableCell align="center">Actividad</StyledTableCell>
                                <StyledTableCell align="center">Fecha</StyledTableCell>
                                <StyledTableCell align="center">Cambio</StyledTableCell>
                                <StyledTableCell align="center">Nombre</StyledTableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {mLogProv.map((row) => (
                                <StyledTableRow key={row.id}>
                                  <StyledTableCell component="th" scope="row">{row.id}</StyledTableCell>
                                  <StyledTableCell align="justify">{row.actividad}</StyledTableCell>
                                  <StyledTableCell align="justify">{row.fechaRegistro}</StyledTableCell>
                                  <StyledTableCell align="justify">{row.cambios}</StyledTableCell>
                                  <StyledTableCell align="left">{row.personalNombre}</StyledTableCell>
                                </StyledTableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                    
                  </Card>
            </TabPane>
            <TabPane tabId="3">
              <Card body>
                <CardTitle>{mprvsel && mprvsel.supplierName}</CardTitle>
                <div id="upload-box">
                  <input id="mfile" name="mfile" type="file" onChange={handleChangeImage} />
                  
                </div>
                <br/>
          
                <TableContainer component={Paper}>
                  <Table className={classes.table} aria-label="spanning table">
                    
                    <TableBody>
                      {mImagenes.map((row) => (
                        <TableRow key={row.id}>
                          <TableCell><Avatar alt="Remy Sharp" src={row.file} className={mclasses.large} /></TableCell>
                          <TableCell>{row.nombre}</TableCell>
                          <TableCell>{row.size}</TableCell>
                          <TableCell align="right">
                                  <IconButton color="primary" onClick={()=>delimage(row)} component="span">
                                  <Delete/>
                                  </IconButton>
                            </TableCell>
                        </TableRow>
                      ))}
                      
                    </TableBody>
                  </Table>
                </TableContainer>
              </Card>
            </TabPane>
          </TabContent>
        </div>
        </ModalBody>
        <ModalFooter>
            <Button className="btn btn-danger" onClick={()=>accionesModalVer()} >Cerrar</Button>
        </ModalFooter>
      </Modal>
      
    </>
  );
}
