import React,{ useState,useEffect , Fragment } from "react";
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { Grid,TextField,Paper,Button,IconButton, Hidden } from "@material-ui/core";
import Icon from '@material-ui/core/Icon';
// styles
import useStyles from "./styles";
import { withStyles, makeStyles } from '@material-ui/core/styles';
import axios from 'axios'
import { useSnackbar } from 'notistack';
// components
import PageTitle from "../../components/PageTitle/PageTitle";
import Widget from "../../components/Widget/Widget";
import { Typography } from "../../components/Wrappers/Wrappers";
import mock from "../dashboard/mock";
//import Table from "../dashboard/components/Table/TableArea";
import Tablep from "../dashboard/components/Table/TableProve";
import Tablect from "../dashboard/components/Table/Tablect";
import Tablemarca from "../dashboard/components/Table/TableMarca";
import classnames from "classnames";
import { DataGrid, RowsProp, ColDef, AutoSizer } from '@material-ui/data-grid';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import {Edit,Delete,PanoramaFishEyeRounded} from '@material-ui/icons';
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
import FaceIcon from '@material-ui/icons/Face';
import DoneIcon from '@material-ui/icons/Done';
import {Modal,ModalBody,ModalFooter,ModalHeader} from "reactstrap"
import { useAlert } from "react-alert";

import MiTable from "./DataTable";

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import { getDatalist ,getArea,getProveedor} from "./Controller";
import { SettingsSystemDaydreamTwoTone } from "@material-ui/icons";
import BtcArea from "../../components/BtcArea/BtcArea";
import BtcSolcompra from "../../components/BtcSolcompra/BtcSolcompra";
import Constantes from "../../context/Constates";

import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const museStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
    '& .super-app-theme--cell': {
      backgroundColor: 'rgba(224, 183, 60, 0.55)',
      color: '#1a3e72',
      fontWeight: '600',
    },
    '& .super-app.negative': {
      backgroundColor: '#d90007',
      color: '#FFFFFF',
      fontWeight: '600',
    },
    '& .super-app.positive': {
      backgroundColor: '#d47483',
      color: '#1a3e72',
      fontWeight: '600',
    },
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  large: {
    width: theme.spacing(15),
    height: theme.spacing(15),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#008000',
  },
 
}));
var ltareas=[];
var ltprov=[];
var ltmarcas=[];
var ltctmark=[];
var ContextUser={};
var Grupo={};

var tol=0;




export default function Solpago() {
  let history = useHistory();
  const alert = useAlert();
  const location = useLocation();
  ltareas=JSON.parse(localStorage.getItem('ltareas'));
  ltprov=JSON.parse(localStorage.getItem('ltprov'));
  ltmarcas=JSON.parse(localStorage.getItem('ltmarcas'));
  ltctmark=JSON.parse(localStorage.getItem('ltctmarks'));
  ContextUser=JSON.parse(localStorage.getItem('user'));
  Grupo=JSON.parse(localStorage.getItem('grupo'));
  var classes = useStyles();
  var mSolicitud={};
  var dataRecibido={};
  var lstSolpago={};
  var config = {
    headers: {'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json','Authorization': 'Bearer '+localStorage.getItem('id_token')}
};
 
  const mclasses = museStyles();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  getDatalist();

  useEffect(()=>{
    var idproces=location.pathname.substring(13, location.pathname.length);
    console.log(idproces);
    if (idproces!=null && idproces>0){
      dataRecibido.procesoId=idproces;
      obtSolicitudpago()
      setloading(true);
      setSoloLectura(true);
      console.log("son proceso")
    }else{
      if (location.state!=null) {
        dataRecibido=location.state.dtsolicitud;
        if (dataRecibido.procesoId>0) {
          obtSolicitudpago()
          setloading(true);
          if (parseInt(ContextUser.personalId)==13) {
            
          }
          if (dataRecibido.actividadId ==Constantes.SOLICITUDDEAPROBACIONDECOMPRA) {
            setbtnEncabezado("pendiente")
          } else{
            setbtnEncabezado("continuar")
          }
        }
      }
    }


    
    //GetPersonaAreas();
  },[location])

  
  const [mdataItm, setmdataItm] = React.useState([]);
  const [mdataItmpago, setmdataItmpago] = React.useState([]);
  const [mImagenes, setmImagenes] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [mdata, setmdata] = React.useState([]);
  const [mcolumns, setmcolumns] = React.useState([]);
  const [nameModal, setnameModal] = React.useState("");
  const [type,settype]=React.useState("");
  const [loadarea, setloadarea] = React.useState({});
  const [solicitud, setsolicitud] = React.useState({});
  const [loadprov, setloadprov] = React.useState(0);
  const TAX_RATE = 0.07;
  const [modalins, setmodalins] = React.useState(false);
  const [selectedDate, setSelectedDate] = React.useState(new Date('2014-08-18T21:11:54'));
  const [mdescripcion, setmdescripcion] = React.useState("");
  const [dataDescripcion, setdataDescripcion] = React.useState("");
  const [stateDescripcion, setstateDescripcion] = React.useState(false);
  const [loading,setloading]=React.useState(false);
  const [statearea, setstatearea] = React.useState(false);
  const [dataCuenta, setdataCuenta] = React.useState(0);
  const [stateCuenta, setstateCuenta] = React.useState(false);
  const [encargado, setEncargado] = React.useState("");
  const [mPendientePago, setPendientePago] = React.useState(0);
  const [mListaComentario, setmListaComentario] = React.useState(null);
  const [editable, seteditable] = useState(true);
  const [btnEncabezado, setbtnEncabezado] = useState("nuevo");
  //factura
  const [pricetotal, setpricetotal] = React.useState(0);
  const [mcomentario, setmcomentario] = React.useState("");
  const [FechaFactura, setFechaFactura] = React.useState("");
  const [FechaEntrega, setFechaEntrega] = React.useState("");
  const [NumFactura, setNumFactura] = React.useState("");
  const [Codigoctrl, setCodigoctrl] = React.useState("");
  const [CmtFactura, setCmtFactura] = React.useState("");
  const [NumeroAut, setNumeroAut] = React.useState(0);
  const [mSolicitudPago, setmSolicitudPago] = React.useState({});
  const [Selimpuesto, setSelimpuesto] = React.useState({});

  //procesos
  const [SolpagoPaso, setSolpagoPaso] = React.useState(1);
  
  ///variables entorno
  var spProveedor={};
  const [CuentaMayor, setCuentaMayor] = useState([]);
  const [Impuestos, setImpuestos] = useState([]);
  const [Banco, setBanco] = useState([]);
  /////*llenado tipo pago///
  const [Tipopago, setTipopago] = useState(1);
  const [tp_titulo, settp_titulo] = useState("");
  const [tp_banco, settp_banco] = useState("");
  const [tp_tipocuenta, settp_tipocuenta] = useState("");
  const [tp_CuentaBanco, settp_CuentaBanco] = useState("");
  const [tp_Moneda, settp_Moneda] = useState("");
  const [tp_CondPago, settp_CondPago] = useState("");
  const [tp_FechaLimite, settp_FechaLimite] = useState("");
  const [SoloLectura, setSoloLectura] = useState(false);

  const[mitem,setmitem]=React.useState({
    SolCompraId: 0,
    solCompraDetalleId: 0,
    solCompraDetalleTextoBreve:'',
    solCompraDetalleFechaEntregaStr:'',
    solCompraDetalleFechaEntrega:null,
    solCompraDetallePrecioUnitario:0,
    solCompraDetalleCantidad:'',
    solCompraDetalleCantidadRecibida:'',
    solCompraDetalleTotal:0,
    ImpId:0,
    tipoEstado:1
})
const[image,setimage]=React.useState({
  id: 0,
  file: null,
  nombre:'',
  size:""
})


var configjson = {
  headers: {'Content-Type': 'application/json' , 'Authorization': 'Bearer '+localStorage.getItem('id_token')}
};
  /*rutas */
  var voidSolicitus=Constantes.URLSERVER+Constantes.APIPOSTSOLICITUDPAGO
  var voidsenimages=Constantes.URLSERVER+Constantes.APIPOSTFILEPAGO
  var getSolicitudpago=Constantes.URLSERVER+Constantes.APIGETSOLICITUDPAGO
  var getSolicitud=Constantes.URLSERVER+Constantes.APIGETSOLICITUD
  var getDocumento=Constantes.URLSERVER+Constantes.APIGETDOCUMENTO
  var voidSolicitudEstado=Constantes.URLSERVER+Constantes.APIVOIDAPRORECHPAGO
  var getSolicitudp=Constantes.URLSERVER+Constantes.APIGETSOLICITUDPAGOF

  
  const handleChange=e=>{
    const { name, value}=e.target;
    setmitem({
        ...mitem,
        [name]:value
    })
  }
  const handleCodigoctrl=(event)=>{
    setCodigoctrl(event.target.value);
  }
  const handleNumeroAut = (event) => {
    setNumeroAut(event.target.value);
   };
  const handleNumFactura = (event) => {
    setNumFactura(event.target.value);
   };
  const handleFechaentrega=(event)=>{
    setFechaEntrega(reformatDate(event.target.value))
  }
  const handleFechaFactura=(event)=>{
    setFechaFactura(reformatDate(event.target.value))
  }
  const handleChangecomentario = (event) => {
    setmcomentario(event.target.value);
   };
  
  const handleChangedetallefactura=(data,e,sw)=>{
    mitem.SolCompraId= parseInt(data.solCompraId);
    mitem.solCompraDetalleId= parseInt(data.solCompraDetalleId);
    mitem.solCompraDetalleTextoBreve=data.solCompraDetalleTextoBreve;
    mitem.solCompraDetalleFechaEntregaStr=data.solCompraDetalleFechaEntregaStr;
    mitem.solCompraDetalleFechaEntrega=data.solCompraDetalleFechaEntrega;
    mitem.solCompraDetallePrecioUnitario= data.solCompraDetallePrecioUnitario;
    mitem.solCompraDetalleCantidad=data.solCompraDetalleCantidad;
    mitem.solCompraDetalleCantidadRecibida=parseInt(data.solCompraDetalleCantidadRecibida);

      mdataItm.map(dt => {
        if (dt.solCompraDetalleId==mitem.solCompraDetalleId) {
          if(sw==1){
            var valor=e.target.value;
            dt.solPagoDatosAdicionales=valor;
          }
          if(sw==2){  
            var valor=parseInt(e.target.value);
            if(mitem.solCompraDetalleCantidadRecibida<=mitem.solCompraDetalleCantidad){
              if(mitem.solCompraDetalleCantidadRecibida==mitem.solCompraDetalleCantidad){
                e.target.value=0;
              }else{
                var tcantidad=mitem.solCompraDetalleCantidad-mitem.solCompraDetalleCantidadRecibida;
                if (valor<=tcantidad) {
                  dt.solPagoCantidad=valor
                 // dt.solCompraDetalleTotal=0
                  dt.solCompraDetalleTotal=(valor*dt.solCompraDetallePrecioUnitario)
                }else{
                  e.target.value=tcantidad;
                }
              }

            }
            
          }  
          if(sw==3){
            var valor=parseInt(e.target.value);
            dt.cuentaMayorId=valor;
          }
          if(sw==4){
            var valor=(e.target.value)
            dt.solCompraDetalleTextoBreve=valor
            mitem.solCompraDetalleTextoBreve=valor
          }
         
        }
      });
    
    
    var stotal=0;
    mdataItm.map(dt => {
      stotal=stotal+dt.solCompraDetalleTotal
    });
    setpricetotal(stotal);
    setmdataItm(mdataItm);
   
  }
  const handleChangeImpuesto=(e)=>{
    var id=parseInt(e.target.value);
    Impuestos.map(imp=>{
      if(imp.impId==id){
        setSelimpuesto(imp)
      }
    })
  }
  
  const handleChangeImage=e=>{
    //setmfile(URL.createObjectURL(event.target.files[0]))
    var cfile={};
    if(mImagenes.length<=0){
      cfile.id=mImagenes.length+1;
      var mfile=e.target.files[0];
      cfile.files=mfile;
      cfile.file=URL.createObjectURL(e.target.files[0]);
      cfile.nombre=e.target.files[0].name;
      cfile.size=formatBytes(e.target.files[0].size);
      setimage(e.target)
      setmImagenes(mImagenes.concat(cfile));
    }    
  }
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const accionesModal=()=>{
    setmodalins(!modalins)
  }
  
  function ccyFormat(num) {
    return `${num.toFixed(3)}`;
  }
  
  function priceRow(qty, unit) {
    return qty * unit;
  }
  
  function createRow(solCompraDetalleId, solCompraDetalleTextoBreve,solCompraDetalleFechaEntregaStr, solCompraDetallePrecioUnitario,solCompraDetalleCantidadRecibida,solCompraDetalleCantidad) {
    const solCompraDetalleTotal = priceRow(solCompraDetallePrecioUnitario, solCompraDetalleCantidad);
    var solCompraDetalleFechaEntregaStr=format(solCompraDetalleFechaEntregaStr);
    return { solCompraDetalleId, solCompraDetalleTextoBreve, solCompraDetalleFechaEntregaStr, solCompraDetallePrecioUnitario,solCompraDetalleCantidad,solCompraDetalleCantidadRecibida,solCompraDetalleTotal };
  }

  var rows = [];
  
  function createRowPago(SolPagoId, SolPagoDetalleId,SolCompraDetalleId, SolCompraDetalleEstado,SolPagoCantidad,SolPagoPrecioUnitario,SolPagoProyecto,SolPagoCuentaMayor,solPagoDatosAdicionales,cuentaMayorId,solCompraDetalleTextoBreve ) {
    const SolPagoSubTotal = priceRow(SolPagoPrecioUnitario, SolPagoCantidad);
    return { SolPagoId, SolPagoDetalleId, SolCompraDetalleId, SolCompraDetalleEstado,SolPagoCantidad,SolPagoPrecioUnitario,SolPagoProyecto,SolPagoCuentaMayor,SolPagoSubTotal,solPagoDatosAdicionales,cuentaMayorId,solCompraDetalleTextoBreve };
  }
  

  const voidItem=async()=>{
    var stotal=0;
    console.log(mdataItm);
    mdataItm.map(dt => {
      stotal=stotal+dt.solCompraDetalleTotal
      var estado=true;
      if (dt.solPagoCantidad==0) {
        estado=false;
      }
      if(dt && dt.cuentaMayorId){
        var mit=createRowPago(1,1,dt.solCompraDetalleId,estado,dt.solPagoCantidad,dt.solCompraDetallePrecioUnitario,dt.SolPagoProyecto,dt.SolPagoCuentaMayor,dt.solPagoDatosAdicionales,dt.cuentaMayorId,dt.solCompraDetalleTextoBreve );
      }else{
        var mit=createRowPago(1,1,dt.solCompraDetalleId,estado,dt.solPagoCantidad,dt.solCompraDetallePrecioUnitario,dt.SolPagoProyecto,dt.SolPagoCuentaMayor,dt.solPagoDatosAdicionales,0,dt.solCompraDetalleTextoBreve);
      }
      if(Selimpuesto && Selimpuesto.impId>0){
        mit.ImpId=Selimpuesto.impId;
      }else{
        mit.ImpId=0;
      }
      mdataItmpago.push(mit);
    });
    setmdataItmpago(mdataItmpago);
    setpricetotal(stotal);
  }

  var data;
  
  function modalArea(){
    GetSolpago();
  }

  const GetSolpago=async()=>{
    try {
        var data={};
        const resp = await axios.get(getSolicitudpago,config);
        var result = resp.data;
        if(result.codigoRespuesta==0){
            var columns=[
                   { field: 'procesoId', headerName: '#', width: 120 },
                   { field: 'procesoCodigo', headerName: 'Solicitud de Compra', resizable: true,width: 230  },
                   { field: 'proveedor', headerName: 'Proveedor', width: 180 },
                   { field: 'monto', headerName: 'Monto', width: 110 },
                   { field: 'pendientePago', headerName: 'Pendiente Pago', width: 170 },
                   { field: 'descripción', headerName: 'Descripción', width: 180 },
                   { field: 'centroCosto', headerName: 'Centro de Costo', width: 230},
                   { field: 'manager', headerName: 'Manager', width: 230},
                   { field: 'nit', headerName: 'Nit', width: 230},
                   { field: 'contacto', headerName: 'Contacto', width: 230},
                   { field: 'correo', headerName: 'Correo', width: 230},
                   { field: 'telefonoOficina', headerName: 'Telefono Oficina', width: 230},
                   { field: 'condicionPago', headerName: 'Condicion Pago', width: 230},
                   { field: 'dirección', headerName: 'Dirección', width: 230},
                   { field: 'celular', headerName: 'Celular', width: 230},
                   { field: 'solicitante', headerName: 'Solicitante', width: 230},
                   
                 ];
            var rows=result.valorRespuesta;
            rows.map(dt => {
              dt.id=dt.procesoId;
            });
            lstSolpago=rows;
            setmdata(rows);
            setmcolumns(columns);
            //setnameModal("Seleccione Orden de compra");
            setOpen(true);
            settype(1);
        }else{
            
        }
        return data;
      } catch (err) {
          console.error(err);
      }
}
  
  function modalProveedor(){
    data=getProveedor();
    setmdata(data.rows);
    setmcolumns(data.columns);
    setnameModal("Seleccione Proveedor");
    setOpen(true);
    settype(2);
  }

  const clickhandler = id => loadData(id);

  const onMeetingTitleClick = (data) => {
    console.log("entro clik"+ data);
    loadData(data);
  }
  const onRowClicked = row => { onMeetingTitleClick(row); };

  function loadData(data){
    if(type==1){
      var obpago={};
      mdata.map(df=>{
        if(df.procesoId==data){
          obpago=df;
        }
      })
      lstSolpago=obpago;
      setloadarea(obpago);
      setEncargado(ContextUser.personalNombre);
      setPendientePago(obpago.pendientePago);
      setOpen(false);
      setloading(true);
      obtSolicitud();
    }else if(type==2){
      if (obpago.estado=='Activo') {
        setloadprov(data);  
        setOpen(false);
      }
    }
  }
  
  function format(inputDate) {
    var date = new Date(inputDate);
    if (!isNaN(date.getTime())) {
        // Months use 0 index.
        return date.getDate() + '/' +date.getMonth() + 1 + '/' + date.getFullYear();
    }
  }
  function formatd(inputDate) {
    var date = new Date(inputDate);
    if (!isNaN(date.getTime())) {
        // Months use 0 index.
        return date.getDay() + '/' +date.getMonth() + 1 + '/' + date.getFullYear();
    }
  }
  function reformatDate(dateStr)
  {
    var dArr = dateStr.split("-");  // ex input "2010-01-18"
    return dArr[2]+ "/" +dArr[1]+ "/" +dArr[0]; //ex out: "18/01/10"
  }
  const obtSolicitud=async()=>{
    try {
      var data={};
      var urlservice=getSolicitud+"ProcesoId="+lstSolpago.procesoId;
      const resp = await axios.get(urlservice,configjson);
      var result = resp.data;
      if(result.codigoRespuesta==0){
          var rows=result.valorRespuesta;
          var dcomentario=rows.solicitudCompra.solCompraDescripcion;
          setsolicitud(rows.solicitudCompra);
          var listentrgado=rows.listaSolicitudCompraDetalleEntregado;
          rows.solicitudCompra.listaSolicitudCompraDetalle.map(dt => {
            if(listentrgado && listentrgado.length>0){
              listentrgado.map(ent=>{
                if(ent.solCompraDetalleId==dt.solCompraDetalleId){
                  dt.solCompraDetalleCantidadRecibida=ent.cantidadEntregada;
                }
              })
            }else{
             // dt.solCompraDetalleTotal=0;
              dt.solCompraDetalleCantidadRecibida=0;
            }
            if(SolpagoPaso==1){
              dt.solCompraDetalleTotal=0;
            }
            dt.solPagoCantidad=0;
          });
          var ddetalle=rows.solicitudCompra.listaSolicitudCompraDetalle;
          setmdataItm(ddetalle);
          var dtotal=0;
          if(SolpagoPaso!=1){
            var dtotal=rows.solicitudCompra.solCompraTotal;
          }
          setpricetotal(dtotal);
          //seteditable(false);
          setloading(false);
      }else{
          alert.show(result.mensajeRespuesta);

      }
      return data;
    } catch (err) {
        console.error(err);
    }
  }
  
  const obtSolicitudpago=async()=>{
    try {
      var data={};
      var urlservice=getSolicitudp+"ProcesoId="+dataRecibido.procesoId;

      const resp = await axios.get(urlservice,configjson);
      var result = resp.data;
      if(result.codigoRespuesta==0){
          setTipopago(2);
          setSolpagoPaso(2);
          setbtnEncabezado();
          var rows=result.valorRespuesta;
          var dcomentario=rows.solicitudCompra.solCompraDescripcion;
          loadpago(rows.solicitudCompra);
          setPendientePago(rows.totalPendientePago)
          /*var solcompra=rows.solicitudCompra;
          solpagopendientes.map(dt => {
            dt.id=dt.procesoId;
          });*/
          setsolicitud(rows.solicitudCompra);
          setdataDescripcion(dcomentario);
          var solpago=(rows.solicitudPago);
          setmSolicitudPago(solpago)
          //carga tipo de pago
          spProveedor=rows.solicitudCompra.proveedor;
          if(Grupo.grupoPersonasId==Constantes.PASOTRESPAGOS){
            setSolpagoPaso(3);
            setCuentaMayor(rows.listaCuentaMayor);
            setBanco(rows.listaBanco);
            setTipopago(spProveedor.tipoPago)
            setImpuestos(rows.listaImpuesto)
            if (spProveedor.tipoPago==1) {
              settp_titulo("Tipo Pago Transferencia");
              settp_banco(spProveedor.banco);
              settp_tipocuenta(spProveedor.tipoCuenta);
              settp_CuentaBanco(spProveedor.nrodeCuenta);
              settp_Moneda(spProveedor.monedaCuenta);
              settp_CondPago(spProveedor.paymentConditionInDays);
              var ndate=addDay(solpago.solPagoFechaEntregaStr,spProveedor.paymentConditionInDays)
              settp_FechaLimite(ndate);
              
            }else if(spProveedor.tipoPago==2){
              settp_titulo("Tipo Pago Cheque");
            }
          }
          //
          rows.solicitudCompra.listaSolicitudCompraDetalle.map(dt => {
            rows.solicitudPago.listaSolicitudPagoDetalle.map(pg=>{
              if(pg.solCompraDetalleId==dt.solCompraDetalleId){
                dt.solPagoDatosAdicionales=pg.solPagoDatosAdicionales;
                dt.cuentaMayorId=pg.cuentaMayorId;
                dt.solCompraDetalleTotal=pg.solPagoSubTotal;
                rows.listaSolicitudCompraDetalleEntregado.map(en=>{
                  if(dt.solCompraDetalleId==en.solCompraDetalleId){
                    dt.solCompraDetalleCantidadRecibida=en.cantidadEntregada;
                  }
                })
                dt.solPagoCantidad=pg.solPagoCantidad;
              }
              dtotal=dtotal+pg.solPagoSubTotal
            });
          });
          var ddetalle=rows.solicitudCompra.listaSolicitudCompraDetalle;
          setmdataItm(ddetalle);
          var dtotal=0;
          rows.solicitudPago.listaSolicitudPagoDetalle.map(pg=>{
            dtotal=dtotal+pg.solPagoSubTotal
          });
          setpricetotal(dtotal);
          //seteditable(false);
          if(rows.solicitudCompra.proceso.procesoEstado=='FINALIZADA'){
            setbtnEncabezado('FINALIZADA');
          }
          var dDocumentos=rows.solicitudPago.solPagoNombreArchivo;
            if (dDocumentos!="") {
              var cfile={};
              cfile.id=1;
              var urlimage=getDocumento+"filename="+dDocumentos;
              cfile.file=urlimage;
              cfile.nombre=dDocumentos;
              cfile.size=formatBytes(urlimage);
              mImagenes.push(cfile)
              setmImagenes(mImagenes);
            }
            
                   
          var dComentarios=rows.solicitudPago.proceso.listaTareas;
          var contenido=(
            <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="spanning table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Personal</StyledTableCell>
                    <StyledTableCell >Comentario</StyledTableCell>
                    <StyledTableCell >Actividad</StyledTableCell>
                    <StyledTableCell >Fecha Asignacion</StyledTableCell>
                    <StyledTableCell >Fecha Ejecutada</StyledTableCell>
                    <StyledTableCell >Dias</StyledTableCell>
                  </TableRow>
                </TableHead>
                  <TableBody>
                    {dComentarios.map((row) => (
                      <TableRow key={row.item}>
                        <TableCell>{row.personalNombre}</TableCell>
                        <TableCell>{row.comentario}</TableCell>
                        <TableCell>{row.actividadNombre}</TableCell>
                        <TableCell>{row.tareaFechaAsignacionStr}</TableCell>
                        <TableCell>{row.tareaFechaEjecucionStr}</TableCell>
                        <TableCell>{row.dias}</TableCell>
                      </TableRow>
                    ))}
                    
                  </TableBody>
                </Table>
              </TableContainer>
          );
          setmListaComentario(contenido);
          setloading(false);
      }else{
        alert.show(result.mensajeRespuesta);
      }
      return data;
    } catch (err) {
        console.error(err);
    }
  }
  

  const handleClose = () => {
    setOpen(false);
  };
  const body = (
      <Grid item xs={12}>
        <Widget title={nameModal}  upperTitle noBodyPadding disableWidgetMenu={true} bodyClass={classes.tableOverflow}>
          <div style={{ height: 380 , padding:0}}   >
            <DataGrid 
            rows={mdata} 
            columns={mcolumns}  
            pageSize={5} 
            showToolbar
            onRowSelected={(newSelection) => {
            loadData(newSelection.data);
            }}  />
          </div>
        </Widget>
      </Grid>
  );
 
  /*Peticiones Servicio */
  const voidSolicitud=async()=>{
    console.log(validar());
    if(validar()){
      try {
        var data={};
        setloading(true);
        data.TipoEstado=1; ///insertar
        data.SolPagoId=0;
        data.ProcesoId=parseInt(solicitud.procesoId);
        data.SolComprasId=parseInt(solicitud.solCompraId);
        data.SolPagoAprobado=false;
        data.SolPagoCodigoControl=Codigoctrl;
        data.SolPagoNumAutorizacion=NumeroAut;
        data.SolPagoNumFactura=NumFactura;
        data.SolPagoCodEncCompras=mdescripcion;
        data.SolPagoNombreArchivo="";
        data.SolPagoTotal=pricetotal;
        data.SolPagoFechaFacturaStr=FechaFactura;
        data.SolPagoFechaEntregaStr=FechaEntrega;
        data.PersonalId=parseInt(ContextUser.personalId);
        data.Comentario=mcomentario;
        data.ImprimirOrdenCompra=false;
        data.SolPagoUID=uuid();

        setmdataItmpago([]);
        voidItem();
        data.ListaSolicitudPagoDetalle=mdataItmpago;

        const json = JSON.stringify(data);
        console.log(json);

        const resp = await axios.post(voidSolicitus,json,configjson);
        var result = resp.data;
        if(result.codigoRespuesta==0){
          data=result.valorRespuesta;
          mSolicitud=data;
          voidSendImage();
        /*var msj='Cod: '+mSolicitud.procesoId+' Solicitud enviada..';
          alert(msj);
          handleClickVariant(msj,'success')
          history.push({
            pathname: '../../app/dashboard',
            state: { dtsolicitud: mSolicitud }
          });*/
        }else{
          alert.show(result.mensajeRespuesta);
        }
        setloading(false);
        return data;
      } catch (err) {
          console.error(err);
      }
    }else{
      console.log("no");
      alert.error("Falta llenar datos!");
    }
    
}

const voidEstadoSolicitud=async(estado)=>{
  if(true){
    try {
      var data={};
      setloading(true);
      //data.TipoEstado=1; ///insertar
      data.estado=parseInt(estado);
      data.procesoId=mSolicitudPago.procesoId;
      data.personalId=parseInt(ContextUser.personalId);
      data.comentario=mcomentario;
      if(CmtFactura && CmtFactura!=""){
        data.comentarioSap=CmtFactura;
      }
      setmdataItmpago([]);
      voidItem();
      data.SolicitudPagoDetalle=mdataItmpago;
      const json = JSON.stringify(data);
      console.log(json);
      const resp = await axios.post(voidSolicitudEstado,json,configjson);
      var result = resp.data;
      if(result.codigoRespuesta==0){
        data=result.valorRespuesta;
        // if(SolpagoPaso==3){
        //   alert.show(result.mensajeRespuesta);
        // }
        var msj='Cod: '+data.procesoCodigo;
        if(estado==1){
          msj=msj+' Solicitud pago aprobada..'
        }else if (estado==2){
          msj=msj+' Solicitud pago rechazada..'
        }else if(estado==3){
          msj=msj+' Solicitud pago terminada..'
        }
        alert.success(msj, {timeout: 0   });
        handleClickVariant(msj,'success')
        history.push({
          pathname: '../../app/dashboard',
          state: { dtsolicitud: mSolicitud }
        });
      }else{
        alert.show(result.mensajeRespuesta);
      }
      setloading(false);
      return data;
    } catch (err) {
      //alert.show(result.mensajeRespuesta);
        console.error(err);
        history.push({
          pathname: '../../app/dashboard',
          //state: { dtsolicitud: mSolicitud }
        });
       // alert.error(err);
    }
  }else{
    alert.show("faltan datos!!");
  }
}

const voidSendImage=async()=>{
  try {
    var configimage = {
      headers: {'Content-Type': 'multipart/form-data','Authorization': 'Bearer '+localStorage.getItem('id_token')}
    };
    var formData = new FormData();
    mImagenes.map(dt => {
      formData.append("files", dt.files);
    });
    var urlservice=voidsenimages+"?ProcesoId="+mSolicitud.procesoId;
      const resp = await axios.post(urlservice,formData,configimage);
      var result = resp.data;
      if(result.codigoRespuesta==0){
        var msj='Cod: '+mSolicitud.proceso.procesoCodigo+' Solicitud enviada..';
        alert.success(msj, {timeout: 0   });
        handleClickVariant(msj,'success')
        history.push({
          pathname: '../../app/dashboard',
          state: { dtsolicitud: mSolicitud }
        });
      }else{
        alert.show(result.mensajeRespuesta);
      }
      return data;
    } catch (err) {
        console.error(err);
    }
}

  /*end peticiones*/
  /*funciones */
  function formatBytes(a,b=2){if(0===a)return"0 Bytes";const c=0>b?0:b,d=Math.floor(Math.log(a)/Math.log(1024));return parseFloat((a/Math.pow(1024,d)).toFixed(c))+" "+["Bytes","KB","MB","GB","TB","PB","EB","ZB","YB"][d]}
   
  function uuid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

  function validar(){
    var sw=true;
      if (Codigoctrl=="") {
        sw=false;
        alert.show("El campo codigo de control es obligatorio!!");
      }
      if (NumeroAut=="") {
        sw=false;
        alert.show("El campo Numero Autorizacion es obligatorio!!");
      }
      if (NumFactura=="") {
        sw=false;
        alert.show("El campo Numero factura es obligatorio!!");
      }
      // if (mcomentario=="") {
      //   sw=false;
      //   alert.show("El campo comentario es obligatorio!!");
      // }
      if (FechaFactura=="") {
        sw=false;
        alert.show("El campo fecha de factura es obligatorio!!");
      }
      if (FechaEntrega=="") {
        sw=false;
        alert.show("El campo fecha de entrega es obligatorio!!");
      }
      if(mImagenes && mImagenes.length<1){
        alert.show("Es necesario agregar adjunto!!");
        sw=false;
      }
      return sw;
    }

  function validarEstados(estado){
    var sw=true;
    if(estado==3 || estado==4){
        /*if(CmtFactura && CmtFactura==""){
          alert.show("Comentario de Factura es obligatorio!!");
          sw=false;
        }
        if(Tipopago==1 && Selimpuesto==null){
          alert.show("Debe seleccionar el tipo de impuesto!!");
          sw=false;
        }*/
    }
  } 
    const handleChangedescripcion = (event) => {
      setmdescripcion(event.target.value);
    };
    const handlecmtfactura = (event) => {
      setCmtFactura(event.target.value);
    };
     
    
     

     const delimage= (data) =>{
      var index = mImagenes.indexOf(data);
        if (index > -1) {
          mImagenes.splice(index, 1);
          var temp = mImagenes;
          setmImagenes([])
          setTimeout(() => {
            setmImagenes(temp);
          }, 1000);
        }
     }

     const delitem= (data) =>{
      var index = mdataItm.indexOf(data);
      if (index > -1) { 
        mdataItm.splice(index, 1);
        setmImagenes(mdataItm);
      }
     }
     const edititem=(data)=>{

     }

     
     const handleClickVariant = (variant) => () => {
      // variant could be success, error, warning, info, or default
      var msj='Cod: '+mSolicitud.procesoId+' Solicitud enviada..';
      enqueueSnackbar(msj, { variant });
    };

    function loadpago (data){
      var lsolcompra={};
      lsolcompra.procesoCodigo=data.proceso.procesoCodigo;
      lsolcompra.proveedor=data.proveedor.supplierName;
      lsolcompra.monto=data.solCompraTotal;
      lsolcompra.descripción=data.solCompraDescripcion;
      lsolcompra.ceCoDescripción=data.jtI_64_Area.costCenter;
      lsolcompra.personalIdManager=data.jtI_64_Area.personalIdManager;
      lsolcompra.manager=data.jtI_64_Area.manager;
      lsolcompra.nit=data.proveedor.taxIdentificationNumber;
      lsolcompra.contacto=data.proveedor.taxIdentificationNumber;
      lsolcompra.correo=data.proveedor.contactMail;
      lsolcompra.telefonoOficina=data.proveedor.officePhone;
      lsolcompra.condicionPago=data.proveedor.paymentConditionInDays;
      lsolcompra.dirección=data.proveedor.address;
      lsolcompra.celular=data.proveedor.cellandWhasAppNumber;
      lsolcompra.solicitanteId="";
      lsolcompra.procesoId=data.procesoId;
      setloadarea(lsolcompra);
      /*var component=(<BtcSolcompra solcompra={lsolcompra} ></BtcSolcompra>);
      setrjBtcSolcompra(component);*/
      //solcompra.solicitanteId=data.
    }
  /*end funciones*/
  /*otras funciones */
  function addDay(date,mday){
    var dy=parseInt(mday);
    var day = date.substring(0,2);
    var month = date.substring(3,5);
    var year = date.substring(6,10);
    var dateObj = new Date (month + '/' + day + '/' + year);
    var newdate = new Date(dateObj);
    newdate.setDate(newdate.getDate() + dy);
    var dd = newdate.getDate();
    var mm = newdate.getMonth() + 1;
    var y = newdate.getFullYear();
    var someFormattedDate = dd + '/' + mm + '/' + y;
    return someFormattedDate;
  }
  
  /**end otras funciones */
  return (
    <>
      <Grid container spacing={6}>
        <Grid item xs={12} md={12}  style={{paddingBottom: 1}}>
          {!SoloLectura && SolpagoPaso==1 && <Widget  title="Opciones" disableWidgetMenu>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={()=>voidSolicitud()}
                  className={classes.button}
                  endIcon={<Icon>send</Icon>}>
                  Enviar
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => history.goBack()}
                  className={classes.button}
                  endIcon={<Icon>cancel_schedule_send</Icon>}>
                  Cancelar
                </Button>
                
                </Widget>}
          {!SoloLectura && SolpagoPaso==3 && <Widget  title="Opciones" disableWidgetMenu>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={()=>voidEstadoSolicitud(3)}
                  className={classes.button}
                  endIcon={<Icon>navigate_next</Icon>}>
                  Continuar
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => history.goBack()}
                  className={classes.button}
                  endIcon={<Icon>cancel_schedule_send</Icon>}>
                  Cancelar
                </Button>
                {/* <Button
                  variant="contained"
                  color="primary"
                  hidden="true"
                  onClick={()=>voidEstadoSolicitud(4)}
                  className={classes.button}
                  endIcon={<Icon>navigate_next</Icon>}>
                  Sincronizar SAP
                </Button> */}
                </Widget>}
          {!SoloLectura && SolpagoPaso==2 && <Widget  title="Opciones" disableWidgetMenu>
              <Button
                variant="contained"
                color="primary"
                onClick={()=>voidEstadoSolicitud(1)}
                className={classes.button}
                endIcon={<Icon>done</Icon>}>
                Aprobar
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={()=>voidEstadoSolicitud(2)}
                className={classes.button}
                endIcon={<Icon>do_disturb</Icon>}>
                Rechazar
              </Button>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => history.goBack()}
                className={classes.button}
                endIcon={<Icon>cancel_schedule_send</Icon>}>
                Cancelar
              </Button>
              </Widget>}
        </Grid>
      
        {mSolicitudPago.proceso.procesoCodigo && <Grid item xs={12} md={12}  style={{paddingBottom: 1}}>
        <label>Codigo:</label>
                <br/>
                <input id="id" name="id" type="text" readOnly className="form-control" defaultValue={mSolicitudPago.proceso.procesoCodigo}></input>
        </Grid>   }     

        <Grid item xs={12} md={12}  style={{paddingBottom: 1}}>
          <Widget  title="Orden de compra" disableWidgetMenu>
            <Grid container spacing={12}>
              <Grid item xs={12} md={12} >
                {SolpagoPaso ==1 &&   <TextField
                  id="ODC"
                  InputProps={{
                    classes: {
                      underline: classes.textFieldUnderline,
                      input: classes.textField,
                    },
                  }}
                  onClick={modalArea}
                  margin="normal"
                  required
                  label="Seleccione Orden de compra"
                  placeholder="Seleccione Orden de Compra"
                  type="text"
                  disabled={statearea}
                  fullWidth
                  />
                }
                 <BtcSolcompra solcompra={loadarea} ></BtcSolcompra> 
              </Grid>
            </Grid>
          </Widget>
        </Grid>
         {SolpagoPaso==3 && Tipopago==1 && <Grid item xs={12} md={12}  style={{paddingBottom: 1}}>
          <Widget  title={tp_titulo} disableWidgetMenu>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} >
                  <TextField
                    margin="normal"
                    value={tp_banco}
                    label="Banco"
                    type="text"
                    fullWidth
                  />
                  <TextField
                    margin="normal"
                    value={tp_tipocuenta}
                    label="Tipo de Cuenta"
                    type="text"
                    fullWidth
                  />
                  <TextField
                    margin="normal"
                    value={tp_CuentaBanco}
                    label="Cuenta Bancaria"
                    type="text"
                    fullWidth
                  />
                </Grid>
            <Grid item xs={12} md={6} >
                  <TextField
                    margin="normal"
                    value={tp_Moneda}
                    label="Moneda"
                    type="text"
                    fullWidth
                  />
                  <TextField
                    margin="normal"
                    value={tp_CondPago}
                    label="Condicion de Pago"
                    type="text"
                    fullWidth
                  />
                  <TextField
                    margin="normal"
                    value={tp_FechaLimite}
                    label="Fecha Limite de Pago"
                    type="text"
                    fullWidth
                  />
                  
              </Grid>
          </Grid> 
        
          </Widget>
        </Grid>}
        
        
        {SolpagoPaso==1 && 
        <Grid item xs={12} md={12}  style={{paddingBottom: 1}}>
          <Widget  title="Factura" disableWidgetMenu>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} >
                  <TextField
                    margin="normal"
                    onChange={handleCodigoctrl}
                    label="Codigo Control"
                    type="text"
                    fullWidth
                  />
                  <TextField
                    margin="normal"
                    onChange={handleNumeroAut}
                    label="Numero Autorizacion"
                    type="number"
                    fullWidth
                  />
                  <TextField
                    margin="normal"
                    onChange={handleFechaentrega}
                    label="Fecha de Entrega"
                    type="date"
                    format={'DD/MM/YYYY'}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                  />
                </Grid>
            <Grid item xs={12} md={6} >
                  <TextField
                    margin="normal"
                    onChange={handleNumFactura}
                    label="Numero de factura"
                    type="text"
                    fullWidth
                  />
                  <TextField
                    margin="normal"
                    onChange={handleFechaFactura}admin123
                    label="Fecha de la Factura"
                    type="date"
                    format={'DD/MM/YYYY'}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                  />
                  
              </Grid>
          </Grid> 
          </Widget>
        </Grid>}
        {SolpagoPaso!=1 &&
        <Grid item xs={12} md={12}  style={{paddingBottom: 1}}>
          <Widget  title="Factura" disableWidgetMenu>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6} >
                  <TextField
                    margin="normal"
                    value={mSolicitudPago && mSolicitudPago.solPagoCodigoControl}
                    label="Codigo Control"
                    type="text"
                    InputProps={{
                      classes: {
                        underline: classes.textFieldUnderline,
                        input: classes.textField,
                        readOnly: true,
                      },
                    }}
                    fullWidth
                  />
                  <TextField
                    margin="normal"
                    label="Numero Autorizacion"
                    value={mSolicitudPago && mSolicitudPago.solPagoNumAutorizacion}
                    type="number"
                    InputProps={{
                      classes: {
                        underline: classes.textFieldUnderline,
                        input: classes.textField,
                      },
                      readOnly: true,
                    }}
                    fullWidth
                  />
                  <TextField
                    margin="normal"
                    label="Fecha de Entrega"
                    type="text"
                    value={mSolicitudPago && mSolicitudPago.solPagoFechaEntregaStr}
                    InputProps={{
                      classes: {
                        underline: classes.textFieldUnderline,
                        input: classes.textField,
                      },
                      readOnly: true,
                    }}
                    fullWidth
                  />
                </Grid>
              <Grid item xs={12} md={6} >
                    <TextField
                      margin="normal"
                      label="Numero de factura"
                      InputProps={{
                        classes: {
                          underline: classes.textFieldUnderline,
                          input: classes.textField,
                        },
                        readOnly: true,
                      }}
                      value={mSolicitudPago && mSolicitudPago.solPagoNumFactura}
                      type="text"
                      fullWidth
                    />
                    <TextField
                      margin="normal"
                      label="Fecha de la Factura"
                      value={mSolicitudPago && mSolicitudPago.solPagoFechaFacturaStr}
                      type="text"
                      InputProps={{
                        classes: {
                          underline: classes.textFieldUnderline,
                          input: classes.textField,
                        },
                        readOnly: true,
                      }}
                      fullWidth
                    />
                  {SolpagoPaso==3 && <FormControl className={classes.formControl} fullWidth>
                    <InputLabel htmlFor="age-native-simple">Tipo Impuesto</InputLabel>
                    <Select
                    native
                    onChange={(e)=>handleChangeImpuesto(e)}
                    >
                        <option value=""></option>
                        {Impuestos.map(pers => (
                                <option
                                  key={pers.impId}
                                  value={pers.impId}
                                  >
                                  {pers.impNombre}
                                </option>
                        ))}
                    </Select>
                </FormControl>}
                </Grid>
            </Grid> 
          </Widget>
        </Grid>}
      
        <Grid item xs={12} md={16} style={{paddingBottom: 1}}>
          <Widget title="Detalle de la factura" disableWidgetMenu>
          <Grid container spacing={12}>
              <Grid item xs={12} md={12} >
              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="spanning table">
                  <TableHead>
                    <TableRow>
                      <TableCell style={{width: '8%'}}>Item</TableCell>
                      <TableCell align="center">Texto breve.</TableCell>
                      {SolpagoPaso > 1 &&<TableCell align="center">Datos Adicionales</TableCell>}
                      {SolpagoPaso==3 &&<TableCell align="center">Cuenta Mayor</TableCell>}
                      <TableCell align="center">Fecha entrega</TableCell>
                      <TableCell align="center">Precio Unitario</TableCell>
                      <TableCell align="center">Cantidad Pedida</TableCell>
                      <TableCell align="center">Cantidad recibida</TableCell>
                      <TableCell align="center" style={{width: '7%'}}>Cantidad</TableCell>
                      <TableCell align="right">Total Bs</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {mdataItm.map((row) => (
                      <TableRow key={row.solCompraDetalleId}>
                        <TableCell>{row.solCompraDetalleId}</TableCell>
                        {SolpagoPaso < 3 && <TableCell align="justify">{row.solCompraDetalleTextoBreve}</TableCell>}
                        {SolpagoPaso>=3 && <TableCell align="justify"><input  type="text" onChange={(e)=>handleChangedetallefactura(row,e,4)} Value={row && row.solCompraDetalleTextoBreve} defaultValue={row && row.solCompraDetalleTextoBreve} className="form-control" ></input></TableCell>}
                        {!SoloLectura && SolpagoPaso > 1 &&<TableCell align="right"><input  type="text" onChange={(e)=>handleChangedetallefactura(row,e,1)} defaultValue={row && row.solPagoDatosAdicionales} defaultValue={row && row.solPagoDatosAdicionales} className="form-control" ></input></TableCell>}
                        {SoloLectura && <TableCell align="right"><input  type="text" readOnly defaultValue={row && row.solPagoDatosAdicionales} defaultValue={row && row.solPagoDatosAdicionales} className="form-control" ></input></TableCell>}
                        {SolpagoPaso==3 &&<TableCell align="right">
                          <select   className="form-control" onChange={(e)=>handleChangedetallefactura(row,e,3)} defaultValue={row && row.cuentaMayorId}>
                            <option value="">Cuenta mayor</option>
                              {CuentaMayor.map(pers => (
                                <option
                                  key={pers.cuentaMayorId}
                                  value={pers.cuentaMayorId}
                                  >
                                  {pers.cuentaMayorNombre}
                                </option>
                              ))}
                          </select></TableCell>}
                        <TableCell align="right">{row.solCompraDetalleFechaEntregaStr}</TableCell>
                        <TableCell align="right">{row.solCompraDetallePrecioUnitario}</TableCell>
                        <TableCell align="right">{row.solCompraDetalleCantidad}</TableCell>
                        <TableCell align="right">{row.solCompraDetalleCantidadRecibida}</TableCell>
                        {SolpagoPaso == 1 && <TableCell align="right"><input  type="number" onChange={(e)=>handleChangedetallefactura(row,e,2)} Value={row && row.solPagoCantidad} defaultValue={row && row.solPagoCantidad} className="form-control" ></input></TableCell>
                        }
                        { SolpagoPaso != 1 && <TableCell align="right">{row.solPagoCantidad}</TableCell>}
                        <TableCell align="right">{row.solCompraDetalleTotal}</TableCell>
                      </TableRow>
                    ))}
                    <TableRow>
                      <TableCell  />
                      <TableCell  />
                      <TableCell />
                      <TableCell />
                      <TableCell > <strong>Total Detalle</strong> </TableCell>
                      <TableCell align="right">{pricetotal}</TableCell>
                      <TableCell />
                    </TableRow>
                    <TableRow>
                      <TableCell  />
                      <TableCell  />
                      <TableCell />
                      <TableCell />
                      <TableCell > <strong>Pendiente de Pago</strong> </TableCell>
                      <TableCell align="right">{mPendientePago}</TableCell>
                      <TableCell />
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            
              </Grid>
          </Grid>


            
          </Widget>
        </Grid>
        
        {SolpagoPaso==3 &&  <Grid item xs={12} md={12}  style={{paddingBottom: 1}}>
          <Widget  title="Comentario de Factura" disableWidgetMenu>
             <TextField
                InputProps={{
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField,
                  },
                }}
                Width="50%"
                margin="normal"
                required
                multiline
                onChange={handlecmtfactura}
                placeholder="Comentario de factura"
                type="text"
                fullWidth
              />
              <br/>
              <br/>
        </Widget>
        </Grid>}
        <br/>                


        <Grid item xs={12} md={12}  style={{paddingBottom: 1}}>
          <Widget  title="Factura escaneada" disableWidgetMenu>
          {SolpagoPaso==1 && mImagenes.length<=0 &&  <div id="upload-box">
            <input id="mfile" name="mfile" type="file" onChange={handleChangeImage} />
            
          </div>}
          <br/>
          
            <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="spanning table">
                  
                  <TableBody>
                    {mImagenes.map((row) => (
                      <TableRow key={row.id}>
                        <TableCell><Avatar alt="Remy Sharp" src={row.file} className={mclasses.large} /></TableCell>
                        <TableCell>{row.nombre}</TableCell>
                        {Tipopago!=2 && <TableCell  align="right">
                                <IconButton color="primary" onClick={()=>delimage(row)} component="span">
                                <Delete/>
                                </IconButton>
                          </TableCell>}
                      </TableRow>
                    ))}
                    
                  </TableBody>
                </Table>
              </TableContainer>
        </Widget>
        </Grid>
        
        <Grid item xs={12} md={12}  style={{paddingBottom: 1}}>
          <Widget  title="Historial de Proceso" disableWidgetMenu>
             <TextField
                id="comentario"
                name="comentario"
                InputProps={{
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField,
                  },
                }}
                onChange={handleChangecomentario}
                Width="50%"
                margin="normal"
                required
                multiline
                placeholder="Comentario"
                type="text"
                fullWidth
              />
              <br/>
              <br/>
              {mListaComentario}
        </Widget>
        </Grid>
        <br/>
        <br/>
        
      </Grid>

      <Modal
        open={open}
        onClose={handleClose}
        noBodyPadding
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
      
  
      <Modal
        isOpen={open}
        paddingTop={30}
        centered
        zIndex={5000}
        size={'xl'}
        autoFocus={true}
      >
        <ModalBody>
        {/* {body} */}
        <MiTable data={mdata}  click={clickhandler}  onRowClicked={onRowClicked}/>
        </ModalBody>
        <ModalFooter>
            <Button className="btn btn-danger" onClick={()=>handleClose()} >Cerrar</Button>
        </ModalFooter>
      </Modal>
      

      <Modal
        isOpen={modalins}
        paddingTop={30}
        centered
        zIndex={9000}
        size={'lg'}
      >
        <ModalBody>
        <Grid container spacing={6}>
            <Grid item xs={12} md={12}>
              <TextField
                  multiline
                  id="solCompraDetalleTextoBreve"
                  name="solCompraDetalleTextoBreve"
                  InputProps={{
                    classes: {
                      underline: classes.textFieldUnderline,
                      input: classes.textField,
                    },
                  }}
                  onChange={handleChange}
                  label="Texto Breve"
                  margin="normal"
                  required
                  type="text"
                  fullWidth
                />

                <TextField
                  id="solCompraDetalleFechaEntregaStr"
                  name="solCompraDetalleFechaEntregaStr"
                  onChange={handleChange}
                  label="Fecha de Entrega"
                  margin="normal"
                  required
                  type="date"
                  format={'DD/MM/YYYY'}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  fullWidth
                />
                
                <TextField
                  id="solCompraDetallePrecioUnitario"
                  name="solCompraDetallePrecioUnitario"
                  InputProps={{
                    classes: {
                      underline: classes.textFieldUnderline,
                      input: classes.textField,
                    },
                  }}
                  onChange={handleChange}
                  label="Precio"
                  margin="normal"
                  required
                  type="number"
                  fullWidth
                />
                <TextField
                  id="solCompraDetalleCantidad"
                  name="solCompraDetalleCantidad"
                  InputProps={{
                    classes: {
                      underline: classes.textFieldUnderline,
                      input: classes.textField,
                    },
                  }}
                  onChange={handleChange}
                  label="Cantidad"
                  margin="normal"
                  required
                  type="number"
                  fullWidth
                />
                
            </Grid>
        </Grid>
        </ModalBody>
        <ModalFooter>
        <Grid >
        
            <Button
              variant="contained"
              color="success"
              size="small"
              onClick={()=>accionesModal()}
              style={{ marginLeft: 16 ,color:"#FFFFFF"}}
            >
              Cerrar
            </Button>
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={()=>voidItem()}
              style={{ marginLeft: 16 ,color:"#FFFFFF"}}
            >
              Agregar
            </Button>
        </Grid>
        </ModalFooter>
      </Modal>

          

      <Backdrop className={mclasses.backdrop} open={loading} >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}
