var token=localStorage.getItem('id_token');

export default {
 //URLSERVER: process.env.REACT_APP_SERVER_URL,
  // URLSERVER: "http://177.222.33.6:1415/",
    URLSERVER: "https://bflowserv.banticanalytica.com/",
    //URLSERVER: "http://localhost:1414/",

    APILOGIN: "api/servicio/login?",
    APIPOSTLOGIN: "api/servicio/loginAuth",
    APIGETMENU: "api/servicio/getMenu?",
    APIGETLISTA: "api/servicio/getListaFormSolicitudCompra",
    APIGETLISTAAREA: "api/servicio/getListaJTIAreas",
    APIGETAREA: "api/servicio/getJTIArea?",
    APIGETPERSAREA: "api/servicio/getPersonasArea",
    APIPOSTAREA: "api/servicio/guardarArea",
    APIGETPROVEEDORES: "api/servicio/getListaProveedores",
    APIGETPROVEEDORESANIO: "api/servicio/getListaProveedoresFiltroAnio",
    APIGETPROVEEDOR: "api/servicio/getProveedor?",
    APIPOSTPROVEEDOR: "api/servicio/guardarProveedor",
    APIGETLOGSLISTPROVELOG: "api/servicio/getListaProveedoresLog?",
    APIGETLOGSHISTORICOPROCES: "api/servicio/getListaHistoricoProcesosLog",
    APIPOSTSOLICITUD: "api/servicio/guardarSolicitudCompra",
    APIPOSTFILE: "api/servicio/upload",
    APIPOSTFILEPAGO: "api/servicio/uploadpago",
    APIPOSTFILEPROVEEDOR: "api/servicio/uploadproveedor",
    APIGETTAREAS: "api/servicio/TareasPendientes?",
    APIGETSOLICITUD: "api/servicio/getSolicitudCompra?",
    APIGETDOCUMENTO: "api/servicio/download?",
    APIGETDOCUMENTOS: "api/servicio/getProveedorArchivos?",
    APIGETSEGUIMIENTO: "api/servicio/getSeguimientoSolicitudCompra",
    APIGETSEGUIMIENTOUSER: "api/servicio/getSeguimientoSolicitudCompra?",
    APIVOIDAPRORECH: "api/servicio/guardarAprobarRechazar",
    APIGETSOLICITUDPAGO: "api/servicio/getListaSolicitudCompraPendientesPago?",
    APIPOSTSOLICITUDPAGO: "api/servicio/guardarSolicitudPago",
    APIGETSOLICITUDPAGOF: "api/servicio/getSolicitudPago?",
    APIVOIDAPRORECHPAGO: "api/servicio/guardarAprobarRechazarSolPago",
    APIGETROLESUSER: "api/servicio/getFormInicialRol",
    APIGETROLAPPUSER: "api/servicio/getRolAplicacionPersonal?",
    APIPOSTROL: "api/servicio/guardarRol",
    APIPOSTROLAPP: "api/servicio/guardarRolAplicaciones",
    APIPOSTROLUSER: "api/servicio/guardarRolPersonas",
    APIPOSTPERSONAL: "api/servicio/guardarPersonal",
    APIPOSTPASSWORD: "api/servicio/CambiarClave",
    APIGETSEGUIMIENTOPAGO: "api/servicio/getSeguimientoSolicitudPago?",
    APIGETRESETCLAVE: "api/servicio/resetClavePersonal?",
    APIGETLISTPROCESOS: "api/servicio/getListaProcesos?",
    APIPOSTANULARPROCESO: "api/servicio/anularProceso",
    APIGETLISTCAMBIOS: "api/servicio/getListaGestionCambios",
    APIPOSTRECPASS: "api/servicio/OlvidoClave",
    APIGETVALIDARHASH: "api/servicio/OlvidoClaveValidarHash?",
    APIPOSTRECCLAVESAVE: "api/servicio/OlvidoClaveHashGuardar",
    APIGETLOSECUSER: "api/servicio/getListaUsuariosLog?",
    APIGETREPORTE: "api/Reporte/getReporteCompra?",
    APIGETAREASPROCESO: "api/servicio/getListaTareasByProceso?",
    

    //ESTADOS
    SOLICITUDDEAPROBACIONDECOMPRA : 248,
    SOLICITUDDEAPROBACIONDECOMPRAGG : 259,
    PASOTRESPAGOS:21,


    configjsonAuth : {
      headers: {'Content-Type': 'application/json'},
      headers: {'Authorization': 'Bearer '+token}      
    },
    bigStat: [
      {
        product: "Light Blue",
        total: {
          monthly: 4232,
          weekly: 1465,
          daily: 199,
          percent: { value: 3.7, profit: false }
        },
        color: "primary",
        registrations: {
          monthly: { value: 830, profit: false },
          weekly: { value: 215, profit: true },
          daily: { value: 33, profit: true }
        },
        bounce: {
          monthly: { value: 4.5, profit: false },
          weekly: { value: 3, profit: true },
          daily: { value: 3.25, profit: true }
        }
      },
      {
        product: "Sing App",
        total: {
          monthly: 754,
          weekly: 180,
          daily: 27,
          percent: { value: 2.5, profit: true }
        },
        color: "warning",
        registrations: {
          monthly: { value: 32, profit: true },
          weekly: { value: 8, profit: true },
          daily: { value: 2, profit: false }
        },
        bounce: {
          monthly: { value: 2.5, profit: true },
          weekly: { value: 4, profit: false },
          daily: { value: 4.5, profit: false }
        }
      }
    ],
    notifications: [
      {
        id: 0,
        icon: "thumbs-up",
        color: "primary",
        content:
          'Ken <span className="fw-semi-bold">accepts</span> your invitation'
      },
      {
        id: 1,
        icon: "file",
        color: "success",
        content: "Report from LT Company"
      },
      {
        id: 2,
        icon: "envelope",
        color: "danger",
        content: '4 <span className="fw-semi-bold">Private</span> Mails'
      },
      {
        id: 3,
        icon: "comment",
        color: "success",
        content: '3 <span className="fw-semi-bold">Comments</span> to your Post'
      },
      {
        id: 4,
        icon: "cog",
        color: "light",
        content: 'New <span className="fw-semi-bold">Version</span> of RNS app'
      },
      {
        id: 5,
        icon: "bell",
        color: "info",
        content:
          '15 <span className="fw-semi-bold">Notifications</span> from Social Apps'
      }
    ],
    table: [
      {
        id: 0,
        sigla: "INF105",
        nivel: "2",
        materia: "informatica",
        horario: "08:00 - 10:00",
        aula: "L22",
        doncente: "Otsego core",
        estado: "Inscrita"
      },
      {
        id: 1,
        sigla: "MAT240",
        nivel: "2",
        materia: "calculo 1",
        horario: "09:00 - 11:00",
        aula: "S22",
        doncente: "Larry Fivepointville",
        estado: "Inscrita"
      },
      {
        id: 2,
        sigla: "EST552",
        nivel: "2",
        materia: "Estadistica 1",
        horario: "00:00 - 00:00",
        aula: "Q11",
        doncente: "Larry the Bird",
        estado: "Resagada"
      },
      {
        id: 3,
        sigla: "LIN521",
        nivel: "1",
        materia: "Ingles",
        horario: "00:00",
        aula: "L36",
        doncente: "Seaforth rojas",
        estado: "Rechazada"
      }
    ]
  };
  