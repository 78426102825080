import React,{ useState,useEffect , Fragment } from "react";
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { Grid,TextField,Paper,Button,IconButton, Link ,Hidden } from "@material-ui/core";
import Icon from '@material-ui/core/Icon';
// styles
import useStyles from "./styles";
import { withStyles, makeStyles } from '@material-ui/core/styles';
import axios from 'axios'
import { useSnackbar } from 'notistack';
// components
import PageTitle from "../../components/PageTitle/PageTitle";
import Widget from "../../components/Widget/Widget";
import { Typography } from "../../components/Wrappers/Wrappers";
import mock from "../dashboard/mock";
//import Table from "../dashboard/components/Table/TableArea";
import Tablep from "../dashboard/components/Table/TableProve";
import Tablect from "../dashboard/components/Table/Tablect";
import Tablemarca from "../dashboard/components/Table/TableMarca";
import classnames from "classnames";
import { DataGrid, RowsProp, ColDef, AutoSizer, getIdFromRowElem } from '@material-ui/data-grid';
//import { DataGrid, esES } from '@mui/x-data-grid';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import {Edit,Delete,PanoramaFishEyeRounded,PrintIcon} from '@material-ui/icons';
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
import FaceIcon from '@material-ui/icons/Face';
import DoneIcon from '@material-ui/icons/Done';
import {Modal,ModalBody,ModalFooter,ModalHeader, UncontrolledDropdown} from "reactstrap"
import { useAlert } from "react-alert";
import MiTable from "./DataTable";
import MiTableArea from "./DataTableArea";

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead'; 
import TableRow from '@material-ui/core/TableRow';

import { getDatalist ,getArea,getProveedor} from "./Controller";
import { SettingsSystemDaydreamTwoTone } from "@material-ui/icons";
import BtcArea from "../../components/BtcArea/BtcArea";
import BtcProveedor from "../../components/BtcProveedor/BtcProveedor";
import Constantes from "../../context/Constates";

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

import "react-datepicker/dist/react-datepicker.css";


const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const museStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    zIndex: 500,
    '& > *': {
      margin: theme.spacing(1),
    },
    '& .super-app-theme--cell': {
      backgroundColor: 'rgba(224, 183, 60, 0.55)',
      color: '#1a3e72',
      fontWeight: '600',
    },
    '& .super-app.negative': {
      backgroundColor: '#d90007',
      color: '#FFFFFF',
      fontWeight: '600',
    },
    '& .super-app.positive': {
      backgroundColor: '#d47483',
      color: '#1a3e72',
      fontWeight: '600',
    },
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  large: {
    width: theme.spacing(15),
    height: theme.spacing(15),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#008000',
  },
  
}));
var ltareas=[];
var ltprov=[];
var ltmarcas=[];
var ltctmark=[];
var ContextUser={};

var tol=0;




export default function MateriasPage() {
  let history = useHistory();
  const location = useLocation();
  const alert = useAlert();
  const [fechahoy, setfechahoy] = React.useState("");

  ltareas=JSON.parse(localStorage.getItem('ltareas'));
  ltprov=JSON.parse(localStorage.getItem('ltprov'));
  ltmarcas=JSON.parse(localStorage.getItem('ltmarcas'));
  ltctmark=JSON.parse(localStorage.getItem('ltctmarks'));
  ContextUser=JSON.parse(localStorage.getItem('user'));
  var classes = useStyles();
  var mSolicitud={};
  var dataRecibido={};

  const mclasses = museStyles();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  useEffect(()=>{
    setstateDescripcion(false);
    getDatalist();
    disablePastDate();
    console.log(window.location.origin);
    var idproces=location.pathname.substring(15, location.pathname.length);
    if (idproces!=null && idproces>0){
      dataRecibido.procesoId=idproces;
      obtSolicitud()
      setloading(true);
      setSoloLectura(true);
      console.log("son proceso")
    }else{
      if (location.state!=null) {
        dataRecibido=location.state.dtsolicitud;
        if (dataRecibido.procesoId>0) {
          obtSolicitud()
          setloading(true);
          if (parseInt(ContextUser.personalId)==13) {

          }
          if (dataRecibido.actividadId ==Constantes.SOLICITUDDEAPROBACIONDECOMPRA || dataRecibido.actividadId ==Constantes.SOLICITUDDEAPROBACIONDECOMPRAGG) {
            setbtnEncabezado("pendiente")
          }
          else{
            setbtnEncabezado("continuar")
          }
        }
      }
    }
    //GetPersonaAreas();
  },[location])


  const [mdataItm, setmdataItm] = React.useState([]);
  const [mImagenes, setmImagenes] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [mdata, setmdata] = React.useState([]);
  const [mcolumns, setmcolumns] = React.useState([]);
  const [nameModal, setnameModal] = React.useState("");
  const [type,settype]=React.useState("");
  const [loadarea, setloadarea] = React.useState({});
  const [solicitud, setsolicitud] = React.useState({});
  const [loadprov, setloadprov] = React.useState(0);
  const [pricetotal, setpricetotal] = React.useState(0);
  const TAX_RATE = 0.07;
  const [modalins, setmodalins] = React.useState(false);
  const [selectedDate, setSelectedDate] = React.useState(new Date('2014-08-18T21:11:54'));
  const [mdescripcion, setmdescripcion] = React.useState("");
  const [dataDescripcion, setdataDescripcion] = React.useState("");
  const [dataCodigoprd, setdataCodigoprd] = React.useState("");
  const [stateDescripcion, setstateDescripcion] = React.useState(false);
  const [loading,setloading]=React.useState(false);
  const [statearea, setstatearea] = React.useState(false);
  const [dataCuenta, setdataCuenta] = React.useState(0);
  const [stateCuenta, setstateCuenta] = React.useState(false);
  const [dataMarca, setdataMarca] = React.useState(0);
  const [stateMarca, setstateMarca] = React.useState(false);
  const [mcomentario, setmcomentario] = React.useState("");
  const [cuenta, setcuenta] = React.useState(0);
  const [marca, setmarca] = React.useState(0);
  const [mfile, setmfile] = React.useState(0);
  const [mListaComentario, setmListaComentario] = React.useState(null);
  const [editable, seteditable] = useState(true);
  const [btnEncabezado, setbtnEncabezado] = useState("nuevo");
  const [textbreveit, settextbreveit] = useState("");
  const [fechait, setfechait] = useState("");
  const [precioit, setprecioit] = useState(0);
  const [cantidadit, setcantidadit] = useState(0);
  const [idit, setidit] = useState(0);
  const [modaledit, setmodaledit] = React.useState(false);
  var [isLoading, setIsLoading] = useState(false);
  const [SoloLectura, setSoloLectura] = useState(false);



  const[mitem,setmitem]=React.useState({
    SolCompraId: 0,
    solCompraDetalleId: 0,
    solCompraDetalleTextoBreve:'',
    solCompraDetalleFechaEntregaStr:'',
    solCompraDetalleFechaEntrega:null,
    solCompraDetallePrecioUnitario:0,
    solCompraDetalleCantidad:'',
    solCompraDetalleTotal:0,
    tipoEstado:1
})
const[image,setimage]=React.useState({
  id: 0,
  file: null,
  nombre:'',
  size:""
})

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

var configjson = {
  headers: {'Content-Type': 'application/json' , 'Authorization': 'Bearer '+localStorage.getItem('id_token')}
};
  /*rutas */
  var voidSolicitus=Constantes.URLSERVER+Constantes.APIPOSTSOLICITUD
  var voidsenimages=Constantes.URLSERVER+Constantes.APIPOSTFILE
  var getSolicitud=Constantes.URLSERVER+Constantes.APIGETSOLICITUD
  var getDocumento=Constantes.URLSERVER+Constantes.APIGETDOCUMENTO
  var voidSolicitudEstado=Constantes.URLSERVER+Constantes.APIVOIDAPRORECH
  var lreporte=Constantes.URLSERVER+Constantes.APIGETREPORTE


  const handleChange=e=>{
    const { name, value}=e.target;
    setmitem({
        ...mitem,
        [name]:value
    })
  }



  const handleChangeImage=e=>{
    //setmfile(URL.createObjectURL(event.target.files[0]))
    var cfile={};

    cfile.id=mImagenes.length+1;
   // cfile.files=e.dataTransfer.files[0]
   var mfile=e.target.files[0];
    cfile.files=mfile;
    cfile.file=URL.createObjectURL(e.target.files[0]);
    cfile.nombre=e.target.files[0].name;
    cfile.size=formatBytes(e.target.files[0].size);
    //console.log(cfile);
    setimage(e.target)
    setmImagenes(mImagenes.concat(cfile));
  }
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const accionesModal=()=>{
    disablePastDate();
    setmodalins(!modalins)
  }
  const accionesModalED=()=>{
    setmodaledit(!modaledit)
  }
  function ccyFormat(num) {
    return `${num.toFixed(3)}`;
  }

  function priceRow(qty, unit) {
    return qty * unit;
  }

  function createRow(solCompraDetalleId, solCompraDetalleTextoBreve,solCompraDetalleFechaEntregaStr, solCompraDetallePrecioUnitario,solCompraDetalleCantidad) {
    const solCompraDetalleTotal = priceRow(solCompraDetallePrecioUnitario, solCompraDetalleCantidad);
    var solCompraDetalleFechaEntregaStr=format(solCompraDetalleFechaEntregaStr);
    return { solCompraDetalleId, solCompraDetalleTextoBreve, solCompraDetalleFechaEntregaStr, solCompraDetallePrecioUnitario,solCompraDetalleCantidad,solCompraDetalleTotal };
  }


  var rows = [];

  const voidItem=async()=>{
    var sw=true;
    if(mitem && mitem.solCompraDetalleTextoBreve=='' ){
      sw=false;
    }
    if(mitem && mitem.solCompraDetalleCantidad<=0 ){
      sw=false;
    }
    if(mitem && mitem.solCompraDetallePrecioUnitario<=0 ){
      sw=false;
    }
    if(sw){
      setIsLoading(true);
      mitem.solCompraDetalleId=parseInt(mitem.solCompraDetalleId);
      mitem.solCompraDetallePrecioUnitario=parseInt(mitem.solCompraDetallePrecioUnitario);
      mitem.solCompraDetalleCantidad=parseInt(mitem.solCompraDetalleCantidad);
      var rid=mdataItm.length+1;
      var mit=createRow(rid,mitem.solCompraDetalleTextoBreve,mitem.solCompraDetalleFechaEntregaStr,mitem.solCompraDetallePrecioUnitario,mitem.solCompraDetalleCantidad);
      setmdataItm(mdataItm.concat(mit));
      rows=mdataItm;
      var stotal=0;
      console.log(rows);
      mdataItm.map(dt => {
        stotal=stotal+dt.solCompraDetalleTotal
      });
      stotal=stotal+mit.solCompraDetalleTotal;
      setpricetotal(stotal);
      accionesModal();
      setIsLoading(false);
      mitem.solCompraDetalleId=0;
      mitem.solCompraDetallePrecioUnitario=0;
      mitem.solCompraDetalleCantidad=0;
      mitem.solCompraDetalleTextoBreve='';
      mitem.solCompraDetalleFechaEntregaStr='';
    }else{
      alert.show("Campor obligatorio!!");
    }


}


  var data;

  function modalArea(){
    settype("1");
    data=getArea();
    setmdata(data.rows);
    setmcolumns(data.columns);
    //setnameModal("Seleccione Area");
    setOpen(true);
  }

  function modalProveedor(){
    data=getProveedor();
    setmdata(data.rows);
    setmcolumns(data.columns);
    setnameModal("Seleccione Proveedor");
    settype("2");
    setOpen(true);
  }

  function loadData(data){
    var obj_sel={};
    mdata.map(df=>{
      if(data==df.id){
        obj_sel=df;
      }
    })
    if(type=="1"){
      setloadarea(obj_sel);
      setOpen(false);
    }else if(type=="2"){
      if (obj_sel.estado=='Activo') {
        setloadprov(obj_sel);
        setOpen(false);
      }
    }
  }
  const clickhandler = id => loadData(id);
  
  const onMeetingTitleClick = (data) => {
    console.log("entro clik"+ data);
    loadData(data);
  }
  const onRowClicked = row => { onMeetingTitleClick(row); };

  function format(inputDate) {

    var year = inputDate.substring(0,4);
    var month = inputDate.substring(5,7);
    var day = inputDate.substring(8,10);
    var someFormattedDate = day + '/' + month + '/' + year;
    return someFormattedDate;
    /*var date = new Date(inputDate);
    if (!isNaN(date.getTime())) {
        // Months use 0 index.
        return date.getDate() + '/' +date.getMonth() + 1 + '/' + date.getFullYear();
    }*/
}

  const obtSolicitud=async()=>{
    try {
      var data={};
      var urlservice=getSolicitud+"ProcesoId="+dataRecibido.procesoId;
      const resp = await axios.get(urlservice,configjson);
      var result = resp.data;
      if(result.codigoRespuesta==0){
          var rows=result.valorRespuesta;
          var dcomentario=rows.solicitudCompra.solCompraDescripcion;
          setsolicitud(rows.solicitudCompra);
          setdataDescripcion(dcomentario);
          setdataCodigoprd(rows.solicitudCompra.proceso.procesoCodigo)
          setstateDescripcion(true);
          setstatearea(true);
          var darea=rows.solicitudCompra.jtI_64_Area;
          setloadarea(darea);
          console.log(darea);
          var dprov=rows.solicitudCompra.proveedor;
          setloadprov(dprov);
          var dmarca=rows.solicitudCompra.marcaId;
          var dcuenta=rows.solicitudCompra.cuentasId;
         // setdataCuenta(dcuenta);
          setcuenta(dcuenta)
          //setdataMarca(dmarca);
          setmarca(dmarca);
          setstateCuenta(true);
          setstateMarca(true);
          var ddetalle=rows.solicitudCompra.listaSolicitudCompraDetalle;
          setmdataItm(ddetalle);
          var dtotal=rows.solicitudCompra.solCompraTotal;
          setpricetotal(dtotal);
          seteditable(false);
          if(rows.solicitudCompra.proceso.procesoEstado=='FINALIZADA'){
            setbtnEncabezado('FINALIZADA');
          }
          var dDocumentos=rows.listaDocumento;
          var prev_image=[];
          dDocumentos.map(docs => {
            var cfile={};
            cfile.id=mImagenes.length+1;
            var urlimage=getDocumento+"filename="+docs.documentoNombre;
            var objectURL = urlimage;


            cfile.file=objectURL;
            cfile.nombre=docs.documentoNombre;
            cfile.size=formatBytes(urlimage);
            console.log(urlimage);
            prev_image=prev_image.concat(cfile)
          //  setmImagenes(mImagenes.concat(cfile));
          });
          setmImagenes(prev_image);

          var dComentarios=rows.solicitudCompra.proceso.listaTareas;
          var contenido=(
            <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="spanning table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Personal</StyledTableCell>
                    <StyledTableCell >Comentario</StyledTableCell>
                    <StyledTableCell >Actividad</StyledTableCell>
                    <StyledTableCell >Fecha Asignacion</StyledTableCell>
                    <StyledTableCell >Fecha Ejecutada</StyledTableCell>
                    <StyledTableCell >Dias</StyledTableCell>
                  </TableRow>
                </TableHead>
                  <TableBody>
                    {dComentarios.map((row) => (
                      <TableRow key={row.item}>
                        <TableCell>{row.personalNombre}</TableCell>
                        <TableCell>{row.comentario}</TableCell>
                        <TableCell>{row.actividadNombre}</TableCell>
                        <TableCell>{row.tareaFechaAsignacionStr}</TableCell>
                        <TableCell>{row.tareaFechaEjecucionStr}</TableCell>
                        <TableCell>{row.dias}</TableCell>
                      </TableRow>
                    ))}

                  </TableBody>
                </Table>
              </TableContainer>
          );
          setmListaComentario(contenido);
          setloading(false);
      }else{
        alert.show(result.mensajeRespuesta);
      }
      return data;
    } catch (err) {
        console.error(err);
    }
  }



  const handleClose = () => {
    setOpen(false);
  };

  const body = (
      <Grid item xs={12}>
        <Widget title={nameModal}  upperTitle noBodyPadding disableWidgetMenu={true} bodyClass={classes.tableOverflow}>
          <div style={{ height: 380 , padding:0}} >
            <DataGrid
            // localeText={esES.props.MuiDataGrid.localeText}
            
            rows={mdata}
            columns={mcolumns}
            pageSize={5}
            showToolbar={true} 
            autoHeight 
            onRowSelected={(newSelection) => {
            loadData(newSelection.data);
            }}  />
          </div>
        </Widget>
      </Grid>
  );

  /*Peticiones Servicio */
  const voidSolicitud=async()=>{
    console.log(validar());
    if(validar()){
      try {
        setloading(true);
        var data={};
        data.TipoEstado=1; ///insertar
        data.solCompraId=0;
        data.procesoId=0;
        data.personalId=parseInt(ContextUser.personalId);
        data.proveedorId=parseInt(loadprov.id);
        data.marcaId=parseInt(marca);
        data.cuentasId=parseInt(cuenta);
        data.areaId=parseInt(loadarea.id);
        data.solCompraDescripcion=mdescripcion;
        data.solCompraTienePresupuesto=1;
        data.solCompraDescripcionGF="";
        data.solCompraMotivoRechazo="";
        data.solCompraPPOComents="";
        data.solCompraTotal=parseInt(pricetotal);
        data.comentario=mcomentario;
        data.ListaSolicitudCompraDetalle=mdataItm;
        data.SolCompraUID=uuid();

        const json = JSON.stringify(data);
        console.log(json);

        const resp = await axios.post(voidSolicitus,json,configjson);
        var result = resp.data;
        if(result.codigoRespuesta==0){
          data=result.valorRespuesta;
          mSolicitud=data;
          setsolicitud(data);
          voidSendImage();
        }else{
          alert.show(result.mensajeRespuesta);
          setloading(false);
        }

        return data;
      } catch (err) {
          console.error(err);
      }
    }else{
      alert.error("Falta llenar datos!");
      //alert("Falta rellenar datos");
    }

}

const voidEstadoSolicitud=async(estado)=>{
  if(true){
    try {
      var data={};
      //data.TipoEstado=1; ///insertar
      setloading(true);
      data.estado=parseInt(estado);
      data.procesoId=solicitud.procesoId;
      data.personalId=parseInt(ContextUser.personalId);
      data.comentario=mcomentario;
      console.log(data);
      console.log(solicitud);
      const json = JSON.stringify(data);

      const resp = await axios.post(voidSolicitudEstado,json,configjson);
      var result = resp.data;
      if(result.codigoRespuesta==0){
        data=result.valorRespuesta;
        var msj='Cod: '+data.procesoCodigo;
        if (estado==1){
          msj=msj+' Solicitud aprobada..';
        }else if(estado ==2){
          msj=msj+' Solicitud rechazada..';
        }else if(estado==3){
          msj=msj+' Solicitud terminada..';
        }
        alert.success(msj, {timeout: 0   });
        setloading(false);
        handleClickVariant(msj,'success')
        history.push({
          pathname: '../../app/dashboard',
          state: { dtsolicitud: mSolicitud }
        });
      }else{
        setloading(false);
        alert.show(result.mensajeRespuesta);
      }
      return data;
    } catch (err) {
        console.error(err);
    }
  }else{
    console.log("no");
  }
}
const voidPrintOC=async()=>{
  if(solicitud!=null){
    var urlfile =lreporte+"ProcesoId="+solicitud.procesoId;
    var win = window.open(urlfile, '_blank');
  win.focus();
    // axios({
    //   url: urlfile,
    //   method: 'GET',
    //   headers: {
    //     'Content-Type': 'application/pdf',
    //   },
    //   responseType: 'blob', // important
    // }).then((response) => {
    //   const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
    //   const link = document.createElement('a');
    //   link.href = url;
    //   //link.setAttribute('download', 'file.pdf');
    //   link.setAttribute('target', '_blank');
    //   //link.target="target";
    //   document.body.appendChild(link);
    //   link.click();
    // });
  }

}
const voidPrintOCDW=async()=>{
  var urlfile =lreporte+"ProcesoId="+solicitud.procesoId;
  axios({
    url: urlfile,
    method: 'GET',
    headers: {
      'Content-Type': 'application/pdf',
    },
    responseType: 'blob', // important
  }).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
    const link = document.createElement('a');
    link.href = url;
    //link.setAttribute('download', 'file.pdf');
    link.setAttribute('target', '_blank');
    //link.target="target";
    document.body.appendChild(link);
    link.click();
  });
}

const voidSendImage=async()=>{
  try {
    var configimage = {
      headers: {'Content-Type': 'multipart/form-data', 'Authorization': 'Bearer '+localStorage.getItem('id_token')},
    };
    var formData = new FormData();
    mImagenes.map(dt => {
      formData.append("files", dt.files);
    });
    var urlservice=voidsenimages+"?ProcesoId="+mSolicitud.procesoId;
      const resp = await axios.post(urlservice,formData,configimage);
      var result = resp.data;
      if(result.codigoRespuesta==0){
        var msj='Cod: '+mSolicitud.proceso.procesoCodigo+' Solicitud enviada..';
        alert.success(msj, {timeout: 0   });
        setloading(false);
        handleClickVariant(msj,'success')
        history.push({
          pathname: '../../app/dashboard',
          state: { dtsolicitud: mSolicitud }
        });
      }else{
        setloading(false);
        alert.show(result.mensajeRespuesta);
      }
      return data;
    } catch (err) {
        console.error(err);
    }
}

  /*end peticiones*/
  /*funciones */
  function uuid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }
  function formatBytes(a,b=2){if(0===a)return"0 Bytes";const c=0>b?0:b,d=Math.floor(Math.log(a)/Math.log(1024));return parseFloat((a/Math.pow(1024,d)).toFixed(c))+" "+["Bytes","KB","MB","GB","TB","PB","EB","ZB","YB"][d]}

  function validar(){
    var sw=true;
    if (mdescripcion && mdescripcion=="") {
      sw=false;
      alert.show("El campo descripcion es obligatorio!!");
    }
    if(loadprov==0 || loadprov==null || loadprov.length<=0){
      sw=false;
      alert.show("Debe seleccionar un proveedor!!");
    }
    if(loadarea==0 || loadarea==null || loadarea.length<=0){
      sw=false;
      alert.show("Debe seleccionar un area!!");
    }
    if(pricetotal==0 || pricetotal==null || pricetotal.length<=0){
      sw=false;
      alert.show("Debe agregar un Detalle!!");
    }
    if(mImagenes==null || mImagenes.length<=0){
      sw=false;
      alert.show("ES OBLIGATORIO ADJUNTAR AL MENOS 1 ARCHIVO");
    }
      return sw;
    }

    const handleChangedescripcion = (event) => {
      setmdescripcion(event.target.value);
     };
    const handleChangemarc = (event) => {
      setmarca(event.target.value);
      //setdataMarca(event.target.value);
     };
     const handleChangecuenta = (event) => {
      setcuenta(event.target.value);
     };
     const handleChangecomentario = (event) => {
      setmcomentario(event.target.value);
     };

     const delimage= (data) =>{
      var index = mImagenes.indexOf(data);
      if (index > -1) {
        mImagenes.splice(index, 1);
        setmImagenes(mImagenes);
      }
      console.log(mImagenes);
     }

     const delitem= (opt,data) =>{
       if(opt==1){
        var index = mdataItm.indexOf(data);
        if (index > -1) {
          mdataItm.splice(index, 1);
          setmImagenes(mdataItm);
        }
       }else{
        var index = mImagenes.indexOf(data);
        if (index > -1) {
          mImagenes.splice(index, 1);
          var temp = mImagenes;
          setmImagenes([])
          setTimeout(() => {
            setmImagenes(temp);
          }, 1000);
        }
       // console.log(mImagenes);
       }

     }
     const edititem=(data)=>{
      console.log(data);
      settextbreveit(data.solCompraDetalleTextoBreve);
      setfechait(data.solCompraDetalleFechaEntregaStr);
      setprecioit(data.solCompraDetallePrecioUnitario);
      setcantidadit(data.solCompraDetalleCantidad);
      setidit(data.solCompraDetalleId);
      accionesModalED();
     }

     const confimacion = (opt,data) => {
      //delitem(opt,data);
       confirmAlert({
         //title: 'Desea eliminar este elemento?',
         message: 'Desea eliminar este elemento?',
         buttons: [
           {
             label: 'Si',
             onClick: () => delitem(opt,data)
           },
           {
             label: 'No',
            onClick: () => {}
           }
         ]
       });
    };

     const modificaritem=()=>{
      var sw=true;
      if(textbreveit=='' ){
        sw=false;
      }
      if(precioit<=0 ){
        sw=false;
      }
      if(cantidadit<=0 ){
        sw=false;
      }
      if(sw){

        rows=mdataItm;
        var stotal=0;
        console.log(rows);
        mdataItm.map(dt => {
          if(idit==dt.solCompraDetalleId){
            dt.solCompraDetalleCantidad=cantidadit;
            dt.solCompraDetallePrecioUnitario=precioit;
            dt.solCompraDetalleTextoBreve=textbreveit;
            dt.solCompraDetalleTotal=cantidadit*precioit;
          }
          stotal=stotal+dt.solCompraDetalleTotal
        });
        //stotal=stotal+mit.solCompraDetalleTotal;
        setpricetotal(stotal);
        accionesModalED();

      }else{
        alert.show("Campor obligatorio!!");
      }
     }

     const handleClickVariant = (variant) => () => {
      // variant could be success, error, warning, info, or default
      var msj='Cod: '+mSolicitud.procesoId+' Solicitud enviada..';
      enqueueSnackbar(msj, { variant });
    };

    function disablePastDate ()  {
      const today = new Date();
      const dd = String(today.getDate() + 1).padStart(2, "0");
      const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      const yyyy = today.getFullYear();
      var datehoy=  yyyy+ "-" + mm + "-"+dd ;
      setfechahoy(datehoy);
    };
    const loadDataTab=(urlin)=>{
      var urlimage=getDocumento+"filename="+urlin;
      window.open(urlimage, "_blank");
      //window.focus();

     /* $.get(urlin, function (data) {
        var data = [data];
        var blob = new Blob(data, {
            type: 'application/javascript'
        });
        url = window.URL.createObjectURL(blob);
        window.open(url, '_blank');
      }).fail(function () {});*/
    }
  /*end funciones*/
  return (
    <>
      <Grid container spacing={6}>
      <Grid item xs={12} md={12}  style={{paddingBottom: 1}}>

        {!SoloLectura && btnEncabezado=="nuevo" && <Widget  title="Opciones" disableWidgetMenu>
              <Button
                variant="contained"
                color="primary"
                onClick={()=>voidSolicitud()}
                className={classes.button}
                endIcon={<Icon>send</Icon>}>
                Enviar
              </Button>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => history.goBack()}
                className={classes.button}
                endIcon={<Icon>cancel_schedule_send</Icon>}>
                Cancelar
              </Button>

              </Widget>}
        {!SoloLectura && btnEncabezado=="continuar" && <Widget  title="Opciones" disableWidgetMenu>
              <Button
                variant="contained"
                color="primary"
                onClick={()=>voidEstadoSolicitud(3)}
                className={classes.button}
                endIcon={<Icon>navigate_next</Icon>}>
                Continuar
              </Button>

              <Button
                variant="contained"
                color="secondary"
                onClick={() => history.goBack()}
                className={classes.button}
                endIcon={<Icon>cancel_schedule_send</Icon>}>
                Cancelar
              </Button>
              <Button
            variant="contained"
              color="primary"
                onClick={()=>voidPrintOCDW()}
                className={classes.button}
                endIcon={<Icon>print</Icon>}>
                Imprimir
              </Button>
              </Widget>}
        {!SoloLectura && btnEncabezado=="pendiente" && <Widget  title="Opciones" disableWidgetMenu>
              <Button
                variant="contained"
                color="primary"
                onClick={()=>voidEstadoSolicitud(1)}
                className={classes.button}
                endIcon={<Icon>done</Icon>}>
                Aprobar
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={()=>voidEstadoSolicitud(2)}
                className={classes.button}
                endIcon={<Icon>do_disturb</Icon>}>
                Rechazar
              </Button>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => history.goBack()}
                className={classes.button}
                endIcon={<Icon>cancel_schedule_send</Icon>}>
                Cancelar
              </Button>
              </Widget>}
        {!SoloLectura && btnEncabezado=="terminado" && <Widget  title="Opciones" disableWidgetMenu>
          <Button
            variant="contained"
              color="primary"
                onClick={()=>voidPrintOCDW()}
                className={classes.button}
                endIcon={<Icon>print</Icon>}>
                Imprimir
              </Button>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => history.goBack()}
                className={classes.button}
                endIcon={<Icon>cancel_schedule_send</Icon>}>
                Cancelar
              </Button>

        </Widget>}
        {!SoloLectura && btnEncabezado=="FINALIZADA" && <Widget  title="Opciones" disableWidgetMenu>

              <Button
                variant="contained"
                color="secondary"
                onClick={() => history.goBack()}
                className={classes.button}
                endIcon={<Icon>cancel_schedule_send</Icon>}>
                Cerrar
              </Button>

        </Widget>}
        {SoloLectura && <Widget  title="Opciones" disableWidgetMenu>

        <Button
            variant="contained"
              color="primary"
                onClick={()=>voidPrintOCDW()}
                className={classes.button}
                endIcon={<Icon>print</Icon>}>
                Imprimir
              </Button>

        </Widget>}
        </Grid>

        <Grid item xs={12} md={12}  style={{paddingBottom: 1}}>
          <Widget  title="Informacion General" disableWidgetMenu>
            <Grid container spacing={6}>
            <Grid item xs={12} md={6}>
              {editable  &&   <TextField
                id="Descripción"
                name="Descripción"
                InputProps={{
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField,
                  },
                }}
                onChange={handleChangedescripcion}
                margin="normal"
                required
                multiline
                label="Descripción"
                type="text"
                fullWidth
              />}
              {!editable &&
              <div className="form-group">
                <label>Codigo:</label>
                <br/>
                <input id="id" name="id" type="text" readOnly className="form-control" defaultValue={dataCodigoprd}></input>
                <label>Descripción:</label>
                  <br/>
                  <input id="id" name="id" type="text" readOnly className="form-control" defaultValue={dataDescripcion}></input>
              </div>
              }
            </Grid>
            <Grid item xs={12} md={6} >
            {editable &&   <TextField
                  id="Area"
                  InputProps={{
                    classes: {
                      underline: classes.textFieldUnderline,
                      input: classes.textField,
                    },
                  }}
                  onClick={modalArea}
                  margin="normal"
                  required
                  label="Seleccione Area"
                  placeholder="Seleccione Area"
                  type="text"
                  disabled={statearea}
                  fullWidth
                />}
                 <BtcArea area={loadarea} ></BtcArea>
            </Grid>
            </Grid>
          </Widget>
        </Grid>

        <Grid item xs={12} md={16} style={{paddingBottom: 1}}>
          <Widget title="Proveedor Seleccionado" disableWidgetMenu>
              <Grid container spacing={6}>
              {editable &&   <Grid item xs={12} md={12}>
                      <TextField
                      id="Area"
                      InputProps={{
                        classes: {
                          underline: classes.textFieldUnderline,
                          input: classes.textField,
                        },
                      }}
                      onClick={modalProveedor}
                      margin="normal"
                      required
                      placeholder="Seleccione Proveedor"
                      type="text"
                      fullWidth
                    />
                  </Grid>}
                  <Grid item xs={12} md={12}>
                        <BtcProveedor proveedor={loadprov}></BtcProveedor>
                  </Grid>
              </Grid>


          </Widget>
        </Grid>

        <Grid item xs={12} md={16} style={{paddingBottom: 1}}>
          <Widget title="Cuentas Marketing" disableWidgetMenu>
            <Grid container item xs={12}>
            <Grid item xs={6} style={{paddingRight: 25}}>
              <FormControl className={classes.formControl} fullWidth>
                    <InputLabel htmlFor="age-native-simple">Seleccione Marca</InputLabel>
                    <Select
                    native
                    id="Marca"
                    name="Marca"
                    onChange={handleChangemarc}
                    value={marca}
                    disabled={stateMarca}
                    >
                    <option value=""></option>
                    {ltmarcas.map(marca => (
                        <option
                        key={marca.id}
                        value={marca.id}
                        >
                        {marca.marca}
                        </option>
                    ))}
                    </Select>
                </FormControl>
            </Grid>

            <Grid item xs={6}>
            <FormControl className={classes.formControl} fullWidth>
                    <InputLabel htmlFor="age-native-simple">Seleccione Cuentas</InputLabel>
                    <Select
                    native
                    id="Cuentas"
                    name="Cuentas"
                    onChange={handleChangecuenta}
                    value={cuenta}
                    disabled={stateCuenta}
                    >
                        <option value=""></option>
                    {ltctmark.map(ctmark => (
                        <option
                        key={ctmark.id}
                        value={ctmark.id}
                        >
                        {ctmark.nombre}
                        </option>
                    ))}
                    </Select>
                </FormControl>

              </Grid>
            </Grid>

          </Widget>
        </Grid>

        <Grid item xs={12} md={16} style={{paddingBottom: 1}}>
          <Widget title="Detalle de la Compra" disableWidgetMenu>
          {editable &&   <Button
              variant="contained"
              size="small"
              color="primary"
              className={classes.button}
              onClick={()=>accionesModal()}
              style={{ marginBottom: 15 }}
            >
              Agregar
            </Button>}




              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="spanning table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Item</TableCell>
                      <TableCell align="right">Texto breve.</TableCell>
                      <TableCell align="right">Fecha entrega</TableCell>
                      <TableCell align="right">Precio Unitario</TableCell>
                      <TableCell align="right">Cantidad</TableCell>
                      <TableCell align="right">Total Bs</TableCell>
                      <TableCell align="right"></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {mdataItm.map((row) => (
                      <TableRow key={row.solCompraDetalleId}>
                        <TableCell>{row.solCompraDetalleId}</TableCell>
                        <TableCell>{row.solCompraDetalleTextoBreve}</TableCell>
                        <TableCell align="right">{row.solCompraDetalleFechaEntregaStr}</TableCell>
                        <TableCell align="right">{row.solCompraDetallePrecioUnitario}</TableCell>
                        <TableCell align="right">{row.solCompraDetalleCantidad}</TableCell>
                        <TableCell align="right">{row.solCompraDetalleTotal}</TableCell>
                        {editable && <TableCell  align="right">
                              <IconButton color="primary" onClick={()=>edititem(row)} component="span">
                                <Edit/>
                                </IconButton>
                                <IconButton color="primary" onClick={()=>confimacion(1,row)}  component="span">
                                <Delete/>
                                </IconButton>
                          </TableCell>
                        }


                      </TableRow>
                    ))}

                    <TableRow>
                      <TableCell  />
                      <TableCell  />
                      <TableCell />
                      <TableCell />
                      <TableCell > <strong>Total</strong> </TableCell>
                      <TableCell align="right">{pricetotal}</TableCell>
                      <TableCell />

                    </TableRow>

                  </TableBody>
                </Table>
              </TableContainer>

          </Widget>
        </Grid>

        <Grid item xs={12} md={12}  style={{paddingBottom: 1}}>
          <Widget  title="Documentos" disableWidgetMenu>
          {editable &&  <div id="upload-box">
            <input id="mfile" name="mfile" type="file" onChange={handleChangeImage} />

          </div>}
          <br/>

            <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="spanning table">
                  <TableBody>
                    {mImagenes.map((row) => (
                      <TableRow key={row.id}>
                        <TableCell><Avatar alt="Remy Sharp" src={row.file} className={mclasses.large} /></TableCell>
                        <TableCell><Link onClick={() => loadDataTab(row.nombre)}  color="inherit"> {row.nombre} </Link></TableCell>
                        {editable && <TableCell>{row.size}</TableCell>}
                        {editable && <TableCell  align="right">
                                <IconButton color="primary" onClick={()=>confimacion(2,row)} component="span">
                                <Delete/>
                                </IconButton>
                          </TableCell>}
                      </TableRow>
                    ))}

                  </TableBody>
                </Table>
              </TableContainer>
        </Widget>
        </Grid>

        <Grid item xs={12} md={12}  style={{paddingBottom: 1}}>
          <Widget  title="Historial de Proceso" disableWidgetMenu>
             <TextField
                id="comentario"
                name="comentario"
                InputProps={{
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField,
                  },
                }}
                onChange={handleChangecomentario}
                Width="50%"
                margin="normal"
                required
                multiline
                placeholder="Comentario"
                type="text"
                fullWidth
              />
              <br/>
              <br/>
              {mListaComentario}
        </Widget>
        </Grid>
        <br/>
        <br/>

      </Grid>



      <Modal
        isOpen={open}
        centered
        zIndex={5000}
        size={'lg'}
      >
        <ModalBody>
        {type=="1" && <MiTableArea data={mdata} click={clickhandler} onRowClicked={onRowClicked}/>}
        {type=="2" && <MiTable data={mdata}  click={clickhandler} onRowClicked={onRowClicked}  />}
        {/* {body} */}
        </ModalBody>
        <ModalFooter>
            <Button className="btn btn-danger" onClick={()=>handleClose()} >Cerrar</Button>
        </ModalFooter>
      </Modal>


      <Modal
        isOpen={modalins}
        paddingTop={30}
        centered
        zIndex={9000}
        size={'lg'}
      >
        <ModalBody>
        <Grid container spacing={6}>
            <Grid item xs={12} md={12}>
              <TextField
                  multiline
                  id="solCompraDetalleTextoBreve"
                  name="solCompraDetalleTextoBreve"
                  InputProps={{
                    classes: {
                      underline: classes.textFieldUnderline,
                      input: classes.textField,
                    },
                  }}
                  onChange={handleChange}
                  label="Texto Breve"
                  margin="normal"
                  required
                  type="text"
                  fullWidth
                />

                <TextField
                  id="solCompraDetalleFechaEntregaStr"
                  name="solCompraDetalleFechaEntregaStr"
                  onChange={handleChange}
                  label="Fecha de Entrega"
                  margin="normal"
                  required
                  type="date"
                  format={'dd/mm/YYYY'}
                  inputProps={{
                    min: fechahoy
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  fullWidth
                />

                <TextField
                  id="solCompraDetallePrecioUnitario"
                  name="solCompraDetallePrecioUnitario"
                  InputProps={{
                    classes: {
                      underline: classes.textFieldUnderline,
                      input: classes.textField,
                    },
                  }}
                  onChange={handleChange}
                  label="Precio"
                  defaultValue={0}
                  margin="normal"
                  required
                  type="number"
                  fullWidth
                />
                <TextField
                  id="solCompraDetalleCantidad"
                  name="solCompraDetalleCantidad"
                  InputProps={{
                    classes: {
                      underline: classes.textFieldUnderline,
                      input: classes.textField,
                    },
                  }}
                  defaultValue={0}
                  onChange={handleChange}
                  label="Cantidad"
                  margin="normal"
                  required
                  type="number"
                  fullWidth
                />

            </Grid>
        </Grid>
        </ModalBody>
        <ModalFooter>
        <Grid >
            <Button
              variant="contained"
              color="success"
              size="small"
              onClick={()=>accionesModal()}
              style={{ marginLeft: 16 ,color:"#FFFFFF" }}
            >
              Cerrar
            </Button>
            {isLoading ? (
                  <CircularProgress size={26} className={classes.loginLoader} />
              ) : (
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={()=>voidItem()}
              style={{ marginLeft: 16 ,color:"#FFFFFF" }}
            >
              Agregar
            </Button>
            )}
        </Grid>
        </ModalFooter>
      </Modal>

              <Modal
                isOpen={modaledit}
                paddingTop={30}
                centered
                zIndex={9000}
                size={'lg'}
              >
                <ModalHeader>
                    Modificar
                </ModalHeader>
                <ModalBody>
                    <Grid container spacing={6}  xs={12} md={12}>
                      <Grid item xs={12} md={12}>
                      <TextField
                          InputProps={{
                            classes: {
                              underline: classes.textFieldUnderline,
                              input: classes.textField,
                            },
                          }}
                          hidden="true"
                          value={idit}
                          onChange={e => setidit(e.target.value)}
                          label="Texto Breve"
                          margin="normal"
                          required
                          type="number"
                          fullWidth
                        />
                      <TextField
                          InputProps={{
                            classes: {
                              underline: classes.textFieldUnderline,
                              input: classes.textField,
                            },
                          }}
                          value={textbreveit}
                          onChange={e => settextbreveit(e.target.value)}
                          label="Texto Breve"
                          margin="normal"
                          required
                          type="text"
                          fullWidth
                        />
                      <TextField
                          InputProps={{
                            classes: {
                              underline: classes.textFieldUnderline,
                              input: classes.textField,
                            },
                          }}
                          value={fechait}
                          onChange={e => setfechait(e.target.value)}
                          label="Fecha de entrega"
                          margin="normal"
                          required
                          type="date"
                          inputProps={{
                            min: fechahoy
                          }}
                          format={'dd/mm/YYYY'}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          fullWidth
                        />
                        <TextField
                          InputProps={{
                            classes: {
                              underline: classes.textFieldUnderline,
                              input: classes.textField,
                            },
                          }}
                          value={precioit}
                          onChange={e => setprecioit(e.target.value)}
                          label="Precio"
                          margin="normal"
                          required
                          type="number"
                          fullWidth
                        />
                        <TextField
                          InputProps={{
                            classes: {
                              underline: classes.textFieldUnderline,
                              input: classes.textField,
                            },
                          }}
                          value={cantidadit}
                          onChange={e => setcantidadit(e.target.value)}
                          label="Cantidad"
                          margin="normal"
                          required
                          type="number"
                          fullWidth
                        />
                      </Grid>
                      </Grid>

                </ModalBody>
                <ModalFooter>
                <Grid >

                    <Button
                      variant="contained"
                      color="success"
                      size="small"
                      onClick={()=>accionesModalED()}
                      style={{ marginLeft: 16 ,color:"#FFFFFF" }}
                    >
                      Cancelar
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      onClick={()=>modificaritem()}
                      style={{ marginLeft: 16,color:"#FFFFFF"  }}
                    >
                      Cambiar
                    </Button>
                </Grid>
                </ModalFooter>
              </Modal>

      <Backdrop className={mclasses.backdrop} open={loading} >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}
