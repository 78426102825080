import React ,{useState, useEffect}from "react";
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { Grid,TextField,Paper,Button,IconButton } from "@material-ui/core";
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Icon from '@material-ui/core/Icon';
import {Modal,ModalBody,ModalFooter,ModalHeader} from "reactstrap"
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import PrintIcon from '@material-ui/icons/Print';
import VisibilityIcon from '@material-ui/icons/Visibility';
// styles
import useStyles from "./styles";
import "bootstrap/dist/css/bootstrap.min.css";
// components
import PageTitle from "../../components/PageTitle/PageTitle";
import Widget from "../../components/Widget/Widget";
import { Typography } from "../../components/Wrappers/Wrappers";
import mock from "../dashboard/mock";
import classnames from "classnames";
import {
  DataGrid,
  ColDef,
  ValueGetterParams,
  CellParams,
  GridApi
} from "@material-ui/data-grid";
import Constantes from "../../context/Constates";
import axios from 'axios'
import {Edit,Delete,PanoramaFishEyeRounded} from '@material-ui/icons';
import ReactExport from "react-export-excel";
import { SettingsSystemDaydreamTwoTone } from "@material-ui/icons";


export default function GestionCambioPage() {
  const museStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      '& > *': {
        margin: theme.spacing(1),
      },
      '& .super-app-theme--cell': {
        backgroundColor: 'rgba(224, 183, 60, 0.55)',
        color: '#1a3e72',
        fontWeight: '600',
      },
      '& .super-app.negative': {
        backgroundColor: '#d90007',
        color: '#FFFFFF',
        fontWeight: '600',
      },
      '& .super-app.positive': {
        backgroundColor: '#d47483',
        color: '#1a3e72',
        fontWeight: '600',
      },
    },
    small: {
      width: theme.spacing(3),
      height: theme.spacing(3),
    },
    large: {
      width: theme.spacing(15),
      height: theme.spacing(15),
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#008000',
    },
   
  }));
    let history = useHistory();
    const location = useLocation();
    var classes = useStyles();
  const mclasses = museStyles();
    const [mdata, setmdata] = React.useState([]);
    const [mcolumns, setmcolumns] = React.useState([]);
    const [muser, setuser] = React.useState([]);
    const [loading,setloading]=React.useState(false);
    var dataRecibido={};
    var Usuario=JSON.parse(localStorage.getItem('user'));

    var lSeguimiento=Constantes.URLSERVER+Constantes.APIGETLISTCAMBIOS
    var voidanular=Constantes.URLSERVER+Constantes.APIPOSTANULARPROCESO
    var lstSeguimiento=[];
    var config = {
        headers: {'Access-Control-Allow-Origin': '*' , 'Authorization': 'Bearer '+localStorage.getItem('id_token')}
    };

    useEffect(()=>{
      if (location.state!=null) {
        dataRecibido=location.state.user;
        var iduser=Usuario.personalId;
        if (dataRecibido.personalId>0) {
          iduser=dataRecibido.personalId;
        }
        setuser(iduser);
        lSeguimiento=lSeguimiento+"?PersonalId="+iduser
      }
      //GetPersonaAreas();
    },[location])
    
    const ExcelFile = ReactExport.ExcelFile;
    const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
    const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

    /*datagrid */
    
    /*Solicitud */
    const[solselc,setsolselc]=useState({
        id: 0,
        procesoCodigo:'',
        solicitante:'',
        fechaSolicitud:'',
        estado:0,
        personalAsignado:'',
        personalIdGM:0,
        generalManager:'',
        personalIDRegionalCFO:0,
        regionalCFO:'',
        personalIDRegionalPA:0,
        regionalPresidentAmericas:'',
        position:'',
        tipoEstado:0
    })
    /*funciones extras modal */
    
    /*handles*/
    

    
    /*Peticiones*/ 
    const methodanular=async(dt)=>{
      try {
        var data={};
        var proceso={}
          proceso.ProcesoId =dt.procesoId;
          proceso.PersonalId=Usuario.personalId;
          const json = JSON.stringify(proceso);
          console.log(json);
          const resp = await axios.post(voidanular,json,config);
          var result = resp.data;
          if(result.codigoRespuesta==0){
            GetSolicitudes();
             
          }else{
              
          }
          return data;
        } catch (err) {
            console.error(err);
        }
  }
    const GetSolicitudes=async()=>{
        try {
            var data={};
            setloading(true);
            const resp = await axios.get(lSeguimiento,config);
            var result = resp.data;
            if(result.codigoRespuesta==0){
                var columns=[
                       { field: 'id', headerName: '#', width: 80 },
                       { field: 'area', headerName: 'Area', width: 120 },
                       { field: 'fechaStr', headerName: 'Fecha Solicitud', width: 180 },
                       { field: 'version', headerName: 'Version Sistema', width: 180 },
                       { field: 'aplicacion', headerName: 'Aplicacion', width: 180 },
                       { field: 'descripcion', headerName: 'Descripcion', width: 250 },
                       { field: 'requeridoPor', headerName: 'Solicitante', width: 180 },
                     ];
                var rows=result.valorRespuesta;
                
                lstSeguimiento=rows;
                setmcolumns(columns);
                setmdata(rows);
            }else{
                
            }
            setloading(false);
            return data;
          } catch (err) {
              console.error(err);
          }
    }
    
    /*en peticiones */
    
  function voidAnular(data){
    methodanular(data);
  }
  function viewSolicitud(sol){
    history.push({
      pathname: '../../app/Solcompra',
      state: { dtsolicitud: sol }
    });
  }

    useEffect(()=>{
      GetSolicitudes();
    },[])

    
 
    
   

 
 
  return (
    <>
      <Grid container spacing={6}>
      <Grid item xs={12} md={16} style={{paddingBottom: 1}}>
          <Widget title="Gestion de Cambios"   disableWidgetMenu>
            <br/>
            <br/>
          <div style={{ height: 700 , padding:0}}>
            <DataGrid 
            rows={mdata} 
            columns={mcolumns}  
            pageSize={15}
            columnBuffer={2}
            //showToolbar 
            density="compact"
            /*onRowSelected={(newSelection) => {
              var sol=newSelection.data;
                      history.push({
                        pathname: '../../app/Solcompra',
                        state: { dtsolicitud: sol }
                      });
                      console.log(newSelection.data);
              }}*/
            filterModel={{
              items: [
                { columnField: 'procesoCodigo', operatorValue: 'contains', value: '' },
              ],
            }}
             />
          </div>
              
          </Widget>
        </Grid>
       
     
        <Grid item xs={12} md={16} style={{paddingBottom: 1}}>
        <Widget noWidgetShadow disableWidgetMenu noBodyPadding noHeaderPadding style={{paddingRight: 15}} headerClass={classes.widgetHeader}>
        
        
        </Widget>
        </Grid>
      </Grid>

      <Backdrop className={mclasses.backdrop} open={loading} >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}
