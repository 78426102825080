import React ,{useState, useEffect}from "react";
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Grid,TextField,Paper,Button,IconButton } from "@material-ui/core";
import {Modal,ModalBody,ModalFooter,ModalHeader} from "reactstrap"
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
// styles
import useStyles from "./styles";
import { withStyles, makeStyles } from '@material-ui/core/styles';
import "bootstrap/dist/css/bootstrap.min.css";
// components
import PageTitle from "../../components/PageTitle/PageTitle";
import Widget from "../../components/Widget/Widget";
import { Typography } from "../../components/Wrappers/Wrappers";
import mock from "../dashboard/mock";
import classnames from "classnames";
import {
  DataGrid,
  ColDef,
  ValueGetterParams,
  CellParams,
  GridApi
} from "@material-ui/data-grid";
import Constantes from "../../context/Constates";
import axios from 'axios'
import {Edit,Delete,PanoramaFishEyeRounded} from '@material-ui/icons';

import { SettingsSystemDaydreamTwoTone } from "@material-ui/icons";
const museStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
    '& .super-app-theme--cell': {
      backgroundColor: 'rgba(224, 183, 60, 0.55)',
      color: '#1a3e72',
      fontWeight: '600',
    },
    '& .super-app.negative': {
      backgroundColor: '#d90007',
      color: '#FFFFFF',
      fontWeight: '600',
    },
    '& .super-app.positive': {
      backgroundColor: '#d47483',
      color: '#1a3e72',
      fontWeight: '600',
    },
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  large: {
    width: theme.spacing(15),
    height: theme.spacing(15),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#008000',
  },
 
}));

export default function LogshPage() {
    var classes = useStyles();
    const mclasses = museStyles();
    const [mdata, setmdata] = React.useState([]);
    const [mcolumns, setmcolumns] = React.useState([]);
    const [mdatatareas, setmdatatareas] = React.useState([]);
    const [mcolumnstareas, setmcolumnstareas] = React.useState([]);
    const [loading,setloading]=React.useState(false);
    const [open, setOpen] = React.useState(false);

    var lstprovee=Constantes.URLSERVER+Constantes.APIGETLOGSHISTORICOPROCES
    var lgetTareasProces=Constantes.URLSERVER+Constantes.APIGETAREASPROCESO
    
    var lstArea=[];
    var config = {
        headers: {'Access-Control-Allow-Origin': '*' , 'Authorization': 'Bearer '+localStorage.getItem('id_token')}
    };
    var configjson = {
        headers: {'Content-Type': 'application/json' , 'Authorization': 'Bearer '+localStorage.getItem('id_token')}
    };
    useEffect(()=>{
      GetHistoricos();
    },[])
    /*datagrid */
    
    const body = (
      <Grid item xs={12}>
        <Widget title={"Tareas del proceso"}  upperTitle noBodyPadding disableWidgetMenu={true} bodyClass={classes.tableOverflow}>
          <div style={{ height: 380 , padding:0}} >
            <DataGrid
            // localeText={esES.props.MuiDataGrid.localeText}
            rows={mdatatareas}
            columns={mcolumnstareas}
            pageSize={5}
            showToolbar ={false} />
          </div>
        </Widget>
      </Grid>
  );
    
    /*Peticiones*/ 
    const GetHistoricos=async()=>{
      try {
          var data={};
          setloading(true);
          const resp = await axios.get(lstprovee,config);
          var result = resp.data;
          if(result.codigoRespuesta==0){
              var columns=[
                     { field: 'procesoId', headerName: '# Proceso', width: 110 },
                     {field:'id', headerName:'#',width:100,
                     renderCell: (params: CellParams) => {
                      const onClickver = () => {
                       const api: GridApi = params.api;
                       const fields = api
                         .getAllColumns()
                         .map((c) => c.field)
                         .filter((c) => c !== "__check__" && !!c);
                       const thisRow = {};
               
                       fields.forEach((f) => {
                         thisRow[f] = params.getValue(f);
                       });
               
                       return  verTareas(thisRow);
                     };
                      return <div>
                              <label htmlFor="icon-button-file">
                              <IconButton color="primary" onClick={onClickver} component="span">
                               <PanoramaFishEyeRounded/>
                              </IconButton>
                            </label>
                          </div>;
                    }},
                     { field: 'procesoCodigo', headerName: 'Codigo Proceso',width: 180  },
                     { field: 'actividadNombre', headerName: 'Actividad Nombre', width: 180 },
                     { field: 'personalNombre', headerName: 'Nombre Personal', width: 180 },
                     { field: 'personalNombreDirigido', headerName: 'Nombre Personal Dirigido', width: 180 },
                     { field: 'procesoFechaCreacionStr', headerName: 'Fecha de Creacion', width: 170 },
                     { field: 'tareaFechaAsignacionStr', headerName: 'Fecha deAsignacion', width: 180 },
                     { field: 'tareaFechaEjecucionStr', headerName: 'Fecha de Ejecuacion', width: 180 },
                     { field: 'estado', headerName: 'Estado', width: 180 },
                     
                   ];
              var rows=result.valorRespuesta;
              var logh={};
             /* for (let j = 1; j <= rows; j += 1) {
                rows[j].id=j;
              }*/
              rows.map(dt => {
                dt.id=dt.procesoId;
              });
              console.log(rows);
              lstArea=rows;
              setmcolumns(columns);
              
              setmdata(rows);
          }else{
              
          }
          setloading(false);
          return data;
        } catch (err) {
            console.error(err);
        }
  }
    const verTareas=async(vdata)=>{
      try {
        var data={};
        var urlfile =lgetTareasProces+"ProcesoId="+vdata.procesoId;
        const resp = await axios.get(urlfile,config);
        var result = resp.data;
        if(result.codigoRespuesta==0){
            handleClose();

            var tareas=result.valorRespuesta;
            var ct=1;
            var datatareas=tareas.listaTareas.map(dt=>{
                dt.id=ct;
                ct++
            });

            
            setmdatatareas(tareas.listaTareas);
            var columns=[
              //{ field: 'id', headerName: '#', width: 80 },
              { field: 'personalNombre', headerName: 'Nombre', resizable: true,width: 150  },
              { field: 'comentario', headerName: 'Comentario', width: 150 },
              { field: 'actividadNombre', headerName: 'Actividad', width: 150 },
              { field: 'tareaFechaAsignacionStr', headerName: 'Asigando', width: 180 },
              { field: 'tareaFechaEjecucionStr', headerName: 'Realizado', width: 180 },
              { field: 'dias', headerName: 'Dias', width: 80 },

            ];
            setmcolumnstareas(columns);
        }else{
        }
      } catch (err) {
          console.error(err);
      }
    }
    
    
    /*en peticiones */
    
  

    

    
 
   const handleClose = () => {
    setOpen(!open);
  };
   

 
 
  return (
    <>
      <Grid container spacing={6}>
      <Grid item xs={12} md={16} style={{paddingBottom: 1}}>
        <Widget title="Historicos Procesos"  disableWidgetMenu   style={{paddingRight: 15}} headerClass={classes.widgetHeader}>
        
        
        </Widget>
        </Grid>
      <Grid item xs={12} md={16} style={{paddingBottom: 1}}>
          <Widget  disableWidgetMenu>
          <div style={{ height: 700 , padding:0}}>
            <DataGrid 
            rows={mdata} 
            columns={mcolumns}  
            pageSize={15}
            columnBuffer={2}
            showToolbar 
            density="compact"
            filterModel={{
              items: [
                { columnField: 'procesoCodigo', operatorValue: 'contains', value: '' },
              ],
            }}
             />
          </div>
              
          </Widget>
        </Grid>
       
     
        <Grid item xs={12} md={16} style={{paddingBottom: 1}}>
        <Widget noWidgetShadow disableWidgetMenu noBodyPadding noHeaderPadding style={{paddingRight: 15}} headerClass={classes.widgetHeader}>
        
        
        </Widget>
        </Grid>
      </Grid>

      
      

      
      
        

      <Modal
        isOpen={open}
        centered
        zIndex={5000}
        size={'lg'}
        autoFocus={true}
      >
        <ModalBody>
        {body}
        </ModalBody>
        <ModalFooter>
            <Button className="btn btn-danger" onClick={()=>handleClose()} >Cerrar</Button>
        </ModalFooter>
      </Modal>
      
      <Backdrop className={mclasses.backdrop} open={loading} >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}
