import React, { useState } from "react";
import {
  Grid,
  LinearProgress,
  Select,
  OutlinedInput,
  MenuItem,
  TextField
} from "@material-ui/core";
import { useTheme } from "@material-ui/styles";
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import {
  ResponsiveContainer,
  ComposedChart,
  AreaChart,
  LineChart,
  Line,
  Area,
  PieChart,
  Pie,
  Cell,
  YAxis,
  XAxis,
} from "recharts";

// styles
import useStyles from "./styles";
import axios from 'axios'
import Constantes from "../../context/Constates";

// components
import mock from "./mock";
import Widget from "../../components/Widget/Widget";
import PageTitle from "../../components/PageTitle/PageTitle";
import { Typography } from "../../components/Wrappers/Wrappers";
import Dot from "../../components/Sidebar/components/Dot";
import Table from "./components/Table/Table";
import BigStat from "./components/BigStat/BigStat";
import UserAvatar from "../../components/UserAvatar/UserAvatar";
import {Modal,ModalBody,ModalFooter,ModalHeader} from "reactstrap"
const mainChartData = getMainChartData();


export default function Perfil(props) {
  var classes = useStyles();
  var theme = useTheme();

  // local
  var [mainChartState, setMainChartState] = useState("monthly");
  var Usuario=JSON.parse(localStorage.getItem('user'));

  var voidcontra=Constantes.URLSERVER+Constantes.APIPOSTPASSWORD
  var configjson = {
    headers: {'Content-Type': 'application/json' , 'Authorization': 'Bearer '+localStorage.getItem('id_token')}
  };
  const [clave, setclave] = React.useState("");
  const [clavenew, setclavenew] = React.useState("");
  const [clavenewrep, setclavenewrep] = React.useState("");
  const [modal, setmodal] = React.useState(false);
  
  const handleChangeclave=(event) => {
    setclave(event.target.value);
  }
  const handleChangeclavenew=(event) => {
    setclavenew(event.target.value);
  }
  const handleChangeclavenewrep=(event) => {
    setclavenewrep(event.target.value);
  }
  const cambiarcontra=async()=>{
    try {
      var data={};
      var mRol={};
        if(valida()){
          if(clavenew==clavenewrep){
            mRol.PersonalId =Usuario.personalId;
            mRol.ClaveActual =clave;
            mRol.NuevaClave  =clavenew;
            mRol.RepitaNuevaClave =clavenewrep;
            const json = JSON.stringify(mRol);
            console.log(json);
            const resp = await axios.post(voidcontra,json,configjson);
            var result = resp.data;
            if(result.codigoRespuesta==0){
              alert(result.mensajeRespuesta);   
              accionesModal();
              
            }else{
              alert(result.mensajeRespuesta);   
            }
          }else{
            alert("Las nuevas contraseña no coinciden");   

          }
          
        }else{
          alert("los datos son requeridos");   
        }
        
        return data;
      } catch (err) {
          console.error(err);
      }
  }

  function valida(){
    var sw=true;
    if(clave && clave==""){
      sw=false;
    }
    if(clavenew && clavenew==""){
      sw=false;
    }
    if(clavenewrep && clavenewrep==""){
      sw=false;
    }
    return sw;
  }
  const accionesModal=()=>{
    console.log("ingtre"+modal)
    setmodal(!modal)
  }

  return (
    <>
      
      <Grid container spacing={3}>
       
        
       
        
        <Grid item lg={4} md={4} sm={4} xs={12}>
          <Widget
            title="Perfil:"
            upperTitle
            bodyClass={classes.fullHeightBody}
            className={classes.card}
            disableWidgetMenu={true}
          >
            <div className={classes.card}>
                <div class="card-body">
                  <div className={classes.centeritems} >
                    <img src="https://bootdey.com/img/Content/avatar/avatar7.png" alt="Admin" className={classes.imgCircle} width="150"/>
                    <div class="mt-3">
                      <h4>{Usuario.personalNombre} </h4>
                      <hr/>
                      <Button
                          variant="contained"
                          color="primary"
                          size="large"
                          className={classes.button}
                          onClick={()=>accionesModal()}
                          endIcon={<Icon>password</Icon>}>
                          Cambiar Contraseña
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
          </Widget>
        </Grid>
        <Grid item lg={8} md={8} sm={8} xs={12}>
          <Widget
            title="Datos Personales:"
            upperTitle
            bodyClass={classes.fullHeightBody}
            className={classes.card}
            disableWidgetMenu={true}
          >
            <div class="card mb-3">
                <div class="card-body">
                  <div class="row">
                    <div class="col-sm-3">
                      <h6 class="mb-0">Nombre Completo</h6>
                    </div>
                    <div class="col-sm-9 text-secondary">
                      {Usuario.personalNombre}
                    </div>
                  </div>
                  <hr/>
                  <div class="row">
                    <div class="col-sm-3">
                      <h6 class="mb-0">Correo</h6>
                    </div>
                    <div class="col-sm-9 text-secondary">
                    {Usuario.personalCorreo}
                    </div>
                  </div>
                  <hr/>
                  <div class="row">
                    <div class="col-sm-3">
                      <h6 class="mb-0">Usuario</h6>
                    </div>
                    <div class="col-sm-9 text-secondary">
                    {Usuario.personalCuentaRed}
                    </div>
                  </div>
                </div>
              </div>
          </Widget>
        </Grid>
      </Grid>



      <Modal
        isOpen={modal}
        paddingTop={30}
        centered
        zIndex={9000}
        size={'xl'}
      >
        <ModalHeader>
            Cambiar Contraseña
        </ModalHeader>
        <ModalBody>
            <Grid container spacing={6}  xs={12} md={12}>
              <Grid item xs={6} md={6}>
              <TextField
                  InputProps={{
                    classes: {
                      underline: classes.textFieldUnderline,
                      input: classes.textField,
                    },
                  }}
                  onChange={handleChangeclave}
                  label="Clave Actual"
                  margin="normal"
                  required
                  type="password"
                  fullWidth
                />
              <TextField
                  InputProps={{
                    classes: {
                      underline: classes.textFieldUnderline,
                      input: classes.textField,
                    },
                  }}
                  onChange={handleChangeclavenew}
                  label="Clave Anterior"
                  margin="normal"
                  required
                  type="password"
                  fullWidth
                />
                <TextField
                  InputProps={{
                    classes: {
                      underline: classes.textFieldUnderline,
                      input: classes.textField,
                    },
                  }}
                  onChange={handleChangeclavenewrep}
                  label="Repetir Clave"
                  margin="normal"
                  required
                  type="password"
                  fullWidth
                />
              </Grid>
              <Grid item xs={6} md={6}>
                <br/>
                <label>1. La clave no debe ser igual a la clave de reseteo</label>
                <br/>
                <label>2. La clave no debe contener espacios vacíos</label>
                <br/>
                <label>3. La clave debe ser de 8 o más dígitos</label>
                <br/>
                <label>4. La clave debe contener al menos 3 letras mayúsculas</label>
                <br/>
                <label>5. La clave debe contener al menos 3 letras minúsculas</label>
                <br/>
                <label>6. La clave debe contener al menos 1 número</label>
                <br/>
                <label>7. La clave debe contener al menos 1 caracter especial ! # $ & ( ) * + - , . /</label>
                <br/>
                </Grid>
            </Grid>
        </ModalBody>
        <ModalFooter>
        <Grid >
        <Button
              variant="contained"
              color="success"
              size="small"
              onClick={()=>cambiarcontra()}
              style={{ marginLeft: 16 }}
            >
              Cambiar
            </Button>
            <Button
              variant="contained"
              color="success"
              size="small"
              onClick={()=>accionesModal()}
              style={{ marginLeft: 16 }}
            >
              Cancelar
            </Button>
        </Grid>
        </ModalFooter>
      </Modal>
    </>
  );
}

// #######################################################################
function getRandomData(length, min, max, multiplier = 10, maxDiff = 10) {
  var array = new Array(length).fill();
  let lastValue;

  return array.map((item, index) => {
    let randomValue = Math.floor(Math.random() * multiplier + 1);

    while (
      randomValue <= min ||
      randomValue >= max ||
      (lastValue && randomValue - lastValue > maxDiff)
    ) {
      randomValue = Math.floor(Math.random() * multiplier + 1);
    }

    lastValue = randomValue;

    return { value: randomValue };
  });
}

function getMainChartData() {
  var resultArray = [];
  var tablet = getRandomData(31, 3500, 6500, 7500, 1000);
  var desktop = getRandomData(31, 1500, 7500, 7500, 1500);
  var mobile = getRandomData(31, 1500, 7500, 7500, 1500);

  for (let i = 0; i < tablet.length; i++) {
    resultArray.push({
      tablet: tablet[i].value,
      desktop: desktop[i].value,
      mobile: mobile[i].value,
    });
  }

  return resultArray;
}
