import React, { Component } from "react";
import { Grid,TextField } from "@material-ui/core";

class BtcArea extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
            opened: false,
		};
		
	}
  
	
  
	render() {
		var { area } = this.props;

		if (area.id>0){
			return (
                <Grid container spacing={3}>
                  <Grid item item xs={12} md={6}>
                    <TextField
                      id="cdc"
                      margin="normal"
                      label="Centro de Costo"
                      value={area.costCenter}
                      inputProps={{ readOnly: true }}
                      fullWidth
                    />
                      <TextField
                      id="carea"
                      margin="normal"
                      label="Area"
                      placeholder="Area"
                      value={area.area}
                      inputProps={{ readOnly: true }}
                      type="text"
                      fullWidth
                    />
                    <TextField
                      id="cdesc"
                      inputProps={{ readOnly: true }}
                      margin="normal"
                      label="Decripcion Area"
                      placeholder="Decripcion Area"
                      value={area.description}
                      type="text"
                      fullWidth
                    />
                  </Grid>
                  <Grid item item xs={12} md={6}>
                    <TextField
                        id="cmanager"
                        inputProps={{ readOnly: true }}
                        margin="normal"
                        label="Manager"
                        placeholder="Manager"
                        value={area.manager}
                        type="text"
                        fullWidth
                      />
                      <TextField
                        id="ccargo"
                      inputProps={{ readOnly: true }}
                        margin="normal"
                        label="Cargo"
                        placeholder="cargo"
                        value={area.position}
                        type="text"
                        fullWidth
                      />
                  </Grid>
              </Grid>
            )
		}else{
			return (
                <div>
                </div>
            )
		}

	}
}

export default BtcArea;