import React from "react";
import axios from 'axios'
import Constantes from "../../context/Constates";
import { PermDeviceInformationSharp } from "@material-ui/icons";
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles({
  root: {
    '& .super-app-theme--cell': {
      backgroundColor: 'rgba(224, 183, 60, 0.55)',
      color: '#1a3e72',
      fontWeight: '600',
    },
    '& .negative': {
      backgroundColor: '#ff0000',
      color: '#1a3e72',
      fontWeight: '600',
    },
    '& .super-app.positive': {
      backgroundColor: '#d47483',
      color: '#1a3e72',
      fontWeight: '600',
    },
  },
});
export { getDatalist ,getArea,getProveedor};

function getDatalist() {
      var menu=Constantes.URLSERVER+'api/servicio/getListaFormSolicitudCompra'
      var config = {
        headers: {'Access-Control-Allow-Origin': '*','Content-Type': 'application/json', 'Authorization': 'Bearer '+localStorage.getItem('id_token')}

    };
   
      axios.get(menu,config)
      .then((repos) => {
        var result = repos.data;
        if(result.codigoRespuesta==0){
          var mn=result.valorRespuesta
          var listarea=mn.listaAreas
          var listctmark=mn.listaCuentasMarketing
          var listmarcas=mn.listaMarcas
          var listprov=mn.listaProveedores
          localStorage.setItem('ltareas', JSON.stringify(listarea));
          localStorage.setItem('ltctmarks', JSON.stringify(listctmark));
          localStorage.setItem('ltmarcas', JSON.stringify(listmarcas));
          localStorage.setItem('ltprov', JSON.stringify(listprov));
          
        }else{
        console.error('sesion expirada!');
          
        }
      })
      .catch(error => {
        console.error('There was an error!', error);
      });
   
      
  
    
  }

  function getArea(){
   var data={};
    var ltareas=JSON.parse(localStorage.getItem('ltareas'));
  
    

     data.columns=[
       { field: 'id', headerName: '#', width: 80 },
       { field: 'area', headerName: 'Area', resizable: true,width: 180  },
       { field: 'costCenter', headerName: 'Centro de Costo', width: 100 },
       { field: 'description', headerName: 'Description', width: 150 },
       { field: 'manager', headerName: 'Manager', width: 180 },
       { field: 'position', headerName: 'Cargo', width: 190 },
     ];
    data.rows=ltareas;
    return data;
  }
  function getProveedor(){
    var data={};
    var ltprov=JSON.parse(localStorage.getItem('ltprov'));
  
    data.columns=[
      { field: 'id', headerName: '#', width: 80 },
      { field: 'estado', headerName: 'Estado', type: 'string', width: 100 },
      { field: 'supplierName', headerName: 'Nombre Proveedor', resizable: true,width: 180  },
      { field: 'taxIdentificationNumber', headerName: 'Nit', width: 180 },
      { field: 'contactName', headerName: 'Nombre Contacto', width: 150 },
      { field: 'contactMail', headerName: 'Contacto', width: 180 },
      { field: 'cellandWhasAppNumber', headerName: 'Telefono', width: 180 },
      { field: 'address', headerName: 'Direccion', width: 180 },
      { field: 'categoría', headerName: 'Categoría', width: 180 },
      { field: 'paisProcedencia', headerName: 'Pais', width: 180 },
      
    ];
    data.rows=ltprov;
    return data;
  }
  