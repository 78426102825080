import React from "react";
import axios from 'axios'

import Constantes from "./Constates";

var UserStateContext = React.createContext();
var UserDispatchContext = React.createContext();
const URLSERVICE="http://localhost:1414/";
const proxyurl = "https://cors-anywhere.herokuapp.com/";


 
  
  
 

var Persona={};

function userReducer(state, action) {
  switch (action.type) {
    case "LOGIN_SUCCESS":
      return { ...state, isAuthenticated: true };
    case "SIGN_OUT_SUCCESS":
      return { ...state, isAuthenticated: false };
    case "LOGIN_FAILURE":
      return { ...state, isAuthenticated: false};  
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function UserProvider({ children }) {
  var [state, dispatch] = React.useReducer(userReducer, {
    isAuthenticated: !!localStorage.getItem("id_token"),
  });

  return (
    <UserStateContext.Provider value={state}>
      <UserDispatchContext.Provider value={dispatch}>
        {children}
      </UserDispatchContext.Provider>
    </UserStateContext.Provider>
  );
}

function useUserState() {
  var context = React.useContext(UserStateContext);
  if (context === undefined) {
    throw new Error("useUserState must be used within a UserProvider");
  }
  return context;
}

function useUserDispatch() {
  var context = React.useContext(UserDispatchContext);
  if (context === undefined) {
    throw new Error("useUserDispatch must be used within a UserProvider");
  }
  return context;
}

export { UserProvider, useUserState, useUserDispatch, loginUser, signOut,passwordrec ,cambiarcontra};

// ###########################################################

function hasWhiteSpace(s) {
  return s.indexOf(' ') >= 0;
}
function validarString (cadenaAnalizar) {
  if(!hasWhiteSpace(cadenaAnalizar)){
      return false;
  }else{
    var c=0;
    var my=0;
   var  mn=0;
    var ce=0;
    var format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
    for (var i = 0; i< cadenaAnalizar.length; i++) {
      var caracter = cadenaAnalizar.charAt(i);
      if(isCharDigit(caracter)){
        c=c+1;
      }
      if (caracter == caracter.toUpperCase()) {
        my=my+1;
      }
      if (caracter == caracter.toLowerCase()){
        mn=mn+1;
      }
      if(format.test(caracter)){
        ce=ce+1;
      }
    }
    if(c<9){
      return false;
    }
  }
}  

function isCharDigit(n){
  return !!n.trim() && n > -1;
}

/*obtencion menus*/
  const getMenuUser=async(history)=>{
  try {
    var token=localStorage.getItem('id_token');
    var fmenu=Constantes.URLSERVER+Constantes.APIGETMENU+"PersonalId="+Persona.personalId
    var config = {
      headers: {'Access-Control-Allow-Origin': '*' ,'Authorization': 'Bearer '+token}
    };
      axios.get(fmenu,config).then((repos) => {
        var result = repos.data;
        if(result.codigoRespuesta==0){
          var mn=result.valorRespuesta[0]
          var grupo=mn.listaGrupoAplicacion
          var mgrupo={};
          mgrupo.grupoPersonasId=mn.grupoPersonasId;
          mgrupo.grupoPersonasNombre=mn.grupoPersonasNombre;
          mgrupo.grupoPersonasUnipersonal=mn.grupoPersonasUnipersonal;
          mgrupo.areaId=mn.areaId;
          localStorage.setItem('menu', JSON.stringify(grupo));
          localStorage.setItem('grupo', JSON.stringify(mgrupo));
          getDatalist(token);
          history.push('/app/dashboard');

        }else{
          
        }
      });
      
  } catch (err) {
      console.error(err);
  }
};
function getDatalist(token) {
  var menu=Constantes.URLSERVER+'api/servicio/getListaFormSolicitudCompra'
  var config = {
    headers: {'Access-Control-Allow-Origin': '*','Content-Type': 'application/json', 'Authorization': 'Bearer '+token}

};

  axios.get(menu,config)
  .then((repos) => {
    var result = repos.data;
    if(result.codigoRespuesta==0){
      var mn=result.valorRespuesta
      var listarea=mn.listaAreas
      var listctmark=mn.listaCuentasMarketing
      var listmarcas=mn.listaMarcas
      var listprov=mn.listaProveedores
      localStorage.setItem('ltareas', JSON.stringify(listarea));
      localStorage.setItem('ltctmarks', JSON.stringify(listctmark));
      localStorage.setItem('ltmarcas', JSON.stringify(listmarcas));
      localStorage.setItem('ltprov', JSON.stringify(listprov));
    }else{
    console.error('sesion expirada!');
      
    }
  })
  .catch(error => {
    console.error('There was an error!', error);
  });

}
const loginUser=async(dispatch, login, password, history, setIsLoading, setError,setMensaje,setModalCE)=> {
  setError(false);
 // window.localStorage.clear();
  setIsLoading(true);
  setMensaje("");

  if (!!login && !!password  ) {

   // var flogin=Constantes.URLSERVER+Constantes.APILOGIN+"Usuario="+login+"&Clave="+password //'api/servicio/getMenu?PersonalId=11'
    var flogin=Constantes.URLSERVER+Constantes.APIPOSTLOGIN
    var config = {
      headers: {'Content-Type': 'application/json'}
     // headers: {'Access-Control-Allow-Origin': '*'}
  };

  var mdata={}
  mdata.Usuario=login;
  mdata.Clave=password;
  const json = JSON.stringify(mdata);
  axios.post(flogin,json,config)
      .then(res => {
        var result = res.data;
    if(result.codigoRespuesta==0){
      var data=result.valorRespuesta
      Persona=result.valorRespuesta.personal
      var Mensaje=data.listaPersonalMensajes
      var token=Persona.personalToken
      localStorage.setItem('user', JSON.stringify(Persona))
      localStorage.setItem('id_token', Persona.personalToken)
      localStorage.setItem('mensajes', JSON.stringify(Mensaje))

      var valido=result.mensajeRespuesta.substring(0, 3);
      var mensaje=result.mensajeRespuesta.substring(3, result.mensajeRespuesta.length);
      if (valido=="666"){
        localStorage.setItem('user_valido', false)
        setMensaje(mensaje)
        setError(true)
        setIsLoading(false)
        setModalCE(true)
        //dispatch({ type: "SIGN_OUT_SUCCESS" });
      }else{
        setError(null)
        setIsLoading(false)
        dispatch({ type: 'LOGIN_SUCCESS' })

        localStorage.setItem('user_valido', true)
        getMenuUser(history);
      }

    }else{
        setError(true)
        setIsLoading(false)
        setMensaje(result.mensajeRespuesta)
    }
      }).catch(error => {
        console.error('There was an error!', error);
      });
    

  } else {
    dispatch({ type: "LOGIN_FAILURE" });
    setError(true);
    setIsLoading(false);
  }
}
var voidcontra=Constantes.URLSERVER+Constantes.APIPOSTPASSWORD
  var configjson = {
    headers: {'Content-Type': 'application/json' , 'Authorization': 'Bearer '+localStorage.getItem('id_token')}
  };
  const cambiarcontra=async(clave,clavenew,clavenewrep,setModalCE,setMensaje,setIsLoading)=>{
    try {
      var data={};
      var mRol={};
      var Usuario=JSON.parse(localStorage.getItem('user'));

        if(valida(clave,clavenew,clavenewrep)){
          
          if(clavenew==clavenewrep){
            setIsLoading(true);
            mRol.PersonalId =Usuario.personalId;
            mRol.ClaveActual =clave;
            mRol.NuevaClave  =clavenew;
            mRol.RepitaNuevaClave =clavenewrep;
            
            const json = JSON.stringify(mRol);
            
            axios.post(voidcontra,json,configjson)
            .then(res => {
              var result = res.data;
          if(result.codigoRespuesta==0){
            alert(result.mensajeRespuesta);  
            // accionesModal();
            setModalCE(false)
      
          }else{
            setModalCE(false)

          }
          setMensaje(result.mensajeRespuesta) 
          setIsLoading(false);
            }).catch(error => {
              console.error('There was an error!', error);
            });

            
          }else{
            alert("Las nuevas contraseña no coinciden");   

          }
          
        }else{
          alert("los datos son requeridos");   
        }
        
        return data;
      } catch (err) {
          console.error(err);
      }
  }

  function valida(clave,clavenew,clavenewrep){
    var sw=true;
    if(clave && clave==""){
      sw=false;
    }
    if(clavenew && clavenew==""){
      sw=false;
    }
    if(clavenewrep && clavenewrep==""){
      sw=false;
    }
    return sw;
  }


function signOut(dispatch, history) {
  localStorage.removeItem("id_token");
  dispatch({ type: "SIGN_OUT_SUCCESS" });
  history.push("/login");
}

function passwordrec( login,setmodal,setmensaje,setIsLoading) {
 
  if (!!login   ) {
    setIsLoading(true);
  var voidreccontra=Constantes.URLSERVER+Constantes.APIPOSTRECPASS
    var config = {
      headers: {'Content-Type': 'application/json' , 'Authorization': 'Bearer '+localStorage.getItem('id_token')}
     // headers: {'Access-Control-Allow-Origin': '*'}
  };
  var mdata={}
  mdata.usuario=login;
  const json = JSON.stringify(mdata);
  axios.post(voidreccontra,json,config)
      .then(res => {
        var result = res.data;
    if(result.codigoRespuesta==0){
      setmensaje(result.mensajeRespuesta);
      setTimeout(() => {
        setmodal(false);
        setIsLoading(false);
      }, 5000);
    }else{
        setmensaje(result.mensajeRespuesta);
        setIsLoading(false);
    }
      }).catch(error => {
        console.error('There was an error!', error);
      });
  } else {
    setmensaje("campo vacio");
  }
}