import React, { useMemo } from "react";

import DataTable from "react-data-table-component";
import FilterComponent from "./FilterComponent";

const MiTable = props => {
  
  const columns = [
    {
      name: "#",
      selector: "id",
      width:"90px",
    },
    {
      name: "Estado",
      selector: "estado",
      sortable: true,
      width:"100px",
      hide: "sm",
    },
    {
      name: "Nombre Proveedor",
      selector: "supplierName",
      sortable: true,
    },
    {
      name: "Nit",
      selector: "taxIdentificationNumber",
      sortable: true,
      width:"120px",
      hide: "md",
    },
    {
      name: "Nombre",
      selector: "contactName",
      sortable: true,
      hide: "md",
    },
    {
        name: "Correo",
        selector: "contactMail",
        sortable: true,
        hide: "md",
    },
    {
        name:"Categoría",
        selector:"categoría",
        sortable:true,
    },
    
  ];
  const handleChange = ({ selectedRows }) => {
    // You can set state or dispatch with something like Redux so we can use the retrieved data
    console.log('Selected Rows: ', selectedRows);
  };
  const conditionalRowStyles = [
    {
      when: row => row.estado =="Inactivo",
      style: {
        color: 'red',
          '&:hover': {
          cursor: 'pointer',
        },
      },
    },
  ];
  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = React.useState(
    false
  );
  // const filteredItems = data.filter(
  //   item => item.name && item.name.includes(filterText)
  // );
 
  const filteredItems = props.data.filter(
    item =>
      JSON.stringify(item)
        .toLowerCase()
        .indexOf(filterText.toLowerCase()) !== -1
  );

  const onMeetingTitleClick = (event,data) => {
    props.onRowClicked(data);
  }
  const onRowClicked = (row, event) => { onMeetingTitleClick(event, row.id); };

  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };
    
    return (
      <FilterComponent
        onFilter={e => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  return (
    <DataTable
      title="Seleccione Proveedor"
      columns={columns}
      data={filteredItems}
      striped
      pagination
      subHeader
      subHeaderComponent={subHeaderComponent}
      onSelectedRowsChange={handleChange}
      conditionalRowStyles={conditionalRowStyles}
      defaultSortFieldId={2}
      onRowClicked={onRowClicked}
    />
  );
};

export default MiTable ;
