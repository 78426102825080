import React ,{useState, useEffect}from "react";
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { Grid,TextField,Paper,Button,IconButton } from "@material-ui/core";
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Icon from '@material-ui/core/Icon';
import {Modal,ModalBody,ModalFooter,ModalHeader} from "reactstrap"
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import PrintIcon from '@material-ui/icons/Print';
import VisibilityIcon from '@material-ui/icons/Visibility';
// styles
import useStyles from "./styles";
import "bootstrap/dist/css/bootstrap.min.css";
// components
import PageTitle from "../../components/PageTitle/PageTitle";
import Widget from "../../components/Widget/Widget";
import { Typography } from "../../components/Wrappers/Wrappers";
import mock from "../dashboard/mock";
import classnames from "classnames";
import {
  DataGrid,
  ColDef,
  ValueGetterParams,
  CellParams,
  GridApi
} from "@material-ui/data-grid";
import Constantes from "../../context/Constates";
import axios from 'axios'
import {Edit,Delete,PanoramaFishEyeRounded} from '@material-ui/icons';
import ReactExport from "react-export-excel";
import { SettingsSystemDaydreamTwoTone } from "@material-ui/icons";



export default function SeguimientopagoPage() {
  const museStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      '& > *': {
        margin: theme.spacing(1),
      },
      '& .super-app-theme--cell': {
        backgroundColor: 'rgba(224, 183, 60, 0.55)',
        color: '#1a3e72',
        fontWeight: '600',
      },
      '& .super-app.negative': {
        backgroundColor: '#d90007',
        color: '#FFFFFF',
        fontWeight: '600',
      },
      '& .super-app.positive': {
        backgroundColor: '#d47483',
        color: '#1a3e72',
        fontWeight: '600',
      },
    },
    small: {
      width: theme.spacing(3),
      height: theme.spacing(3),
    },
    large: {
      width: theme.spacing(15),
      height: theme.spacing(15),
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#008000',
    },
   
  }));
    let history = useHistory();
    const location = useLocation();
    var classes = useStyles();
    const mclasses = museStyles();
    const [mdata, setmdata] = React.useState([]);
    const [mcolumns, setmcolumns] = React.useState([]);
    const [muser, setuser] = React.useState([]);
    const [loading,setloading]=React.useState(false);
    const [Opt,setOpt]=React.useState(0);
    var dataRecibido={};
    var Usuario=JSON.parse(localStorage.getItem('user'));

    var lSeguimiento=Constantes.URLSERVER+Constantes.APIGETSEGUIMIENTOPAGO
    var lSeguimientouser=Constantes.URLSERVER+Constantes.APIGETSEGUIMIENTOUSER
    var lreporte=Constantes.URLSERVER+Constantes.APIGETREPORTE
    var lstSeguimiento=[];
    var config = {
        headers: {'Access-Control-Allow-Origin': '*' , 'Authorization': 'Bearer '+localStorage.getItem('id_token')}
    };
    var opcionfilter=0;

    useEffect(()=>{
      if (location.state!=null) {
        dataRecibido=location.state.user;
        var iduser=Usuario.personalId;
        if (dataRecibido.personalId>0) {
          iduser=dataRecibido.personalId;
        }
        setuser(iduser);
        lSeguimiento=lSeguimiento+"?PersonalId="+iduser
      }
      //GetPersonaAreas();
    },[location])
    
    const ExcelFile = ReactExport.ExcelFile;
    const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
    const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

    /*datagrid */
    
    /*Solicitud */
    const[solselc,setsolselc]=useState({
        id: 0,
        procesoCodigo:'',
        solicitante:'',
        fechaSolicitud:'',
        estado:0,
        personalAsignado:'',
        personalIdGM:0,
        generalManager:'',
        personalIDRegionalCFO:0,
        regionalCFO:'',
        personalIDRegionalPA:0,
        regionalPresidentAmericas:'',
        position:'',
        tipoEstado:0
    })
    /*funciones extras modal */
    
    /*handles*/
    const handleChange=e=>{
        const { name, value}=e.target;
       /* setAreaselect({
            ...areaselc,
            [name]:value
        })*/
        
    }

    function loadData(data){
      
    }
    const handleChanges = (event) => {
      opcionfilter=parseInt(event.target.value);
      GetSolicitudes();
     };
    /*Peticiones*/ 
    
    const GetSolicitudes=async()=>{
        try {
            var data={};
            setloading(true);
            var method=lSeguimiento+"Tipo="+opcionfilter;
            const resp = await axios.get(method,config);
            var result = resp.data;
            if(result.codigoRespuesta==0){
                var columns=[
                       { field: 'procesoCodigo', headerName: 'Proceso Codigo', width: 150 },
                       { field: 'procesoId', headerName: 'Abrir Pago',width: 120,renderCell: (params: CellParams) => {
                        const onClickview = () => {
                          const api: GridApi = params.api;
                          const fields = api
                            .getAllColumns()
                            .map((c) => c.field)
                            .filter((c) => c !== "__check__" && !!c);
                          const thisRow = {};
                  
                          fields.forEach((f) => {
                            thisRow[f] = params.getValue(f);
                          });
                  
                          return  viewSolicitud(thisRow);
                        };
                        return <div >
                                <label htmlFor="icon-button-file">
                                <IconButton  color="primary" onClick={onClickview}  component="span">
                                <VisibilityIcon/>
                                </IconButton>
                              </label>
                            </div>;
                      }  },
                       { field: 'codigoOC', headerName: 'Código OC', width: 150 },
                       { field: 'ordenId', headerName: 'Abrir OC',width: 120,renderCell: (params: CellParams) => {
                        const onClickviewOC = () => {
                          const api: GridApi = params.api;
                          const fields = api
                            .getAllColumns()
                            .map((c) => c.field)
                            .filter((c) => c !== "__check__" && !!c);
                          const thisRow = {};
                  
                          fields.forEach((f) => {
                            thisRow[f] = params.getValue(f);
                          });
                  
                          return  viewSolicitudOC(thisRow);
                        };
                        return <div >
                                <label htmlFor="icon-button-file">
                                <IconButton  color="primary" onClick={onClickviewOC}  component="span">
                                <VisibilityIcon/>
                                </IconButton>
                              </label>
                            </div>;
                      }  },
                       { field: 'nroFactura', headerName: 'Nro Factura', width: 180 },
                       { field: '', headerName: 'Abrir Ft',width: 120,renderCell: (params: CellParams) => {
                        const onClickviewF = () => {
                          const api: GridApi = params.api;
                          const fields = api
                            .getAllColumns()
                            .map((c) => c.field)
                            .filter((c) => c !== "__check__" && !!c);
                          const thisRow = {};
                  
                          fields.forEach((f) => {
                            thisRow[f] = params.getValue(f);
                          });
                  
                          return  viewSolicitudF(thisRow);
                        };
                        return <div >
                                <label htmlFor="icon-button-file">
                                <IconButton  color="primary" onClick={onClickviewF}  component="span">
                                <VisibilityIcon/>
                                </IconButton>
                              </label>
                            </div>;
                      }  },
                      { field: 'nit', headerName: 'Factura(Marca Agua)',width: 120,renderCell: (params: CellParams) => {
                        const onClickviewF = () => {
                          const api: GridApi = params.api;
                          const fields = api
                            .getAllColumns()
                            .map((c) => c.field)
                            .filter((c) => c !== "__check__" && !!c);
                          const thisRow = {};
                  
                          fields.forEach((f) => {
                            thisRow[f] = params.getValue(f);
                          });
                  
                          return  viewSolicitudF(thisRow);
                        };
                        return <div >
                                <label htmlFor="icon-button-file">
                                <IconButton  color="primary" onClick={onClickviewF}  component="span">
                                <VisibilityIcon/>
                                </IconButton>
                              </label>
                            </div>;
                      }  },
                       { field: 'proveedor', headerName: 'Proveedor', width: 180 },
                       { field: 'description', headerName: 'Description', width: 180 },
                       { field: 'total', headerName: 'Total', width: 80 },
                       { field: 'pendientePago', headerName: 'Pendiente de Pago', width: 180 },
                       { field: 'totalOC', headerName: 'Total OC', width: 180 },
                       { field: 'totalPagado', headerName: 'Total Pagado', width: 180 },
                       { field: 'solicitante', headerName: 'Solicitante', width: 180 },
                       { field: 'fechaSolicitudStr', headerName: 'Fecha de Solicitud', width: 180 },
                       { field: 'estado', headerName: 'Estado', width: 180 },
                       { field: 'centroCosto', headerName: 'Centro de Costo', width: 180 },
                       { field: 'ceCoDescripcion', headerName: 'CeCo Descripcion', width: 180 },
                       { field: 'manager', headerName: 'Manager', width: 180 },
                       { field: 'contacto', headerName: 'Contacto', width: 180 },
                       { field: 'celular', headerName: 'Celular', width: 180 },
                       { field: 'condicionPago', headerName: 'Condición de Pago', width: 180 },
                       { field: 'direccion', headerName: 'Direccion', width: 180 },
                       { field: 'telefonoOficina', headerName: 'Telefono de Oficina', width: 180 },
                       
                     ];
                var rows=result.valorRespuesta;
                rows.map(dt => {
                  dt.id=dt.procesoId;
                });
                lstSeguimiento=rows;
                setmcolumns(columns);
                setmdata(rows);
            }else{
                
            }
            setloading(false);
            return data;
          } catch (err) {
              console.error(err);
          }
    }
    
    /*en peticiones */
    
  function viewSolicitudOC(data){
    var url =window.location.origin+"/#/app/Solcompra/"+data.ordenId;
      window.open(url, "_blank");
    
    // var urlfile =lreporte+"ProcesoId="+data.ordenId;
    // var win = window.open(urlfile, '_blank');
    // win.focus();
    // axios({
    //   url: urlfile,
    //   method: 'GET',
    //   headers: {
    //     'Content-Type': 'application/pdf',
    //   },
    //   responseType: 'blob', // important
    // }).then((response) => {
    //   const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
    //   const link = document.createElement('a');
    //   link.href = url;
    //   //link.setAttribute('download', 'file.pdf');
    //   link.setAttribute('target', '_blank');
    //   //link.target="target";
    //   document.body.appendChild(link);
    //   link.click();
    // });
  }
  function viewSolicitudF(data){
    var urlfile =lreporte+"ProcesoId="+data.procesoId;
    var win = window.open(urlfile, '_blank');
    win.focus();
    axios({
      url: urlfile,
      method: 'GET',
      headers: {
        'Content-Type': 'application/pdf',
      },
      responseType: 'blob', // important
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
      const link = document.createElement('a');
      link.href = url;
      //link.setAttribute('download', 'file.pdf');
      link.setAttribute('target', '_blank');
      //link.target="target";
      document.body.appendChild(link);
      link.click();
    });
  }
  function viewSolicitud(data){
    console.log(data);
    var url =window.location.origin+"/#/app/Solpago/"+data.procesoId;
    window.open(url, "_blank");
    // var sol=data;
    // history.push({
    //   pathname: '../../app/Solpago',
    //   state: { dtsolicitud: sol }
    // });
  }

    useEffect(()=>{
     // GetSolicitudes();
    },[])

    
 
    
   

 
 
  return (
    <>
      <Grid container spacing={6}>
      <Grid item xs={12} md={16} style={{paddingBottom: 1}}>
        <Widget title="Seguimiento al Proceso de Solicitud de Compra y Emisión de Ordenes de Compra"  disableWidgetMenu   style={{paddingRight: 15}} headerClass={classes.widgetHeader}>
        
        <Grid item xs={6} md={6}>
            <ExcelFile filename={"Report Seguimiento"} element={ 
              <Button
              variant="contained"
              color="primary"
              className={classes.button}
              endIcon={<Icon>cloud_download</Icon>}>
              Exportar Excel
            </Button>}>
              <ExcelSheet data={mdata} name="Proveedores">
                <ExcelColumn label="Proceso" value="procesoId"/>
                <ExcelColumn label="Codigo de Proceso" value="procesoCodigo"/>
                <ExcelColumn label="Solicitante" value="solicitante"/>
                <ExcelColumn label="Fecha de Solicitud" value="fechaSolicitudStr"/>
                <ExcelColumn label="Estado" value="estado"/>
                <ExcelColumn label="Personal Asignado" value="personalAsignado"/>
                <ExcelColumn label="Fecha Asignacion" value="tareaFechaAsignacionStr"/>
                <ExcelColumn label="Description" value="description"/>
                <ExcelColumn label="Area" value="area"/>
                <ExcelColumn label="Centro de Costo" value="centroCosto"/>
                <ExcelColumn label="Nombre" value="nombre"/>
                <ExcelColumn label="Cargo" value="cargo"/>
                <ExcelColumn label="Proveedor" value="proveedor"/>
                <ExcelColumn label="Nit" value="nit"/>
                <ExcelColumn label="Nombre Contacto" value="nombreContacto"/>
                <ExcelColumn label="Email Contacto" value="emailContacto"/>
                <ExcelColumn label="Celular" value="celular"/>
                <ExcelColumn label="Telefono Oficina" value="telefonoOficina"/>
                <ExcelColumn label="Monto" value="monto"/>
                <ExcelColumn label="Saldo" value="saldo"/>
              </ExcelSheet>
                
            </ExcelFile> 
            </Grid>
        </Widget>
        </Grid>
        <Grid item xs={12} md={16} style={{paddingBottom: 1}}>
          <Widget  disableWidgetMenu>
          <Grid item xs={6} md={6}>
            <FormControl className={classes.formControl} fullWidth>
                    <InputLabel htmlFor="age-native-simple">Seleccione un estado</InputLabel>
                    <Select
                    native
                    onChange={handleChanges}
                    >
                        <option value=""></option>
                        <option value="1">Aprobado por el solicitante</option>
                        <option value="2">Rechazada por el solicitante</option>
                        <option value="3">Pagado 2019</option>
                        <option value="4">Pagado 2020</option>
                        <option value="5">Pagado 2021</option>
                        <option value="6">Pendiente aprobacion por el solicitante</option>
                    
                    </Select>
                </FormControl>
            </Grid>
            <br/><br/>
          <div style={{ height: 700 , padding:0}}>
            <DataGrid 
            rows={mdata} 
            columns={mcolumns} 
            headerHeight={80} 
            pageSize={15}
            columnBuffer={2}
            showToolbar 
            density="compact"
            /*onRowSelected={(newSelection) => {
              var sol=newSelection.data;
                      history.push({
                        pathname: '../../app/Solcompra',
                        state: { dtsolicitud: sol }
                      });
                      console.log(newSelection.data);
              }}*/
            filterModel={{
              items: [
                { columnField: 'procesoCodigo', operatorValue: 'contains', value: '' },
              ],
            }}
             />
          </div>
              
          </Widget>
        </Grid>
       
     
        <Grid item xs={12} md={16} style={{paddingBottom: 1}}>
        <Widget noWidgetShadow disableWidgetMenu noBodyPadding noHeaderPadding style={{paddingRight: 15}} headerClass={classes.widgetHeader}>
        
        
        </Widget>
        </Grid>
      </Grid>

      <Backdrop className={mclasses.backdrop} open={loading} >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}
