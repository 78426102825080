import React ,{useState, useEffect}from "react";
import { Grid,TextField,Paper,Button,IconButton } from "@material-ui/core";
import {Modal,ModalBody,ModalFooter,ModalHeader} from "reactstrap"
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import ReactExport from "react-export-excel";
import Icon from '@material-ui/core/Icon';

// styles
import useStyles from "./styles";
import "bootstrap/dist/css/bootstrap.min.css";
// components
import PageTitle from "../../components/PageTitle/PageTitle";
import Widget from "../../components/Widget/Widget";
import { Typography } from "../../components/Wrappers/Wrappers";
import mock from "../dashboard/mock";
import classnames from "classnames";
import {
  DataGrid,
  ColDef,
  ValueGetterParams,
  CellParams,
  GridApi
} from "@material-ui/data-grid";
import Constantes from "../../context/Constates";
import axios from 'axios'
import {Edit,Delete,PanoramaFishEyeRounded} from '@material-ui/icons';
import { useAlert } from "react-alert";
import { SettingsSystemDaydreamTwoTone } from "@material-ui/icons";
import MiTable from "./DataTable";


export default function Abmuser() {
    var classes = useStyles();
    const alert = useAlert();
    const [mdata, setmdata] = React.useState([]);
    const [mcolumns, setmcolumns] = React.useState([]);
    const [mPersonas, setmPersonas] = React.useState([]);
    const [mPersona, setmPersona] = React.useState({});
    const [mPersonaJefe, setmPersonaJefe] = React.useState({});
    const [modalpersonal, setmodalpersonal] = React.useState(false);
    const [modalins, setmodalins] = React.useState(false);
    const [modaleditar, setmodaleditar] = React.useState(false);
    const [modalver, setmodalver] = React.useState(false);
    const [personasel, setpersonasel] = React.useState({});

    const ExcelFile = ReactExport.ExcelFile;
    const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
    const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

    var lareas=Constantes.URLSERVER+Constantes.APIGETLISTAAREA
    var lPersareas=Constantes.URLSERVER+Constantes.APIGETPERSAREA
    var lVoidareas=Constantes.URLSERVER+Constantes.APIPOSTAREA
    var obteneruserroles=Constantes.URLSERVER+Constantes.APIGETROLESUSER
    var voidpersonal=Constantes.URLSERVER+Constantes.APIPOSTPERSONAL
    var resetclave=Constantes.URLSERVER+Constantes.APIGETRESETCLAVE

    var lstPersonas=[];
    var config = {
        headers: {'Access-Control-Allow-Origin': '*' , 'Authorization': 'Bearer '+localStorage.getItem('id_token')}
    };
    var configjson = {
        headers: {'Content-Type': 'application/json' , 'Authorization': 'Bearer '+localStorage.getItem('id_token')}
    };
    /*datagrid */
    const  columnspers=[
      { field: 'personalId', headerName: '#', width: 80 },
      { field: 'personalNombre', headerName: 'Nombre', resizable: true,width: 180  },
      { field: 'personalCuentaRed', headerName: 'Cuenta', width: 150 },
      { field: 'personalNumeroDocumento', headerName: 'Documento', width: 180 },
      { field: 'personalCorreo', headerName: 'Correo', width: 180 },
      { field: 'personalEstado', headerName: 'Estado', width: 180 },
    
    ];
    /*Area */
    const[personaselc,setpersonaselc]=useState({
        personalId : 0,
        personalNombre :'',
        personalCodigoSAP :'',
        personalNumeroDocumento :'',
        areaId :4,
        personalCuentaRed :'',
        personaImagen :'',
        personalId_Jefe :0,
        personalCorreo :'',
        personalClave :'123*',
        personalEstado :'Activo',
        tipoEstado:1
    })
    /*funciones extras modal */
    const accionesModal=()=>{
        setmodalins(!modalins)
    }
    const accionesModalVer=()=>{
      setmodalver(!modalver)
    }
    const accionesModalEdit=()=>{
      setmodaleditar(!modaleditar)
  }
  
    /*handles*/
    const handleChange=e=>{
        const { name, value}=e.target;
        setpersonaselc({
            ...personaselc,
            [name]:value
        })
        
    }
    /*Peticiones*/ 
   
    const GetRoleandUser=async()=>{
      try {
          var data={};
          const resp = await axios.get(obteneruserroles,configjson);
          var result = resp.data;
          if(result.codigoRespuesta==0){
            var columns=[
              { field: '', headerName: 'Acciones', width: 200 ,
              renderCell: (params: CellParams) => {
               const onClickedit = () => {
                 const api: GridApi = params.api;
                 const fields = api
                   .getAllColumns()
                   .map((c) => c.field)
                   .filter((c) => c !== "__check__" && !!c);
                 const thisRow = {};
         
                 fields.forEach((f) => {
                   thisRow[f] = params.getValue(f);
                 });
         
                 return  editPersona(thisRow);
               };
               const onClickdel = () => {
                 const api: GridApi = params.api;
                 const fields = api
                   .getAllColumns()
                   .map((c) => c.field)
                   .filter((c) => c !== "__check__" && !!c);
                 const thisRow = {};
         
                 fields.forEach((f) => {
                   thisRow[f] = params.getValue(f);
                 });
         
                 return  deletPersona(thisRow);
               };
               const onClickver = () => {
                const api: GridApi = params.api;
                const fields = api
                  .getAllColumns()
                  .map((c) => c.field)
                  .filter((c) => c !== "__check__" && !!c);
                const thisRow = {};
        
                fields.forEach((f) => {
                  thisRow[f] = params.getValue(f);
                });
        
                return  verUsuario(thisRow);
              };
               return <div>
                       <label htmlFor="icon-button-file">
                       <IconButton color="primary" onClick={onClickver} component="span">
                        <PanoramaFishEyeRounded/>
                       </IconButton>
                       <IconButton color="primary" onClick={onClickedit}  component="span">
                       <Edit/>
                       </IconButton>
                       <IconButton color="primary" onClick={onClickdel} component="span">
                       <Delete/>
                       </IconButton>
                     </label>
                   </div>;
             }},
              { field: 'personalId', headerName: '#', width: 80 },
              { field: 'personalNombre', headerName: 'Nombre', resizable: true,width: 180  },
              { field: 'personalCuentaRed', headerName: 'Cuenta', width: 150 },
              { field: 'personalNumeroDocumento', headerName: 'Documento', width: 180 },
              { field: 'personalCorreo', headerName: 'Correo', width: 180 },
              { field: 'personalEstado', headerName: 'Estado', width: 180 }
              
            ];
          var datausuarios=result.valorRespuesta.listaPersonalTodos;
          var c=1;
          datausuarios.map(dt=>{
            dt.id=c;
            c++;
          })
          setmcolumns(columns);
          lstPersonas=datausuarios;
          setmdata(datausuarios);
            var dataroles=result.valorRespuesta.listaAplicacionTodos;
           // setmPersonas(datausuarios);
            //setmRoles(dataroles);
            //setmUsers(datausuarios);
          }else{
            alert.show(result.mensajeRespuesta);
          }
          return data;
        } catch (err) {
            console.error(err);
        }
  }
    const ResetClaveUser=async()=>{
        try {
            var data={};
            var idpersonal=personaselc.personalId;
            var methodreset=resetclave+"PersonalId="+idpersonal
            const resp = await axios.get(methodreset,config);
            var result = resp.data;
            if(result.codigoRespuesta==0){
              accionesModalEdit();
            }else{
              accionesModalEdit();
              alert.show(result.mensajeRespuesta);
            }
            return data;
          } catch (err) {
            accionesModalEdit();
              console.error(err);
          }
    }
    const voidPersona=async(tipo)=>{
        try {
          var data={};
          var persona={}
          if(tipo==1){
            persona.personalId =parseInt(personaselc.PersonalId );
            persona.areaId=parseInt(personaselc.AreaId);
            persona.personalNombre =personaselc.PersonalNombre;
            persona.personalCodigoSAP =personaselc.PersonalCodigoSAP;
            persona.personalCuentaRed =personaselc.PersonalCuentaRed;
            persona.personalCorreo =personaselc.PersonalCorreo ;
            persona.personaImagen =personaselc.PersonaImagen ;
            persona.personalEstado =personaselc.PersonalEstado ;
            persona.personalClave ='123*' ;
            persona.personalNumeroDocumento =personaselc.PersonalNumeroDocumento ;
            persona.personalId_Jefe=parseInt(mPersonaJefe.personalId);
          }else if(tipo==2){
            //persona=personaselc;
            persona.personalId =parseInt(personaselc.personalId );
            persona.areaId=parseInt(personaselc.areaId);
            persona.personalNombre =personaselc.personalNombre;
            persona.personalCodigoSAP =personaselc.personalCodigoSAP;
            persona.personalCuentaRed =personaselc.personalCuentaRed;
            persona.personalCorreo =personaselc.personalCorreo ;
            persona.personaImagen =personaselc.personaImagen ;
            persona.personalEstado =personaselc.personalEstado ;
            persona.personalClave =personaselc.personalClave ;
            persona.personalNumeroDocumento =personaselc.personalNumeroDocumento ;
            persona.personalId_Jefe=mPersonaJefe.personalId;
            
            if(mPersonaJefe && mPersonaJefe.personalId>0 ){
              persona.PersonalId_Jefe=parseInt(mPersonaJefe.personalId);
            }

          }else{
            persona={};
            persona=personaselc;
          }
            persona.tipoEstado=tipo;
            const json = JSON.stringify(persona);
            console.log(json);
            const resp = await axios.post(voidpersonal,json,configjson);
            var result = resp.data;
            if(tipo==1){
              accionesModal();
            }if(tipo==2){
              accionesModalEdit();
            }
            if(result.codigoRespuesta==0){
              GetRoleandUser();
            }else{
              alert.show(result.mensajeRespuesta);
            }
            return data;
          } catch (err) {
              console.error(err);
          }
    }
    /*en peticiones */
    
    useEffect(()=>{
       // GetAreas();
        GetRoleandUser();
    },[])

    function verUsuario(vardata){
      if (vardata && vardata.personalId>0) {

        lstPersonas.map(dt => {
             if(vardata.personalId==dt.personalId){
                 //setpersonasel(dt);
                 setpersonaselc(dt);
             }
           });
           accionesModalVer();
         }
    }



   function editPersona(vardata){
      if (vardata && vardata.personalId>0) {

     lstPersonas.map(dt => {
          if(vardata.personalId==dt.personalId){
              //setpersonasel(dt);
              setpersonaselc(dt);
          }
        });
        accionesModalEdit();
      }
   }
   function deletPersona(vardata){
    if (vardata && vardata.personalId>0) {
      console.log("sel:"+vardata.personalId);
 
      lstPersonas.map(dt => {
           if(vardata.personalId==dt.personalId){
               //setpersonasel(dt);
               setpersonaselc(dt);
               voidPersona(3);
           }
         });
        // accionesModalEdit();
       }
   }
   const accionesModalpers=()=>{
    setmodalpersonal(!modalpersonal)
  }

  const clickhandler = id => loadData(id);

  const onMeetingTitleClick = (data) => {
    loadData(data);
    console.log(data);

  }
  const onRowClicked = row => { onMeetingTitleClick(row); };

  const loadData=(objid)=>{
  console.log(objid);

    mdata.map(dt => {
      if(objid==dt.personalId){
          //setpersonasel(dt);
          setmPersonaJefe(dt);
      console.log(dt);
      }
    });
    accionesModalpers();
  }
 
   const body = (
    <Grid item xs={12}>
      <Widget title={"Personal"}  upperTitle noBodyPadding disableWidgetMenu={true} bodyClass={classes.tableOverflow}>
        <div style={{ height: 380 , padding:0}}   >
          <DataGrid 
          rows={mdata} 
          columns={columnspers}  
          zIndex={2000}
          pageSize={5} 
          showToolbar
          onRowSelected={(newSelection) => {
          loadData(newSelection.data);
          }}  />
        </div>
      </Widget>
    </Grid>
);
   

 
 
  return (
    <>
      <Grid container spacing={6}>
      <Grid item xs={12} md={16} style={{paddingBottom: 1}}>
        <Widget title="Administracion de Usuarios"  disableWidgetMenu   style={{paddingRight: 15}} headerClass={classes.widgetHeader}>
        <Button
          variant="contained"
          size="small"
          color="primary"
          className={classes.button}
          style={{ marginLeft: 16 }}
          onClick={()=>accionesModal()}
        >
          Agregar
        </Button>
     
        
        </Widget>
        </Grid>
      <Grid item xs={12} md={16} style={{paddingBottom: 1}}>
          <Widget  disableWidgetMenu>
          
          <div style={{ height: 700 , padding:0}}>
            <DataGrid 
            rows={mdata} 
            columns={mcolumns}  
            pageSize={10}
            showToolbar 
            filterModel={{
              items: [
              ],
            }}
             />
          </div>
              
          </Widget>
        </Grid>
       
     
        <Grid item xs={12} md={16} style={{paddingBottom: 1}}>
        <Widget noWidgetShadow disableWidgetMenu noBodyPadding noHeaderPadding style={{paddingRight: 15}} headerClass={classes.widgetHeader}>
        
        
        </Widget>
        </Grid>
      </Grid>

      <Modal
        isOpen={modalins}
        zIndex={2000}
        centered
      >
        <ModalHeader>
            Registrar Usuario
        </ModalHeader>
        <ModalBody>
        <Grid container spacing={6}>
            <Grid item xs={12} md={12}>
              <TextField
                  multiline
                  id="personalNombre"
                  name="PersonalNombre"
                  InputProps={{
                    classes: {
                      underline: classes.textFieldUnderline,
                      input: classes.textField,
                    },
                  }}
                  label="Nombre"
                  margin="normal"
                  required
                  onChange={handleChange}
                  type="text"
                  fullWidth
                />
                <TextField
                  multiline
                  id="personalCodigoSAP"
                  name="PersonalCodigoSAP"
                  InputProps={{
                    classes: {
                      underline: classes.textFieldUnderline,
                      input: classes.textField,
                    },
                  }}
                  label="Codigo SAP"
                  margin="normal"
                  required
                  onChange={handleChange}
                  type="text"
                  fullWidth
                />
                <TextField
                  multiline
                  id="personalCuentaRed"
                  name="PersonalCuentaRed"
                  InputProps={{
                    classes: {
                      underline: classes.textFieldUnderline,
                      input: classes.textField,
                    },
                  }}
                  label="Cuenta"
                  margin="normal"
                  required
                  onChange={handleChange}
                  type="text"
                  fullWidth
                />
                <TextField
                  multiline
                  id="personalCorreo"
                  name="PersonalCorreo"
                  InputProps={{
                    classes: {
                      underline: classes.textFieldUnderline,
                      input: classes.textField,
                    },
                  }}
                  label="Correo"
                  margin="normal"
                  required
                  onChange={handleChange}
                  type="text"
                  fullWidth
                />
                <FormControl className={classes.formControl} fullWidth>
                    <InputLabel htmlFor="age-native-simple">Estado</InputLabel>
                    <Select
                    native
                    id="personalEstado"
                    name="PersonalEstado "
                    onChange={handleChange}
                    >
                      <option value="Activo">Activo</option>
                      <option value="Inactivo">Inactivo</option>
                      
                    </Select>
                </FormControl>
                
                
                <TextField
                  multiline
                  InputProps={{
                    classes: {
                      underline: classes.textFieldUnderline,
                      input: classes.textField,
                    },
                  }}
                  label="Seleccione Jefe Superior"
                  margin="normal"
                  required
                  value={mPersonaJefe && mPersonaJefe.personalNombre}
                  onClick={accionesModalpers}
                  type="text"
                  fullWidth
                />
            </Grid>
        </Grid>
        </ModalBody>
        <ModalFooter>
            <Button className="btn btn-danger" onClick={()=>accionesModal()} >Cancelar</Button>
            <Button color="primary" className="btn btn-primary" onClick={()=>voidPersona(1)} > Guardar</Button>

        </ModalFooter>
      </Modal>
      

      <Modal
        isOpen={modaleditar}
        zIndex={5000}
        size={'lg'}
        centered
      >
        <ModalHeader>
            Editar Personal
        </ModalHeader>
        <ModalBody>
        <Grid container spacing={6}>
            <Grid item xs={12} md={12}>
            
                <div className="form-group">
                  <label>Id :</label>
                  <br/>
                  <input id="personalId" name="personalId" type="text" onChange={handleChange} readOnly className="form-control" defaultValue={personaselc && personaselc.personalId}></input>
                  <br/>
                  <label>Nombre:</label>
                  <br/>
                  <input id="personalNombre" name="personalNombre" type="text" onChange={handleChange} className="form-control" defaultValue={personaselc && personaselc.personalNombre}></input>
                  <br/>
                  <label>Cuenta Red:</label>
                  <br/>
                  <input id="personalCuentaRed" name="personalCuentaRed" onChange={handleChange} type="text" className="form-control" defaultValue={personaselc && personaselc.personalCuentaRed}></input>
                  <br/>
                  
                  <label>Codigo SAP:</label>
                  <br/>
                  <input id="personalCodigoSAP" name="personalCodigoSAP" onChange={handleChange} type="text" className="form-control" defaultValue={personaselc && personaselc.personalCodigoSAP}></input>
                  <br/>
                  <label>Correo:</label>
                  <br/>
                  <input id="personalCorreo" name="personalCorreo" onChange={handleChange} type="text" className="form-control" defaultValue={personaselc && personaselc.personalCorreo}></input>
                  <br/>
                  <label>Persona Jefe:</label>
                  <br/>
                  <select id="personalId_Jefe" name="personalId_Jefe" onChange={handleChange}  className="form-control" value={personaselc && personaselc.personalId_Jefe}>
                    <option value="" >Seleccione Personal</option>
                    {mdata.map(pers => (
                        <option
                        key={pers.personalId}
                        value={pers.personalId}
                        >
                        {pers.personalNombre}
                        </option>
                    ))}
                    </select>
                    <br/>
                    <label>Estado:</label>
                    <br/>
                    <select id="personalEstado" name="personalEstado" onChange={handleChange} className="form-control" value={personaselc && personaselc.personalEstado}>
                    <option value="Activo">Activo</option>
                    <option value="Inactivo">Inactivo</option>
                    
                    </select>
                    
                </div>
                
            </Grid>
        </Grid>
        </ModalBody>
        <ModalFooter>
            <Button className="btn btn-primary" onClick={()=>voidPersona(2)} > Guardar</Button>
            <Button className="btn btn-primary" onClick={()=>ResetClaveUser()} > Resetear Clave</Button>
            <Button className="btn btn-danger" onClick={()=>accionesModalEdit()} >Cancelar</Button>
        </ModalFooter>
      </Modal>
      <Modal
        isOpen={modalver}
        zIndex={5000}
        size={'lg'}
        centered
      >
        <ModalHeader>
            Informacion del Personal
        </ModalHeader>
        <ModalBody>
        <Grid container spacing={6}>
            <Grid item xs={12} md={12}>
            
                <div className="form-group">
                  <label>Id :</label>
                  <br/>
                  <input id="personalId" name="personalId" type="text"  readOnly className="form-control" defaultValue={personaselc && personaselc.personalId}></input>
                  <br/>
                  <label>Nombre:</label>
                  <br/>
                  <input id="personalNombre" name="personalNombre" type="text"  readOnly className="form-control" defaultValue={personaselc && personaselc.personalNombre}></input>
                  <br/>
                  <label>Cuenta Red:</label>
                  <br/>
                  <input id="personalCuentaRed" name="personalCuentaRed" readOnly type="text" className="form-control" defaultValue={personaselc && personaselc.personalCuentaRed}></input>
                  <br/>
                  
                  <label>Codigo SAP:</label>
                  <br/>
                  <input id="personalCodigoSAP" name="personalCodigoSAP" readOnly type="text" className="form-control" defaultValue={personaselc && personaselc.personalCodigoSAP}></input>
                  <br/>
                  <label>Correo:</label>
                  <br/>
                  <input id="personalCorreo" name="personalCorreo"  readOnly type="text" className="form-control" defaultValue={personaselc && personaselc.personalCorreo}></input>
                  <br/>
                  <label>Persona Jefe:</label>
                  <br/>
                  <input id="personalId_Jefe" name="personalId_Jefe" readOnly type="text" className="form-control" defaultValue={personaselc && personaselc.personalId_Jefe}></input>
                  
                    <br/>
                    <label>Estado:</label>
                    <br/>
                  <input id="personalEstado" name="personalEstado" readOnly type="text" className="form-control" defaultValue={personaselc && personaselc.personalEstado}></input>
                </div>
                
            </Grid>
        </Grid>
        </ModalBody>
        <ModalFooter>
            <Button className="btn btn-danger" onClick={()=>accionesModalVer()} >Cancelar</Button>
        </ModalFooter>
      </Modal>
       
      <Modal
        isOpen={modalpersonal}
        paddingTop={30}
        centered
        zIndex={5000}
        size={'xl'}
        autoFocus={true}
      >
        <ModalBody>
            <MiTable data={mdata}  click={clickhandler} onRowClicked={onRowClicked}/>

        </ModalBody>
        <ModalFooter>
            <Button className="btn btn-danger" onClick={()=>accionesModalpers()} >Cerrar</Button>
        </ModalFooter>
      </Modal>

    </>
  );
}
