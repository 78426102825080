import React, { useState,useEffect } from "react";
import {
  Grid,
  LinearProgress,
  Select,
  OutlinedInput,
  MenuItem,
  TextField,
  Button
} from "@material-ui/core";
import { useTheme } from "@material-ui/styles";
import {
  ResponsiveContainer,
  ComposedChart,
  AreaChart,
  LineChart,
  Line,
  Area,
  PieChart,
  Pie,
  Cell,
  YAxis,
  XAxis,
} from "recharts";
import { useSnackbar } from 'notistack';
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import {Modal,ModalBody,ModalFooter,ModalHeader} from "reactstrap"
// styles
import {
  DataGrid,
  ColDef,
  ValueGetterParams,
  CellParams,
  GridApi
} from "@material-ui/data-grid";
// components
import axios from 'axios'
import mock from "./mock";
import Widget from "../../components/Widget";
import PageTitle from "../../components/PageTitle";
import { Typography } from "../../components/Wrappers";
import Dot from "../../components/Sidebar/components/Dot";
import BigStat from "./components/BigStat/BigStat";
import UserAvatar from "../../components/UserAvatar/UserAvatar";

import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Constantes from "../../context/Constates";

import { useUserDispatch, signOut } from "../../context/UserContext";


  /*rutas */
  var obtenerTareas=Constantes.URLSERVER+Constantes.APIGETTAREAS
  var voidcontra=Constantes.URLSERVER+Constantes.APIPOSTPASSWORD


const StyledTableCell = withStyles((theme) => ({
  head: {
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);
const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});
export default function Dashboard(props) {
  const classes = useStyles();
  let history = useHistory();
  var theme = useTheme();
  var userDispatch = useUserDispatch();

  var dataRecibido={};
  var configjson = {
    headers: {'Content-Type': 'application/json'},
    headers: {'Authorization': 'Bearer '+localStorage.getItem('id_token')}
  };
  const location = useLocation();
  
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  // local
  if(localStorage!=null){
    var Usuario=JSON.parse(localStorage.getItem('user'));
    var cambiarcontrauser=JSON.parse(localStorage.getItem('userpass'));
    var mensajes=JSON.parse(localStorage.getItem('mensajes'));
  }
  

  const [mtareas, setmtareas] = React.useState([]);
  const [mtareaspagos, setmtareaspagos] = React.useState([]);
  const [modal, setmodal] = React.useState(false);
  
  const mcolumns=[
    { field: 'procesoCodigo', headerName: 'Codigo', width: 200 },
    { field: 'solicitante', headerName: 'Solicitante', resizable: true,width: 230  },
    { field: 'proveedor', headerName: 'Proveedor', width: 150 },
    { field: 'descripcion', headerName: 'Descripción', width: 150 },
    { field: 'monto', headerName: 'Monto', width: 100 },
    { field: 'tareaFechaAsignacionStr', headerName: 'Fecha de Asignación', width: 200 },
    { field: 'actividadNombre', headerName: 'Actividad', width: 220 },
  ];
  const mcolumnspago=[
    { field: 'procesoCodigo', headerName: 'Codigo', width: 200 },
    { field: 'codigoCompra', headerName: 'Orden de Compra', resizable: true,width: 230  },
    { field: 'proveedor', headerName: 'Proveedor', width: 150 },
    { field: 'montoFactura', headerName: 'Monto Factura', width: 150 },
    { field: 'saldo', headerName: 'saldo', width: 100 },
    { field: 'montoPagado', headerName: 'Monto Pagado', width: 200 },
  ];

  var [mainChartState, setMainChartState] = useState("monthly");

  /*Peticiones*/ 
  useEffect(()=>{
    if(mensajes && mensajes.length>0 ){

    }
    if(cambiarcontrauser){
      accionesModal();
    }{
      var inicio=localStorage.getItem('menu');
  if(inicio==null){
    var token=localStorage.getItem('id_token');
    getMenuUser(token);
  }
      GetTareas();
      handleClickVariant('success');
      if (location.state!=null) {
        dataRecibido=location.state.dtsolicitud;
        if (dataRecibido.procesoId>0) {
          handleClickVariant('success');
        }
      }
    }
    
    //GetPersonaAreas();
  },[location])

  /*alert mensajes */
  const handleClickVariant = (variant) => () => {
    // variant could be success, error, warning, info, or default
    var msj='Cod: '+dataRecibido.procesoId+' Solicitud enviada..';
    enqueueSnackbar(msj, { variant });

  };
  const [clave, setclave] = React.useState("");
  const [clavenew, setclavenew] = React.useState("");
  const [clavenewrep, setclavenewrep] = React.useState("");
  const accionesModal=()=>{
   // console.log("ingtre"+modal)
    setmodal(!modal)
  }
  const handleChangeclave=(event) => {
    setclave(event.target.value);
  }
  const handleChangeclavenew=(event) => {
    setclavenew(event.target.value);
  }
  const handleChangeclavenewrep=(event) => {
    setclavenewrep(event.target.value);
  }
  const cambiarcontra=async()=>{
    try {
      var data={};
      var mRol={};
        if(valida()){
          if(clavenew==clavenewrep){
            mRol.PersonalId =Usuario.personalId;
            mRol.ClaveActual =clave;
            mRol.NuevaClave  =clavenew;
            mRol.RepitaNuevaClave =clavenewrep;
            const json = JSON.stringify(mRol);
            console.log(json);
            const resp = await axios.post(voidcontra,json,configjson);
            var result = resp.data;
            if(result.codigoRespuesta==0){
              alert(result.mensajeRespuesta);   
              accionesModal();
              signOut(userDispatch, props.history);
            }else{
              alert(result.mensajeRespuesta);   
            }
          }else{
            alert("Las nuevas contraseña no coinciden");   

          }
          
        }else{
          alert("los datos son requeridos");   
        }
        
        return data;
      } catch (err) {
          console.error(err);
      }
  }

  function valida(){
    var sw=true;
    if(clave && clave==""){
      sw=false;
    }
    if(clavenew && clavenew==""){
      sw=false;
    }
    if(clavenewrep && clavenewrep==""){
      sw=false;
    }
    return sw;
  }
  function getMenuUser (token)  {
    try {
      var Persona=JSON.parse(localStorage.getItem('user'));
      var fmenu=Constantes.URLSERVER+Constantes.APIGETMENU+"PersonalId="+Persona.personalId
      var config = {
        headers: {'Access-Control-Allow-Origin': '*' ,'Authorization': 'Bearer '+token}
      };
        axios.get(fmenu,config).then((repos) => {
          var result = repos.data;
          if(result.codigoRespuesta==0){
            var mn=result.valorRespuesta[0]
            var grupo=mn.listaGrupoAplicacion
            var mgrupo={};
            mgrupo.grupoPersonasId=mn.grupoPersonasId;
            mgrupo.grupoPersonasNombre=mn.grupoPersonasNombre;
            mgrupo.grupoPersonasUnipersonal=mn.grupoPersonasUnipersonal;
            mgrupo.areaId=mn.areaId;
            localStorage.setItem('menu', JSON.stringify(grupo));
            localStorage.setItem('grupo', JSON.stringify(mgrupo));
            getDatalist(token);
  
          }else{
            
          }
        });
        
    } catch (err) {
        console.error(err);
    }
  };
  function getDatalist(token) {
    var menu=Constantes.URLSERVER+'api/servicio/getListaFormSolicitudCompra'
    var config = {
      headers: {'Access-Control-Allow-Origin': '*','Content-Type': 'application/json', 'Authorization': 'Bearer '+token}
  
  };
  
    axios.get(menu,config)
    .then((repos) => {
      var result = repos.data;
      if(result.codigoRespuesta==0){
        var mn=result.valorRespuesta
        var listarea=mn.listaAreas
        var listctmark=mn.listaCuentasMarketing
        var listmarcas=mn.listaMarcas
        var listprov=mn.listaProveedores
        localStorage.setItem('ltareas', JSON.stringify(listarea));
        localStorage.setItem('ltctmarks', JSON.stringify(listctmark));
        localStorage.setItem('ltmarcas', JSON.stringify(listmarcas));
        localStorage.setItem('ltprov', JSON.stringify(listprov));
      }else{
      console.error('sesion expirada!');
        
      }
    })
    .catch(error => {
      console.error('There was an error!', error);
    });
  
    
  
  
  }
  const GetTareas=async()=>{
        try {
            var data={};
            var urldm=obtenerTareas+'PersonalId='+Usuario.personalId;
            const resp = await axios.get(urldm,configjson);
            var result = resp.data;
            if(result.codigoRespuesta==0){
              var rows=result.valorRespuesta.listaTareasPendientesSolCompra;
              var rowspago=result.valorRespuesta.listaTareasPendientesSolPago;
              rows.map(dt => {
                dt.id=dt.procesoId;
              });
              rowspago.map(dt => {
                dt.id=dt.procesoId;
              });
              setmtareas(rows);
              setmtareaspagos(rowspago);
            }else{
                
            }
            return data;
          } catch (err) {
              console.error(err);
          }
    }


  return (
    <>
      
      <Grid container spacing={3}>
       
        
       
        
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Widget
            title="Mis Tareas Pendientes Compras"
            upperTitle
            bodyClass={classes.fullHeightBody}
            className={classes.card}
            disableWidgetMenu={true}
          >
            <div style={{ height: 300 , padding:0}}>
              <DataGrid 
                  rows={mtareas} 
                  columns={mcolumns}  
                  showToolbar 
                  autoHeight 
                  onRowSelected={(newSelection) => {
                    var sol=newSelection.data;
                      history.push({
                        pathname: '../../app/Solcompra',
                        state: { dtsolicitud: sol }
                      });
                    }} 
                  />
          
            </div>
                
          </Widget>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Widget
            title="Mis Tareas Pendientes Pagos"
            upperTitle
            bodyClass={classes.fullHeightBody}
            className={classes.card}
            disableWidgetMenu={true}
          >
            <div style={{ height: 300 , padding:0}}>
            <DataGrid 
                  rows={mtareaspagos} 
                  columns={mcolumnspago}  
                  showToolbar 
                  autoHeight 
                  onRowSelected={(newSelection) => {
                    var sol=newSelection.data;
                      history.push({
                        pathname: '../../app/Solpago',
                        state: { dtsolicitud: sol }
                      });
                    }} 
                  />
          
            </div>
                
          </Widget>
                
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          {/* <Widget
            title="Historico de procesos:"
            upperTitle
            
            bodyClass={classes.fullHeightBody}
            className={classes.card}
            disableWidgetMenu={true}
          >
          </Widget> */}
        </Grid>
      </Grid>




      <Modal
        isOpen={modal}
        paddingTop={30}
        centered
        zIndex={9000}
        size={'xl'}
      >
        <ModalHeader>
            Cambiar Contraseña
        </ModalHeader>
        <ModalBody>
            <Grid container spacing={6}  xs={12} md={12}>
              <Grid item xs={6} md={6}>
              <TextField
                  InputProps={{
                    classes: {
                      underline: classes.textFieldUnderline,
                      input: classes.textField,
                    },
                  }}
                  onChange={handleChangeclave}
                  label="Clave Actual"
                  margin="normal"
                  required
                  type="password"
                  fullWidth
                />
              <TextField
                  InputProps={{
                    classes: {
                      underline: classes.textFieldUnderline,
                      input: classes.textField,
                    },
                  }}
                  onChange={handleChangeclavenew}
                  label="Clave Anterior"
                  margin="normal"
                  required
                  type="password"
                  fullWidth
                />
                <TextField
                  InputProps={{
                    classes: {
                      underline: classes.textFieldUnderline,
                      input: classes.textField,
                    },
                  }}
                  onChange={handleChangeclavenewrep}
                  label="Repetir Clave"
                  margin="normal"
                  required
                  type="password"
                  fullWidth
                />
              </Grid>
              <Grid item xs={6} md={6}>
                <br/>
                <label>1. La clave no debe ser igual a la clave de reseteo</label>
                <br/>
                <label>2. La clave no debe contener espacios vacíos</label>
                <br/>
                <label>3. La clave debe ser de 8 o más dígitos</label>
                <br/>
                <label>4. La clave debe contener al menos 3 letras mayúsculas</label>
                <br/>
                <label>5. La clave debe contener al menos 3 letras minúsculas</label>
                <br/>
                <label>6. La clave debe contener al menos 1 número</label>
                <br/>
                <label>7. La clave debe contener al menos 1 caracter especial ! # $ & ( ) * + - , . /</label>
                <br/>
                </Grid>
            </Grid>
        </ModalBody>
        <ModalFooter>
        <Grid >
        <Button
              variant="contained"
              color="success"
              size="small"
              onClick={()=>cambiarcontra()}
              style={{ marginLeft: 16 }}
            >
              Cambiar
            </Button>
            <Button
              variant="contained"
              color="success"
              size="small"
              onClick={()=>accionesModal()}
              style={{ marginLeft: 16 }}
            >
              Cancelar
            </Button>
        </Grid>
        </ModalFooter>
      </Modal>
    </>
  );
}

// #######################################################################
