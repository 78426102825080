import { Hidden } from "@material-ui/core";
import React, { useMemo } from "react";

import DataTable from "react-data-table-component";
import FilterComponent from "./FilterComponent";

const MiTable = props => {
  const columns = [
    
    {
      name: "Solicitud de Compra",
      selector: "procesoCodigo",
      sortable: true,
      hide: "sm"
    },
    {
      name: "Proveedor",
      selector: "proveedor",
      sortable: true
    },
    {
      name: "Monto",
      selector: "monto",
      sortable: true,
      hide: "md",
      width:"100px",
    },
    {
      name: "Pendiente de Pago",
      selector: "pendientePago",
      sortable: true,
      hide: "md",
    },
    {
        name: "Descripción",
        selector: "descripción",
        sortable: true,
        hide: "md"
    },
    {
      name:"Condicion de Pago",
      selector:"condicionPago",
      sortable:true,
    },
    {
      name:"Solicitante",
      selector:"solicitante",
      sortable:true
    },
    {
        name:"Centro de Costo",
        selector:"centroCosto",
        sortable:true
    },
    {
      name:"Manager",
      selector:"manager",
      sortable:true
  },
  {
    name:"Nit",
    selector:"nit",
    sortable:true,
    hide: "md"
},
{
  name:"Contacto",
  selector:"contacto",
  sortable:true,
  hide: "md"
},
{
  name:"Correo",
  selector:"correo",
  sortable:true,
  hide: "md"
},
{
  name:"Telefono Oficina",
  selector:"telefonoOficina",
  sortable:true,
  hide: "md"
},
{
  name:"Condicion de Pago",
  selector:"condicionPago",
  sortable:true
},
{
  name:"Dirección",
  selector:"dirección",
  sortable:true,
  hide: "md"
},
{
  name:"Celular",
  selector:"celular",
  sortable:true,
  hide: "md"
},
    
  ];

  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = React.useState(
    false
  );
  // const filteredItems = data.filter(
  //   item => item.name && item.name.includes(filterText)
  // );
  const filteredItems = props.data.filter(
    item =>
      JSON.stringify(item)
        .toLowerCase()
        .indexOf(filterText.toLowerCase()) !== -1
  );
  const onMeetingTitleClick = (event,data) => {
    props.onRowClicked(data);
  }
  const onRowClicked = (row, event) => { onMeetingTitleClick(event, row.id); };
  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent
        onFilter={e => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  return (
    <DataTable
      title="Seleccione Orden de compra"
      columns={columns}
      data={filteredItems}
      defaultSortField="estado"
      striped
      pagination
      subHeader
      subHeaderComponent={subHeaderComponent}
      defaultSortFieldId={1}
      onRowClicked={onRowClicked}

    />
  );
};

export default MiTable;
