import React ,{useState, useEffect}from "react";
import { Grid, Paper, Typography,TextField, Button ,CircularProgress} from "@material-ui/core";
import { Link } from "react-router-dom";
import classnames from "classnames";
import Constantes from "../../context/Constates";
import axios from 'axios'
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
// styles
import useStyles from "./styles";

// logo
import logo from "./logo.svg";
import logobtc from "./logo_btc_medio.png";

export default function Validar() {
  var classes = useStyles();
  var [nameValue, setNameValue] = useState("");
  var [repnameValue, setRepNameValue] = useState("");
  var [valido, setvalido] = useState(false);
  var [mtoken, setmtoken] = useState("");
  var [mensaje, setmensaje] = useState("");
  var [isLoading, setIsLoading] = useState(false);
  var lvalidarhash=Constantes.URLSERVER+Constantes.APIGETVALIDARHASH
  var lguardar=Constantes.URLSERVER+Constantes.APIPOSTRECCLAVESAVE
  let history = useHistory();
  var config = {
    headers: {'Access-Control-Allow-Origin': '*'},
    headers: {'Content-Type': 'application/json'}
};
  
  useEffect(()=>{
    const url=window.location.href;
    var dataToken=url.split('/');
    var ctoken=dataToken.length;
    var token=dataToken[ctoken-1];
    validartoken(token);
    setmensaje("Comprobando link..");

  },[])


  const validartoken=async(token)=>{
    try {
      var data={};
      var Result={};
        if(token && token!=""){
          setmtoken(token);
          var method=lvalidarhash+"hash="+token;
          const resp = await axios.get(method,config);
            var result = resp.data;
            if(result.codigoRespuesta==0){
                if(result.valorRespuesta){
                  setvalido(true);
                  setmensaje("");
                }else{
                  setvalido(false);
                  setmensaje("Url ya expiro..");
                }
            }else{
              setmensaje(result.mensajeRespuesta);
            }
        }else{
          alert("Los datos son requeridos");   
        }
        
        return data;
      } catch (err) {
          console.error(err);
      }
  }
  const voidSaveclave=async()=>{
    try {
      
      var data={};
      var clave={};
        if(valida()){
          if(nameValue==repnameValue){
            setIsLoading(true);
            clave.hash =mtoken;
            clave.NuevaClave =nameValue;
            clave.RepitaNuevaClave =repnameValue;
            const json = JSON.stringify(clave);
            console.log(json);
            const resp = await axios.post(lguardar,json,config);
            var result = resp.data;
            if(result.codigoRespuesta==0){
              setvalido(false);  
              setmensaje("Cambio de clave exitoso..");
              setTimeout(() => {
                history.push("/login");
              }, 4000);
            }else{
              //alert(result.mensajeRespuesta); 
              setvalido(true);  
              setmensaje(result.mensajeRespuesta);
            }
            setIsLoading(false);
          }else{
            alert("Las nuevas contraseña no coinciden");   

          }
        }else{
          alert("Los datos son requeridos");

        }
        
        return data;
      } catch (err) {
          console.error(err);
          setvalido(false);  
          setmensaje("Ah ocurrido un error..");
      }
  }
  function valida(){
    var sw=true;
    if(nameValue && nameValue==""){
      sw=false;
    }
    if(repnameValue && repnameValue==""){
      sw=false;
    }
    
    return sw;
  }
  return (
    <Grid container className={classes.container}>
      <div className={classes.logotype}>
        <Typography variant="h3" color="white" className={classes.logotypeText}>
          JTI - Recuperación de clave 
        </Typography>
      </div>

      <Paper classes={{ root: classes.paperRoot }}>
         {valido && <>
          <Grid container spacing={6}  xs={12} md={12}>
              <Grid item xs={6} md={6}>
                <TextField
                  InputProps={{
                    classes: {
                      underline: classes.textFieldUnderline,
                      input: classes.textField,
                    },
                  }}
                  label="Nueva Clave"
                  margin="normal"
                  required
                  value={nameValue}
                  onChange={e => setNameValue(e.target.value)}
                  type="password"
                  fullWidth
                />
                <TextField
                  InputProps={{
                    classes: {
                      underline: classes.textFieldUnderline,
                      input: classes.textField,
                    },
                  }}
                  label="Repetir clave"
                  margin="normal"
                  required
                  value={repnameValue}
                  onChange={e => setRepNameValue(e.target.value)}
                  type="password"
                  fullWidth
                />
                <Typography
                  variant="h6"
                  color="text"
                  colorBrightness="secondary"
                  className={classnames(classes.textRow, classes.safetyText)}
                >
                  {mensaje}
                  
                </Typography>

                <br/>
                <br/>
                <br/>
            {isLoading ? (
                  <CircularProgress size={26} className={classes.loginLoader} />
              ) : (
            <Button
              variant="contained"
              color="primary"
              size="large"
              disabled={
                nameValue.length === 0 || repnameValue.length === 0 
              }
              onClick={
                ()=>voidSaveclave(setIsLoading)
              }
              className={classes.backButton}
            >
              Confirmar
            </Button>
            )}
            </Grid>
              <Grid item xs={6} md={6}>
                <br/>
                <label>1. La clave no debe ser igual a la clave de reseteo</label>
                <br/>
                <label>2. La clave no debe contener espacios vacíos</label>
                <br/>
                <label>3. La clave debe ser de 8 o más dígitos</label>
                <br/>
                <label>4. La clave debe contener al menos 3 letras mayúsculas</label>
                <br/>
                <label>5. La clave debe contener al menos 3 letras minúsculas</label>
                <br/>
                <label>6. La clave debe contener al menos 1 número</label>
                <br/>
                <label>7. La clave debe contener al menos 1 caracter especial ! # $ & ( ) * + - , . /</label>
                <br/>
                </Grid>
            </Grid>
        </>}
        {!valido &&
          <>
          {mensaje && <Typography
          variant="h6"
          color="text"
          colorBrightness="secondary"
          className={classnames(classes.textRow, classes.safetyText)}
        >
          {mensaje}
          
        </Typography>}
        {!mensaje && <Typography
          variant="h6"
          color="text"
          colorBrightness="secondary"
          className={classnames(classes.textRow, classes.safetyText)}
        >
         Este link ya no es valido.
          
        </Typography>}
        <Button
          variant="contained"
          color="primary"
          component={Link}
          to="/"
          size="large"
          className={classes.backButton}
        >
          Volver Inicio
        </Button>
          </>
        }
      </Paper>
    </Grid>
  );
}
