import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
  dashedBorder: {
    
  },
  text: {
    marginBottom: theme.spacing(2),
  },
  paper: {
    position: 'absolute',
    width: '60%',
    maxHeight: '70%',
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3),
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  customtable:{
    display: "block",
    maxWidth: 350
  }
}));
